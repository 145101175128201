import { Appointment, AppointmentData } from "./Appointment";
import { Discount } from "./Discount";
import { Occurrence } from "./Occurrence";
import { getFutureOccurrences, getOccurrenceFromIndex } from "../helpers/rruleHelper";
import { areOverlapping } from "../helpers/timeHelper";
import { areSameDay } from "../helpers/timeZoneHelper";

const WEEKLY_FREQUENCY_TYPE = "WEEKLY";

interface RecurrenceData extends AppointmentData {
    exceptions?: number[],
    is_new_recurrent_customer?: true
}

interface Recurrence extends RecurrenceData {
    id: string
}

class Recurrence {
    constructor(id: string, data: RecurrenceData) {
        this.id = id;
        Object.assign(this, data);
    }
    toData(): RecurrenceData {
        const { id, ...data } = this;
        return data;
    }
    private getDiscounts(occurrenceIndex: number) {
        if (occurrenceIndex === 0)
            return this.discounts;

        return this.discounts?.filter(x => Discount.getTypesForAfterFirstOccurrence().includes(x.type));
    }
    toOccurrence(occurrenceIndex: number, start_time: Date, end_time: Date): Occurrence {
        const { id, ...data } = this;
        const discounts = this.getDiscounts(occurrenceIndex);
        return (
            new Occurrence(
                id,
                occurrenceIndex,
                {
                    ...data,
                    start_time,
                    end_time,
                    discounts
                }
            )
        );
    }
    getOverlappingDates(appointments: Appointment[], weeksToCheck: number) {
        const occurrencesCount = Math.floor(weeksToCheck / this.getFrequency().interval);
        const occurrences = getFutureOccurrences(this, occurrencesCount, undefined, true);

        const overlappingDates: Date[] = [];

        occurrences.forEach(occurrence => {
            const occurrenceRange = { startTime: occurrence.start_time, endTime: occurrence.end_time };
            appointments
                .filter(x => x.groomer.id == this.groomer.id)
                .filter(x => areOverlapping(occurrenceRange, { startTime: x.start_time, endTime: x.end_time }))
                .forEach(x => overlappingDates.push(x.start_time));
        });

        return overlappingDates;
    }
    getNextOverlappingOccurrence(other: Recurrence, toleranceMinutes?: number) {
        const lcm = calculateLeastCommonMultiple(this.getFrequency().interval, other.getFrequency().interval);
        const thisWeeksToCheck = lcm / this.getFrequency().interval;
        const otherWeeksToCheck = lcm / other.getFrequency().interval;

        const occurrences = getFutureOccurrences(this, thisWeeksToCheck, undefined, true);
        const otherOccurrences = getFutureOccurrences(other, otherWeeksToCheck, undefined, true);

        for (const occurrence of occurrences) {
            for (const otherOccurrence of otherOccurrences) {
                if (occurrence.overlapsWith(otherOccurrence, toleranceMinutes)) {
                    return occurrence;
                }
            }
        }

        return undefined;
    }
    //HACK: We use this to get the (typed) frequency in the Recurrence class, instead of overriding the AppointmentData property
    getFrequency(): { type: string, interval: number } {
        if (!this.frequency) {
            throw new Error("Recurrence does not have a frequency");
        }

        return this.frequency;
    }
    getNextAvailableDate(dates: Date[]) {
        const lastExceptionDate = dates[dates.length - 1];
        const intervalInDays = this.frequency ? this.frequency.interval * 7 : 7;
        const startTime = setDateTime(lastExceptionDate, this.start_time.getHours(), this.start_time.getMinutes(), intervalInDays);
        const endTime = setDateTime(lastExceptionDate, this.end_time.getHours(), this.end_time.getMinutes(), intervalInDays);
        return { startTime, endTime };
    }
    areSameDate(occurrenceIndex: number, startTime: Date) {
        const occurrence = getOccurrenceFromIndex(this, occurrenceIndex);
        return areSameDay(occurrence.start_time, startTime, occurrence?.time_zone);
    }
    finalPrice() {
        return Appointment.getFinalPriceFromData(this);
    }
    validateExceptionIndex(exception: Occurrence) {
        if (this.exceptions && this.exceptions.includes(exception.occurrenceIndex)) {
            throw new Error(`Exception ${exception.occurrenceIndex} already created for recurrence ${this.id}. Please refresh the calendar.`);
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromApi(serialized: any) {
        const { id, ...data } = serialized;
        return new Recurrence(id, data);
    }
}

function calculateLeastCommonMultiple(a: number, b: number): number {
    function calculateGreatestCommonDivisor(x: number, y: number): number {
        if (y === 0) {
            return x;
        } else {
            return calculateGreatestCommonDivisor(y, x % y);
        }
    }

    const gcd = calculateGreatestCommonDivisor(a, b);
    const lcm = (a * b) / gcd;

    return lcm;
}

function setDateTime(date: Date, hours: number, minutes: number, intervalInDays: number): Date {
    const dateTime = new Date(date.getTime());
    dateTime.setDate(dateTime.getDate() + intervalInDays);
    dateTime.setHours(hours);
    dateTime.setMinutes(minutes);
    return dateTime;
}

export { Recurrence, WEEKLY_FREQUENCY_TYPE };
export type { RecurrenceData };
