import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";

//HACK: We need to force Sunday as the week start, since luxon hard-coded the start of week as Monday.
// Based on this comment's codesandbox: https://github.com/mui/mui-x/issues/7670#issuecomment-1814064231
// Eventually this should be fixed in the mui library though (see the PR below for more details).
// https://github.com/mui/mui-x/pull/10964
export class CustomLuxonAdapter extends AdapterLuxon {
    // The one in AdapterLuxon is private and so we can't re-use it.
    private setLocaleToValue2 = (value: DateTime) => {
        const expectedLocale = this.getCurrentLocaleCode();
        if (expectedLocale === value.locale) {
            return value;
        }

        return value.setLocale(expectedLocale);
    };

    public startOfWeek = (value: DateTime) => {
        return value.startOf(
            "week",
            // @ts-ignore
            { useLocaleWeeks: true }
        );
    };

    public endOfWeek = (value: DateTime) => {
        return value.endOf(
            "week",
            // @ts-ignore
            { useLocaleWeeks: true }
        );
    };

    public getWeekArray = (value: DateTime) => {
        const cleanValue = this.setLocaleToValue2(value);
        const firstDay = this.startOfWeek(this.startOfMonth(cleanValue));
        const lastDay = this.endOfWeek(this.endOfMonth(cleanValue));

        const { days } = lastDay.diff(firstDay, "days").toObject();

        const weeks: DateTime[][] = [];
        new Array(Math.round(days || 0))
            .fill(0)
            .map((_, i) => i)
            .map(day => firstDay.plus({ days: day }))
            .forEach((v, i) => {
                if (i === 0 || (i % 7 === 0 && i > 6)) {
                    weeks.push([v]);
                    return;
                }

                weeks[weeks.length - 1].push(v);
            });

        return weeks;
    };

    public getWeekNumber = (value: DateTime) => {
        // @ts-ignore
        return value.localeWeekNumber ?? value.weekNumber;
    };
}