import { TimeZone } from "@marathon/common/helpers/timeZoneHelper";
import { getDistanceInMiles } from "../helpers/mapHelper";
import { shouldBeUnreachable } from "../helpers/typesHelper";

enum HubGroup {
    AllPacific = "all-pacific-hubs",
    AllCentral = "all-central-hubs",
    AllArizona = "all-arizona-hubs",
    AllEastern = "all-eastern-hubs",
    AllHubs = "all-hubs"
}

interface HubData {
    acronym: string,
    full_address: string,
    name: string,
    phone_number: string,
    place_id: string,
    vertices: Vertex[],
    coordinates: Vertex,
    pricing_preset_id: string
    time_zone: TimeZone,
    maximum_distance?: number,
    maximum_drive_time?: number,
    prices_include_tax?: true,
    first_time_customer_discount?: {
        value: number,
        is_enabled: boolean
    }
    goal: {
        value: number,
        thismn: number
    },
    google_feedback_url: string,
    square_location_id: string
}

interface Vertex {
    lat: number,
    lng: number
}

interface Hub extends HubData {
    id: string
}

class Hub {
    constructor(id: string, data: HubData) {
        this.id = id;
        Object.assign(this, data);
    }
    toData(): HubData {
        const { id, ...data } = this;
        return data;
    }
    getFormattedPhoneNumber() {
        const match = this.phone_number.match(/(\d{3})(\d{3})(\d{4})/);
        if (!match) {
            return this.phone_number;
        }
        return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    static defaultSalesGoal() {
        return {
            value: 0,
            thismn: 1
        };
    }
    npsFeedbackUrl() {
        return `https://www.barkbus.com/feedback/${this.acronym.toLowerCase()}`;
    }
    getDistanceFromHubInMiles(destinationCoordinates: Vertex) {
        return getDistanceInMiles(this.coordinates, destinationCoordinates);
    }
    static get group() {
        return {
            name: (group: HubGroup) => {
                return (
                    group
                        .split("-")
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(" ")
                );
            },
            timeZone: (group: HubGroup) => {
                switch (group) {
                    case HubGroup.AllHubs:
                        return TimeZone.PacificTime;
                    case HubGroup.AllPacific:
                        return TimeZone.PacificTime;
                    case HubGroup.AllCentral:
                        return TimeZone.CentralTime;
                    case HubGroup.AllArizona:
                        return TimeZone.ArizonaTime;
                    case HubGroup.AllEastern:
                        return TimeZone.EasternTime;
                    default:
                        shouldBeUnreachable(group);
                }
            }
        };
    }
    static get geofenceGroup() {
        return {
            name: (group: GeofenceGroup) => {
                return (
                    group
                        .split("-")
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(" ")
                );
            },
            zoom: (group: GeofenceGroup) => {
                switch (group) {
                    case GeofenceGroup.losAngeles:
                        return 8;
                    case GeofenceGroup.sanDiego:
                        return 9;
                    case GeofenceGroup.dallas:
                        return 9;
                    case GeofenceGroup.houston:
                        return 9;
                    case GeofenceGroup.phoenix:
                        return 9;
                    case GeofenceGroup.atlanta:
                        return 10.5;
                    default:
                        shouldBeUnreachable(group);
                }
            },
            center: (group: GeofenceGroup) => {
                switch (group) {
                    case GeofenceGroup.losAngeles:
                        return { lat: 33.8022, lng: -118.3437 };
                    case GeofenceGroup.sanDiego:
                        return { lat: 33.0157, lng: -117.1611 };
                    case GeofenceGroup.dallas:
                        return { lat: 32.9267, lng: -96.8870 };
                    case GeofenceGroup.houston:
                        return { lat: 29.7604, lng: -95.5998 };
                    case GeofenceGroup.phoenix:
                        return { lat: 33.3884, lng: -111.8500 };
                    case GeofenceGroup.atlanta:
                        return { lat: 33.8290, lng: -84.3880 };
                    default:
                        shouldBeUnreachable(group);
                }
            }
        };
    }
}

enum GeofenceGroup {
    losAngeles = "los-angeles",
    sanDiego = "san-diego",
    dallas = "dallas",
    houston = "houston",
    phoenix = "phoenix",
    atlanta = "atlanta"
}

export { Hub, HubGroup, GeofenceGroup };
export type { HubData, Vertex };