import { Box } from "@mui/material";
import { CustomerAddressData } from "@marathon/common/entities/Customer";
import { AddressOptionList } from "./AddressOptionList";
import { Hub } from "@marathon/common/entities/Hub";
import { SecondaryAddress } from "@marathon/common/entities/SecondaryAddress";

interface AddressSelectorProp {
    addresses: (SecondaryAddress | CustomerAddressData)[],
    selectedAddress?: SecondaryAddress | CustomerAddressData,
    handleSelectedAddressChange: (address: SecondaryAddress | CustomerAddressData) => void,
    originApp: AddressSelectorOriginApp,
    hubs: Hub[]
}

export enum AddressSelectorOriginApp {
    Groomer = "groomer",
    Customer = "customer",
    Admin = "admin"
}

export const AddressSelector = ({ addresses, selectedAddress, handleSelectedAddressChange, originApp, hubs }: AddressSelectorProp) => {
    return (
        <>
            <Box
                className={`${originApp === AddressSelectorOriginApp.Groomer && "address-selector-title"}`}
                style={{
                    fontSize: 16,
                    marginTop: 10,
                    marginBottom: 10,
                    fontWeight: 600
                }}
            >
                Select address for new appointment:
            </Box>
            <AddressOptionList
                addresses={addresses}
                handleSelectedAddressChange={handleSelectedAddressChange}
                selectedAddress={selectedAddress}
                originApp={originApp}
                hubs={hubs}
            />
        </>
    );
};
