import { container, inject, singleton } from "tsyringe";
import { DocResult, INJECTED_FIRESTORE_SERVICE_TOKEN } from "./IFirestoreService";
import type { IFirestoreService } from "./IFirestoreService";
import { Hub, HubData } from "@marathon/common/entities/Hub";
import { UpdateDataInternal } from "@marathon/common/utilities/TypeUtils";
import { CollectionPaths } from "@marathon/common/entities/base/CollectionPaths";

const mapEntity = function (snapshot: DocResult<HubData>) {
    return new Hub(snapshot.id, snapshot.data);
};

@singleton()
export class HubRepository {
    private firestoreService: IFirestoreService<HubData>;
    constructor(@inject(INJECTED_FIRESTORE_SERVICE_TOKEN) injectedService: IFirestoreService<HubData>) {
        injectedService.collectionPath = CollectionPaths.Hubs;
        this.firestoreService = injectedService;
    }
    static get current() {
        return container.resolve(HubRepository);
    }
    async getById(id: string) {
        const doc = await this.firestoreService.getById(id);
        return doc ? mapEntity(doc) : null;
    }
    async search() {
        const docs = await this.firestoreService.search();
        return docs.map(x => mapEntity(x));
    }
    async update(id: string, data: UpdateDataInternal<HubData>) {
        await this.firestoreService.update(id, data);
    }
}
