export class PricingCopyBuilder {
    private description = "";

    appendDescription(desc: string) {
        this.description += desc + "\n";
    }

    setDogName(dogName?: string) {
        this.appendDescription(`The following services are available for ${dogName ?? "your dog"}.`);
        return this;
    }

    setLongAndCurlyHairService() {
        this.appendDescription(`
The Signature Service Includes:
Our signature 'spaw' package includes a warm filtered bath,
your choice of all-natural shampoos & conditioners,
a gentle brush out & blow-dry, teeth brushing, ear cleaning & nail trimming & filing.
Anal gland expression is available upon request.

options:`);
        return this;
    }

    setFullHaircutOption(price: number) {
        this.appendDescription(`
Signature Service + Full Haircut: $${price}
A full-body makeover! This includes everything from the Signature Service plus any and all full-body haircutting and styling.`);
        return this;
    }

    setPartialHaircutOption(price: number) {
        this.appendDescription(`
Signature Service + Partial Haircut: $${price}
This service is perfect for those pups that need a tidy-up! This includes everything from the Signature Service plus a trim of the fur around the head, ears, face, paw pads, and sanitary areas.`);
        return this;
    }

    setBathOnlyOption(price: number) {
        this.appendDescription(`
Signature Service Bath Only: $${price}
This service does not include any hair cutting.`);
        return this;
    }

    setShortHairService(price: number) {
        this.appendDescription(`
Signature service: $${price}
Our signature 'spaw' package includes a warm filtered bath,
your choice of all-natural shampoos & conditioners, 
a gentle brush out & blow-dry, teeth brushing, ear cleaning & nail trimming & filing.
Anal gland expression is available upon request.`);
        return this;
    }

    setDoubleCoatService(price: number) {
        this.appendDescription(`
Signature service with De-shed: $${price}
Hair all over your clothes and furniture? The DeShed has you covered!
This service includes everything from the Signature Service plus a thorough
brush-out of the undercoat and all-natural deshed shampoo & conditioner treatment.
We finish up with the force dryer to blow away the remaining falling fur.`);
        return this;
    }

    setDoubleCoatAndHaircutService(price: number) {
        this.appendDescription(`        
Signature service with De-shed + Haircut: $${price}
Need a DeShed and a slight trim? This service will get your furbaby cleaned up and all set for those hot days.
This includes everything from the DeShed package plus an all-over haircut. 
We do not recommend shaving any double-coated dogs as this may result in compromised re-growth, but we can help with an outline haircut!`);
        return this;
    }

    build() {
        return this.description;
    }
}