import { CollectionPaths } from "@marathon/common/entities/base/CollectionPaths";
import { CustomerLog, CustomerLogData } from "@marathon/common/entities/CustomerLog";
import { DocResult, getLastDocument, INJECTED_FIRESTORE_SERVICE_TOKEN } from "./IFirestoreService";
import type { DocSnapshot, IFirestoreService } from "./IFirestoreService";
import { container, inject, singleton } from "tsyringe";
import { FilterOperator, getFilter, getOrderBy, OrderDirection } from "@marathon/common/api/QueryFilter";

const mapEntity = function (snapshot: DocResult<CustomerLogData>) {
    if (!snapshot.parentId) {
        throw new Error("Parent ID is required");
    }
    return new CustomerLog(snapshot.id, snapshot.parentId, snapshot.data);
};

@singleton()
export class CustomerLogRepository {
    private firestoreService: IFirestoreService<CustomerLogData>;
    constructor(@inject(INJECTED_FIRESTORE_SERVICE_TOKEN) injectedService: IFirestoreService<CustomerLogData>) {
        injectedService.parentCollectionPath = CollectionPaths.Customers;
        injectedService.collectionPath = CollectionPaths.Logs;
        this.firestoreService = injectedService;
    }
    static get current() {
        return container.resolve(CustomerLogRepository);
    }
    async create(customerId: string, data: CustomerLogData) {
        const docId = await this.firestoreService.create(data, customerId);
        return mapEntity({ id: docId, parentId: customerId, data });
    }
    async searchByDate(
        customerId: string,
        fromDate: Date,
        toDate: Date,
        pageSize?: number,
        afterDocument?: DocSnapshot
    ) {
        const docs = await this.firestoreService.search({
            filters: [
                getFilter("date", FilterOperator.greaterThanOrEqual, fromDate),
                getFilter("date", FilterOperator.lessThan, toDate)
            ],
            orders: [getOrderBy("date", OrderDirection.desc)],
            limit: pageSize,
            afterDocument
        }, customerId);
        return {
            logs: docs.map(x => mapEntity(x)),
            lastDocument: getLastDocument(docs)
        };
    }
    listenChanges(
        customerId: string,
        documentCount: number,
        callback: (logs: CustomerLog[]) => void): () => void {
        return this.firestoreService.onQuerySnapshot({
            orders: [getOrderBy("date", OrderDirection.desc)],
            limit: documentCount
        }, docs => {
            callback(docs.map(x => mapEntity(x)));
        }, customerId);
    }
}
