import { Box } from "@mui/material";
import { Button } from "@marathon/web/components/Button";

interface HoldAppointmentActionsProps {
    onAccept: () => Promise<void>,
    onDecline: () => Promise<void>,
    isBooking: boolean,
    isDeclining: boolean
}

export const HoldAppointmentActions = ({ onAccept, onDecline, isBooking, isDeclining }: HoldAppointmentActionsProps) => {
    return (
        <Box display="flex" flexDirection="column" mb={3} alignItems="center">
            <Box mb={2} width="100%">
                <Button
                    disabled={isDeclining}
                    fullWidth
                    style={{ textTransform: "none", backgroundColor: "#0066FF", color: "#FFFF" }}
                    variant="contained"
                    onClick={onAccept}
                    showSpinner={isBooking}>
                    Book this Appointment
                </Button>
            </Box>
            <Box width="100%">
                <Button
                    disabled={isBooking}
                    fullWidth
                    style={{ textTransform: "none" }}
                    variant="outlined"
                    onClick={onDecline}
                    showSpinner={isDeclining}>
                    Decline this Appointment
                </Button>
            </Box>
        </Box>
    );
};