import { GroomerLog, GroomerLogData } from "@marathon/common/entities/GroomerLog";
import { DocResult, getLastDocument, INJECTED_FIRESTORE_SERVICE_TOKEN } from "./IFirestoreService";
import type { DocSnapshot, IFirestoreService } from "./IFirestoreService";
import { container, inject, singleton } from "tsyringe";
import { CollectionPaths } from "@marathon/common/entities/base/CollectionPaths";
import { FilterOperator, getFilter, getOrderBy, OrderDirection } from "@marathon/common/api/QueryFilter";

const mapEntity = function (snapshot: DocResult<GroomerLogData>) {
    if (!snapshot.parentId) {
        throw new Error("Parent id is required");
    }
    return new GroomerLog(snapshot.id, snapshot.parentId, snapshot.data);
};

@singleton()
export class GroomerLogRepository {
    private firestoreService: IFirestoreService<GroomerLogData>;
    constructor(@inject(INJECTED_FIRESTORE_SERVICE_TOKEN) injectedService: IFirestoreService<GroomerLogData>) {
        injectedService.parentCollectionPath = CollectionPaths.Groomers;
        injectedService.collectionPath = CollectionPaths.Logs;
        this.firestoreService = injectedService;
    }
    static get current() {
        return container.resolve(GroomerLogRepository);
    }
    async create(groomerId: string, data: GroomerLogData) {
        const docId = await this.firestoreService.create(data, groomerId);
        return mapEntity({ id: docId, parentId: groomerId, data });
    }
    async searchByDate(
        groomerId: string,
        fromDate: Date,
        toDate: Date,
        pageSize?: number,
        afterDocument?: DocSnapshot
    ) {
        const docs = await this.firestoreService.search({
            filters: [
                getFilter("date", FilterOperator.greaterThanOrEqual, fromDate),
                getFilter("date", FilterOperator.lessThan, toDate)
            ],
            orders: [getOrderBy("date", OrderDirection.desc)],
            limit: pageSize,
            afterDocument
        }, groomerId);

        return {
            logs: docs.map(x => mapEntity(x)),
            lastDocument: getLastDocument(docs)
        };
    }
    listenChanges(
        groomerId: string,
        documentCount: number,
        callback: (logs: GroomerLog[]) => void): () => void {
        return this.firestoreService.onQuerySnapshot({
            orders: [getOrderBy("date", OrderDirection.desc)],
            limit: documentCount
        }, snapshot => {
            callback(snapshot.map(x => mapEntity(x)));
        }, groomerId);
    }
}
