import { DateTime, Duration } from "luxon";
import { TimeZone, getLocalizedDateTime } from "./timeZoneHelper";

const SERVICE_FIRST_HOUR = 9;
const SERVICE_FIRST_MINUTE = 30;

export function formatMediumDate(date: Date, timeZone: TimeZone) {
    return DateTime.fromJSDate(date, { zone: timeZone }).toFormat("cccc MMM dd");
}

export function formatMediumDateWithOrdinal(date: Date, timeZone: TimeZone) {
    return `${DateTime.fromJSDate(date, { zone: timeZone }).toFormat("cccc, MMMM d")}${getOrdinalSuffix(date.getDate())}`;
}

export function formatDateForOnlineBooking(date: Date, timeZone: TimeZone) {
    return `${DateTime.fromJSDate(date).toFormat("cccc, LLL")} ${getDayNumberWithOrdinal(date, timeZone)}`;
}

export function formatShortDate(date: Date, timeZone: TimeZone) {
    return DateTime.fromJSDate(date, { zone: timeZone }).toFormat("MM/dd/yy");
}

export function formatLongDate(date: Date, timeZone: TimeZone) {
    return DateTime.fromJSDate(date, { zone: timeZone }).toFormat("EEEE, MMMM dd, yyyy");
}

export function formatTime(date: Date, timeZone: TimeZone, omitMeridiem = false) {
    const format = omitMeridiem ? "h:mm" : "h:mm a";
    return DateTime.fromJSDate(date, { zone: timeZone }).toFormat(format).replace(/:00/g, "");
}

export function formatTime24(date: Date, timeZone: TimeZone) {
    return DateTime.fromJSDate(date, { zone: timeZone }).toFormat("HH:mm");
}

export function formatTimeWindow(startTime: Date, endTime: Date, timeZone: TimeZone) {
    return `${formatTime(startTime, timeZone, true)}-${formatTime(endTime, timeZone)}`.replace(/:00/g, "");
}

export function formatShortDateTime(date: Date, timeZone: TimeZone) {
    return DateTime.fromJSDate(date, { zone: timeZone }).toFormat("MM/dd/yy hh:mm a").replace(/:00/g, "");
}

export function formatDateAndTimeForCalendar(date: Date, timeZone: TimeZone) {
    return DateTime.fromJSDate(date, { zone: timeZone }).toFormat("MMM dd, h:mm a").replace(/:00/g, "");
}

export function formatDateAndTimeForChatbot(date: Date, timeZone: TimeZone) {
    return DateTime.fromJSDate(date, { zone: timeZone }).toFormat("cccc, MM/dd/yy, hh:mm a").replace(/:00/g, "");
}

export function formatTimeForCalendar(date: Date, timeZone: TimeZone) {
    return DateTime.fromJSDate(date, { zone: timeZone }).toFormat("h:mm a").replace(/:00/g, "");
}

export function formatHourForCalendar(hour: number) {
    return DateTime.fromObject({ hour, minute: 0 }).toLocaleString({ hour: "numeric", minute: "2-digit", hour12: true });
}

export function formatIsoDateTime(date: Date, timeZone: TimeZone) {
    const localized = getLocalizedDateTime(date, timeZone);

    const isoString = localized.toISO();
    if (!isoString)
        throw new Error(`Failed to format date ${date} in timezone ${timeZone} as ISO 8601 string`);

    return isoString;
}

export function formatIsoDateTimeForChatbot(date: Date, timeZone: TimeZone) {
    const localized = getLocalizedDateTime(date, timeZone);

    return localized.toFormat("yyyy-MM-dd'T'HH:mm");
}

export const formatTimeInterval = (startTime: Date, endTime: Date, format: "long" | "short" = "long") => {
    const difference = DateTime.fromJSDate(endTime).diff(DateTime.fromJSDate(startTime), ["hours", "minutes"]);
    return formatTimeDifference(difference, format);
};

export const formatTimeDifference = (difference: Duration, format: "long" | "short" = "long") => {
    const differenceMinutes = Math.round(difference.minutes);
    if (difference.hours >= 1) {
        if (differenceMinutes >= 1)
            return `${difference.hours} hr ${differenceMinutes} min`;

        const hoursText = format === "short" ? "hr" : difference.hours > 1 ? "hours" : "hour";
        return `${difference.hours} ${hoursText}`;
    }
    else {
        const minutesText = format === "short" ? "min" : differenceMinutes > 1 ? "minutes" : "minute";
        return `${differenceMinutes} ${minutesText}`;
    }
};

export function isFirstHourService(arrivalTime: Date, timeZone: TimeZone) {
    const date = DateTime.fromJSDate(arrivalTime, { zone: timeZone });
    return date.hour < SERVICE_FIRST_HOUR || (date.hour === SERVICE_FIRST_HOUR && date.minute <= SERVICE_FIRST_MINUTE);
}

export function getDayNumberWithOrdinal(date: Date, timeZone: TimeZone) {
    const day = DateTime.fromJSDate(date, { zone: timeZone }).toFormat("d");
    const dayNumber = parseInt(day);
    return `${dayNumber}${getOrdinalSuffix(dayNumber)}`;
}

export function getOrdinalSuffix(dayOfMonth: number) {
    const j = dayOfMonth % 10;
    const k = dayOfMonth % 100;
    if (j == 1 && k != 11) {
        return "st";
    }
    if (j == 2 && k != 12) {
        return "nd";
    }
    if (j == 3 && k != 13) {
        return "rd";
    }
    return "th";
}

export { SERVICE_FIRST_HOUR };