class DeviceStorageCache {
    private static userUid: string | null = null;
    private static userName: string | null = null;
    private static userRole: string | null = null;

    public static setCurrentUser(
        uid: string | null,
        name: string | null,
        role: string | null
    ): void {
        DeviceStorageCache.userUid = uid;
        DeviceStorageCache.userName = name;
        DeviceStorageCache.userRole = role;
    }
    public static getCurrentUserUid() {
        return DeviceStorageCache.userUid;
    }
    public static getCurrentUserName() {
        return DeviceStorageCache.userName;
    }
    public static getCurrentUserRole() {
        return DeviceStorageCache.userRole;
    }
    public static getNonEmptyCurrentUserUid() {
        if (DeviceStorageCache.userUid === null)
            throw new Error("User uid is not set");

        return DeviceStorageCache.userUid;
    }
    public static getNonEmptyCurrentUserName() {
        if (DeviceStorageCache.userName === null)
            throw new Error("User name is not set");

        return DeviceStorageCache.userName;
    }
}

export default DeviceStorageCache;