import { container, inject, singleton } from "tsyringe";
import { INJECTED_FIRESTORE_SERVICE_TOKEN } from "./IFirestoreService";
import type { IFirestoreService } from "./IFirestoreService";
import { CollectionPaths } from "@marathon/common/entities/base/CollectionPaths";
import { MessageData } from "@marathon/common/entities/Message";
import { BaseMessageRepository } from "./BaseMessageRepository";

@singleton()
export class GroomerMessageRepository extends BaseMessageRepository {
    constructor(@inject(INJECTED_FIRESTORE_SERVICE_TOKEN) injectedService: IFirestoreService<MessageData>) {
        super(injectedService, CollectionPaths.Groomers);
    }
    static get current() {
        return container.resolve(GroomerMessageRepository);
    }
}