import { useState } from "react";
import { Box } from "@mui/material";
import { Button } from "@marathon/web/components/Button";
import { BookingSuggestion } from "@marathon/common/api/BookingSuggestion";
import { ContextSection, DateSection, StylistSection, TimeSection } from "@marathon/web/components/appointment/AppointmentInfoHeader";
import useStyles from "@marathon/web/components/onlineBooking/styles";
import isMobileViewport from "@marathon/web/hooks/isMobileViewport";
import { getFrecuencyText } from "@marathon/client-side/helpers/availableRecurringSlotHelper";

interface AppointmentSuggestionsItemProps {
    suggestion: BookingSuggestion
    onBookAppointment: (suggestion: BookingSuggestion) => Promise<void>
    isGroomerSide?: boolean
    forceMobile?: boolean,
    showDriveTime?: boolean
}

export const AppointmentSuggestionsItem = ({
    suggestion,
    onBookAppointment,
    isGroomerSide = false,
    forceMobile = false,
    showDriveTime = false
}: AppointmentSuggestionsItemProps) => {
    const isMobile = isMobileViewport() || forceMobile;
    const [isBooking, setIsBooking] = useState(false);

    const handleClick = async () => {
        setIsBooking(true);
        await onBookAppointment(suggestion);
        setIsBooking(false);
    };

    return (
        <div className="appointment-suggestion">
            {isMobile || isGroomerSide
                ? <AppointmentSuggestionItemMobile suggestion={suggestion} onBookAppointment={handleClick} isBooking={isBooking} isGroomerSide={isGroomerSide} showDriveTime={showDriveTime} />
                : <AppointmentSuggestionItemDesktop suggestion={suggestion} onBookAppointment={handleClick} isBooking={isBooking} isGroomerSide={isGroomerSide} />}
        </div>
    );
};

interface AppointmentSuggestionItemInnerProps {
    suggestion: BookingSuggestion
    onBookAppointment: (suggestion: BookingSuggestion) => Promise<void>
    isBooking?: boolean,
    isGroomerSide: boolean,
    showDriveTime?: boolean
}

const AppointmentSuggestionItemMobile = ({ suggestion, onBookAppointment, isBooking, isGroomerSide, showDriveTime }: AppointmentSuggestionItemInnerProps) => {
    const classes = useStyles();
    return (
        <Box display="flex" flexDirection="column">
            <div className="appointment-suggestion-header">
                <Box display="flex" justifyContent="space-between">
                    <Box display="flex" flexDirection="column">
                        <DateSection suggestion={suggestion} style={{ fontSize: "19px", lineHeight: "20px", marginBottom: "5px" }} />
                        {(showDriveTime || isGroomerSide) &&
                            <Box display="flex" className="suggestion-stylist-secondary" fontWeight={600}>
                                {suggestion.driveTime && <span>{suggestion.driveTime.duration.text} Drive</span>}
                                {suggestion.distance && <span>{suggestion.distance.toFixed(2)} Miles</span>}
                                &nbsp;
                                {suggestion.interval && <span style={{ color: "#4898f8", fontSize: "12px", whiteSpace: "nowrap" }}>{getFrecuencyText(suggestion.interval)} </span>}
                            </Box>}
                        {!isGroomerSide &&
                            <StylistSection
                                stylistName={suggestion.groomer.obfuscatedName}
                                style={{ fontSize: "15px" }}
                            />}
                    </Box>
                    <Box display="flex" flexDirection="column" alignItems="flex-end">
                        <TimeSection suggestion={suggestion} style={{ fontSize: "15px", lineHeight: "20px" }} />
                        <ContextSection suggestion={suggestion} style={{ fontSize: "12px", lineHeight: "16px" }} />
                    </Box>
                </Box>
            </div>
            <Button
                showSpinner={isBooking}
                variant="contained"
                size="large"
                color="primary"
                className={classes.cardButton}
                onClick={onBookAppointment}>
                Book this appointment
            </Button>
        </Box>
    );
};

const AppointmentSuggestionItemDesktop = ({ suggestion, onBookAppointment, isBooking }: AppointmentSuggestionItemInnerProps) => {
    const classes = useStyles();
    return (
        <Box display="flex" justifyContent="space-between">
            <div className="appointment-suggestion-header">
                <DateSection suggestion={suggestion} style={{ fontSize: "19px", lineHeight: "26px" }} />
                <br />
                <Box display="flex" alignItems="baseline" mb={"2px"}>
                    <TimeSection suggestion={suggestion} style={{ fontSize: "19px", lineHeight: "26px" }} />
                    &nbsp;
                    <ContextSection suggestion={suggestion} style={{ fontSize: "14px" }} />
                </Box>
                <StylistSection
                    style={{ fontSize: "17px", lineHeight: "23px" }}
                    stylistName={suggestion.groomer.obfuscatedName}
                />
            </div>
            <Button
                showSpinner={isBooking}
                variant="contained"
                size="large"
                color="primary"
                className={classes.cardButtonDesktop}
                onClick={onBookAppointment}>
                Book this appointment
            </Button>
        </Box>
    );
};