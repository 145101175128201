import { formatShortDate, formatTime } from "@marathon/common/helpers/timeFormatHelper";
import steps from "@marathon/common/utilities/OnlineBookingSteps";
import { GetTrackDataProps, TrackData } from "./useTracker";
import { MixPanelEvent } from "@marathon/client-side/utilities/MixPanel";

const getTrackDataForInitialStep = () => {
    const trackData: TrackData = {
        eventName: MixPanelEvent.ob_onlineBookingStart,
        properties: {}  // initial step mustn't contain new_user property
    };

    return trackData;
};


const getTrackDataForPhoneInputStep = ({ currentStep, info }: GetTrackDataProps) => {
    const trackData: TrackData = {
        properties: { new_user: false }
    };

    switch (currentStep.substepNumber) {
        case undefined:
            trackData.eventName = MixPanelEvent.ob_returningCustomer;
            trackData.properties = {
                ...trackData.properties,
                is_from_sms: info?.isFromSms
            };
            break;
        case steps.returning.phoneInputSubsteps.codeVerification:
            trackData.eventName = MixPanelEvent.ob_return_phoneConfirm;
            break;
        default:
            return null;
    }

    return trackData;
};

const getTrackDataForWelcomeStep = ({ currentStep, prevStep, info }: GetTrackDataProps) => {
    const trackData: TrackData = {
        properties: { new_user: false }
    };

    switch (currentStep.substepNumber) {
        case undefined:
            trackData.eventName = MixPanelEvent.ob_return_welcomeBack;
            trackData.properties = {
                ...trackData.properties,
                authentication_type: info?.customer?.authenticationType
            };
            break;
        case steps.returning.welcomeSubsteps.petSelection:
            trackData.eventName = MixPanelEvent.ob_return_pricing;
            if (prevStep?.stepNumber === steps.returning.welcome && prevStep.substepNumber === steps.returning.welcomeSubsteps.petForm) {
                let lastDog = info?.dogs && info.dogs[info.dogs.length - 1];
                trackData.properties = {
                    ...trackData.properties,
                    dog_breed: lastDog?.breedName,
                    dog_age: lastDog?.age,
                    dog_gender: lastDog?.gender,
                    dog_weight: lastDog?.weight
                };
            }
            break;
        case steps.returning.welcomeSubsteps.petForm:
            trackData.eventName = MixPanelEvent.ob_return_pricing_addDog;
            break;
        default:
            return null;
    }

    return trackData;
};

const getTrackDataForSuggestionsStep = ({ info }: GetTrackDataProps) => {
    const trackData: TrackData = {
        eventName: MixPanelEvent.ob_return_dates,
        properties: {
            new_user: false,
            groom_price: info?.servicePrice,
            service: info?.serviceNames
        }
    };

    return trackData;
};

const getTrackDataForConfirmationStep = ({ info }: GetTrackDataProps) => {
    const trackData: TrackData = {
        eventName: MixPanelEvent.ob_return_confirm,
        properties: {
            new_user: false,
            appointment_time: (info?.appointment?.arrivalTime && info?.appointment.timeZone) ? formatTime(info.appointment.arrivalTime, info.appointment.timeZone) : "",
            appointment_date: (info?.appointment?.arrivalTime && info?.appointment.timeZone) ? formatShortDate(info.appointment.arrivalTime, info.appointment.timeZone) : ""
        }
    };

    return trackData;
};

const getTrackDataForSafetyStep = () => {
    const trackData: TrackData = {
        eventName: MixPanelEvent.ob_return_safety,
        properties: { new_user: false }
    };

    return trackData;
};

const getTrackDataForThankYouStep = () => {
    const trackData: TrackData = {
        eventName: MixPanelEvent.ob_return_finalStep,
        properties: {
            new_user: false,
            safety_accepted: true
        }
    };

    return trackData;
};

export const getTrackDataForReturningCustomerFlow = (trackDataParams: GetTrackDataProps) => {
    switch (trackDataParams.currentStep.stepNumber) {
        case steps.returning.initial:
            return getTrackDataForInitialStep();
        case steps.returning.phoneInput:
            return getTrackDataForPhoneInputStep(trackDataParams);
        case steps.returning.welcome:
            return getTrackDataForWelcomeStep(trackDataParams);
        case steps.returning.suggestions:
            return getTrackDataForSuggestionsStep(trackDataParams);
        case steps.returning.confirmation:
            return getTrackDataForConfirmationStep(trackDataParams);
        case steps.returning.safety:
            return getTrackDataForSafetyStep();
        case steps.returning.thankYou:
            return getTrackDataForThankYouStep();
        default:
            return null;
    }
};