import { useEffect, useState } from "react";
import mixpanel, { Dict } from "mixpanel-browser";
import { getTrackDataForNewCustomerFlow } from "./getTrackDataForNewCustomerFlow";
import { getTrackDataForReturningCustomerFlow } from "./getTrackDataForReturningCustomerFlow";
import { TimeZone } from "@marathon/common/helpers/timeZoneHelper";
import { shouldBeUnreachable } from "@marathon/common/helpers/typesHelper";
import { MixPanelEvent } from "@marathon/client-side/utilities/MixPanel";
import { OnlineBookingFlowType } from "utilities/OnlineBookingFlowType";
import { CustomerVerificationMode } from "@marathon/common/entities/Customer";

export interface TrackingInfo {
    customer?: {
        name?: string,
        email?: string,
        petQuantity?: number,
        address?: string,
        authenticationType?: CustomerVerificationMode
    },
    dogs?: {
        breedName?: string,
        age?: number,
        gender?: string,
        weight?: number,
    }[],
    servicePrice?: number,
    serviceNames?: string,
    appointment?: {
        arrivalTime?: Date,
        timeZone?: TimeZone
    }
    isFromSms?: boolean
}

interface UseTrackerProps {
    flowType: OnlineBookingFlowType,
    stepNumber: number,
    substepNumber?: number,
    info?: TrackingInfo
}

export interface StepInfo {
    stepNumber: number,
    substepNumber?: number
}

export interface TrackData {
    eventName?: MixPanelEvent,
    properties?: Dict
}

export interface GetTrackDataProps {
    currentStep: StepInfo,
    prevStep?: StepInfo,
    info?: TrackingInfo
}

export type TrackEventCallback = (trackData: TrackData) => void;


export const trackEvent: TrackEventCallback = (trackData: TrackData) => {
    if (trackData?.eventName)
        mixpanel.track(trackData.eventName, trackData.properties);
};

export const useTracker = ({ flowType, stepNumber, substepNumber, info }: UseTrackerProps): TrackEventCallback => {
    const [prevStep, setPrevStep] = useState<StepInfo | undefined>(undefined);

    useEffect(() => {
        const trackData = getTrackData(flowType,
            {
                currentStep: { stepNumber, substepNumber },
                prevStep,
                info
            });
        if (trackData)
            trackEvent(trackData);

        //HACK: We only want this useEffect to be triggered when flowType, stepNumber or substepNumber changed.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flowType, stepNumber, substepNumber]);

    useEffect(() => {
        setPrevStep({ stepNumber, substepNumber });
    }, [stepNumber, substepNumber]);

    return trackEvent;
};

const getTrackData = (flowType: OnlineBookingFlowType, props: GetTrackDataProps) => {
    switch (flowType) {
        case OnlineBookingFlowType.newCustomer:
            return getTrackDataForNewCustomerFlow(props);
        case OnlineBookingFlowType.returningCustomer:
            return getTrackDataForReturningCustomerFlow(props);
        default:
            shouldBeUnreachable(flowType);
    }
};
