import { Credit, CreditData } from "@marathon/common/entities/Credit";
import { container, inject, singleton } from "tsyringe";
import { DocResult, INJECTED_FIRESTORE_SERVICE_TOKEN } from "./IFirestoreService";
import type { IFirestoreService } from "./IFirestoreService";
import { CollectionPaths } from "@marathon/common/entities/base/CollectionPaths";
import { FilterOperator, getFilter } from "@marathon/common/api/QueryFilter";

const mapEntity = function (snapshot: DocResult<CreditData>) {
    if (!snapshot.parentId)
        throw new Error("Parent id is required");
    return new Credit(snapshot.id, snapshot.parentId, snapshot.data);
};

@singleton()
export class CreditRepository {
    private firestoreService: IFirestoreService<CreditData>;
    constructor(@inject(INJECTED_FIRESTORE_SERVICE_TOKEN) injectedService: IFirestoreService<CreditData>) {
        injectedService.parentCollectionPath = CollectionPaths.Customers;
        injectedService.collectionPath = CollectionPaths.Credits;
        this.firestoreService = injectedService;
    }
    static get current() {
        return container.resolve(CreditRepository);
    }
    async create(customerId: string, data: CreditData) {
        const docId = await this.firestoreService.create(data, customerId);
        return mapEntity({ id: docId, parentId: customerId, data });
    }
    async search(customerId: string) {
        const docs = await this.firestoreService.search(undefined, customerId);
        return docs.map(x => mapEntity(x));
    }
    async update(id: string, credit: CreditData, customerId: string) {
        await this.firestoreService.update(id, { ...credit }, customerId);
    }
    async remove(customerId: string, creditId: string) {
        await this.firestoreService.hardDelete(creditId, customerId);
    }
    async removeByReferredId(customerId: string, customerReferredId: string) {
        const credit = await this.getByCustomerReferredId(customerId, customerReferredId);
        if (!credit) {
            return;
        }

        await this.remove(customerId, credit.id);
    }
    async getByCustomerReferredId(customerId: string, customerReferredId: string) {
        const docs = await this.firestoreService.search({
            filters: [
                getFilter("customer_referred_id", FilterOperator.equal, customerReferredId)
            ]
        }, customerId);

        return docs.map(x => mapEntity(x)).at(0) || null;
    }
}