import { useState } from "react";
import { useHistory } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Button from "@mui/material/Button";
import useStyles from "@marathon/web/components/onlineBooking/styles";
import { OnlineBookingContentWrapper } from "../OnlineBookingContentWrapper";
import { TitleWrapper, FieldsetWrapper } from "../shared/CommonWrappers";
import { Box, Radio } from "@mui/material";
import initialStepImg from "images/initial-step.jpg";
import initialStepCroppedImg from "images/initial-step-cropped.jpg";

const optionValues = {
    newCustomer: "new-customer",
    returningCustomer: "returning-customer"
};

export default function OnlineBookingStep1(props: { onNext: () => void }) {
    const classes = useStyles();
    const [value, setValue] = useState("");
    const [hasError, setHasError] = useState(false);
    const [helperText, setHelperText] = useState("");
    const history = useHistory();

    const handleRadioChange = (value: string) => {
        setValue(value);
        setHelperText("");
        setHasError(false);
    };

    const handleSubmit = () => {
        if (value === optionValues.returningCustomer) {
            props.onNext();
            setHasError(false);
        } else if (value === optionValues.newCustomer) {
            history.push("/online-booking-signup");
        } else {
            setHelperText("Please select an option.");
            setHasError(true);
        }
    };

    return (
        <OnlineBookingContentWrapper>
            <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} alignItems="center">
                <Box
                    width={650}
                    display={{
                        xs: "none",
                        sm: "none",
                        md: "none",
                        lg: "block"
                    }}>
                    <img src={initialStepImg} alt="Barkbus - Initial step" width="100%" height="100%" />
                </Box>
                <Box
                    mb={1}
                    display={{
                        xs: "block",
                        sm: "none",
                        md: "none",
                        lg: "none"
                    }}>
                    <img src={initialStepCroppedImg} alt="Barkbus - Initial step" width="100%" height="100%" style={{ borderRadius: 12, marginTop: -10 }} />
                </Box>
                <Box width={{ xs: "100%", sm: "600px" }} pl={{ lg: 5 }}>
                    <TitleWrapper
                        title="Welcome to Barkbus!"
                        subtitle="Let's find you an appointment, are you a new or returning customer?"
                        marginTop={0}
                    />
                    <Box mt={{ xs: 2, sm: 2.5 }}>
                        <FieldsetWrapper hasError={hasError}>
                            <FormControlLabel
                                checked={value === optionValues.newCustomer}
                                control={<Radio />}
                                label="New Customer"
                                onChange={() => handleRadioChange(optionValues.newCustomer)}
                                classes={{
                                    root: value === optionValues.newCustomer
                                        ? classes.selectedRadioButton
                                        : classes.radioButton
                                }}
                                style={{ marginBottom: 10 }}
                            />
                            <FormControlLabel
                                checked={value === optionValues.returningCustomer}
                                control={<Radio />}
                                label="Returning Customer"
                                onChange={() => handleRadioChange(optionValues.returningCustomer)}
                                classes={{
                                    root: value === optionValues.returningCustomer
                                        ? classes.selectedRadioButton
                                        : classes.radioButton
                                }}
                            />
                            {helperText && <FormHelperText>{helperText}</FormHelperText>}
                            <Button
                                onClick={handleSubmit}
                                variant="contained"
                                className={classes.button}
                                disabled={!value}
                                disableElevation>
                                Continue
                            </Button>
                        </FieldsetWrapper>
                    </Box>
                </Box>
            </Box>
        </OnlineBookingContentWrapper>
    );
}
