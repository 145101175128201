import { DiscountData } from "./Discount";

interface DiscountCodeData extends DiscountData {
    status: DiscountCodeStatus,
    created_at: Date,
    code: string,
    expiration_days: number
}

interface DiscountCode extends DiscountCodeData {
    id: string
}

class DiscountCode {
    constructor(id: string, data: DiscountCodeData) {
        this.id = id;
        Object.assign(this, data);
    }
    toData(): DiscountCodeData {
        const { id, ...data } = this;
        return data;
    }
    toDiscountData(): DiscountData {
        return {
            type: this.type,
            value: this.value
        };
    }
    isValid() {
        return this.status === DiscountCodeStatus.unused && (new Date().getTime() - this.created_at.getTime()) / (1000 * 60 * 60 * 24) <= this.expiration_days;
    }
    static generateRandomCode() {
        const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const numbers = "0123456789";
        let code = "";
        for (let i = 0; i < 4; i++) {
            code += letters.charAt(Math.floor(Math.random() * letters.length));
            code += numbers.charAt(Math.floor(Math.random() * numbers.length));
        }
        return code;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromApi(serialized: any) {
        const { id, ...data } = serialized;
        return new DiscountCode(id, data);
    }
}

enum DiscountCodeStatus {
    used = "used",
    unused = "unused"
}

export { DiscountCode, DiscountCodeStatus };
export type { DiscountCodeData };