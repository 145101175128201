import { OnlineBookingContentWrapper } from "../OnlineBookingContentWrapper";
import { TitleWrapper } from "../shared/CommonWrappers";

export const ExceedsServiceAreaThankYou = () => {
    return (
        <OnlineBookingContentWrapper>
            <TitleWrapper
                title="Thank you"
                subtitle="We'll be in touch as soon as we are in your area!"
            />
        </OnlineBookingContentWrapper>
    );
};