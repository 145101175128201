import { Breed } from "@marathon/common/entities/Breed";
import { MobileServiceFeeConfiguration } from "@marathon/common/entities/Configuration";
import { Groomer } from "@marathon/common/entities/Groomer";
import { Hub } from "@marathon/common/entities/Hub";
import { PricingPreset } from "@marathon/common/entities/PricingPreset";
import { Service } from "@marathon/common/entities/Service";
import { createContext, useContext } from "react";
import { Discount } from "@marathon/common/entities/Discount";
import { UserCredential } from "firebase/auth";
import { AdsClickId } from "@marathon/common/entities/AdsActivity";

interface Props {
    hubs: Hub[],
    breeds: Breed[],
    services: Service[],
    pricingPresets: PricingPreset[],
    groomers: Groomer[],
    discounts: Discount[],
    mobileServiceFee: MobileServiceFeeConfiguration | null,
    loadingContextData: boolean,
    errorLoadingContextData: boolean,
    isAppointmentBooked: boolean,
    setIsAppointmentBooked?: React.Dispatch<React.SetStateAction<boolean>>,
    onSignIn: (options: { phoneNumber: string, recaptcha_id?: string }) => Promise<void>,
    onSignInAnonymously: () => Promise<UserCredential>,
    isSignedIn: boolean,
    isFromSms: boolean,
    setIsFromSms: React.Dispatch<React.SetStateAction<boolean>>,
    onSendSignInLinkToEmail: (email: string, url: string) => Promise<void>,
    clickId?: AdsClickId,
    setClickId: React.Dispatch<React.SetStateAction<AdsClickId | undefined>>
}

export const OnlineBookingContext = createContext<Props>({
    hubs: [],
    breeds: [],
    services: [],
    pricingPresets: [],
    groomers: [],
    discounts: [],
    mobileServiceFee: null,
    loadingContextData: false,
    errorLoadingContextData: false,
    isAppointmentBooked: false,
    onSignIn: async (_: { phoneNumber: string, recaptcha_id?: string }) => Promise.resolve(),
    onSignInAnonymously: async () => Promise.resolve({} as UserCredential),
    isSignedIn: false,
    isFromSms: false,
    setIsFromSms: (_: React.SetStateAction<boolean>) => null,
    onSendSignInLinkToEmail: async (_: string, __: string) => Promise.resolve(),
    clickId: undefined,
    setClickId: (_: React.SetStateAction<AdsClickId | undefined>) => null,
});

export const useOnlineBookingContext = () => useContext(OnlineBookingContext);

export type { Props };