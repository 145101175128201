import { Discount } from "@marathon/common/entities/Discount";
import { Box, Typography } from "@mui/material";

export const DiscountCard = ({ discount }: { discount: Discount }) => {
    return (
        <Box
            width={{ xs: "100%" }}
            height={"36px"}
            bgcolor={"#FBEDFF"}
            borderRadius={"10px"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            margin={"10px 0px"}
        >
            <Typography
                style={{ fontSize: 12 }}
                textAlign="center"
                fontFamily="Plain Bold"
                color="#CB29F3"
                fontWeight="bold">
                PROMO - ${discount.value} Discount will be applied
            </Typography>
        </Box>
    );
};