import { WEEKLY_FREQUENCY_TYPE } from "../entities/Recurrence";

export function formatNames(orignalNames: string[], lastSeparator = "and") {
    const names = [...orignalNames];
    if (names.length === 0) return "";
    const finalName = names.pop();
    return names.length ? `${names.join(", ")} ${lastSeparator} ${finalName}` : finalName;
}

export const getRepeatLabel = function (frequency: { type: string; interval: number; }) {
    let typeLabel = "";
    if (frequency.type === "DAILY") {
        typeLabel = "day(s)";
    } else if (frequency.type === WEEKLY_FREQUENCY_TYPE) {
        typeLabel = "week(s)";
    } else if (frequency.type === "MONTHLY") {
        typeLabel = "month(s)";
    }
    else {
        console.error(`Frequency type ${frequency.type} not valid`);
    }
    return `Repeat every ${frequency.interval} ${typeLabel}`;
};

export function truncateText(text: string, maxLength: number) {
    if (text.length > maxLength) {
        const truncated = text.substring(0, maxLength - 3) + "...";
        return truncated;
    }
    else {
        return text;
    }
}