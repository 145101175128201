import { AppointmentCredit } from "@marathon/common/entities/Appointment";

const CREDIT_LABEL = "Referral program credit";

interface CreditData {
    value: number,
    customer_referred: string,
    customer_referred_id: string,
    was_used: boolean,
}

interface Credit extends CreditData {
    id: string,
    customerId: string
}

class Credit {
    constructor(id: string, customerId: string, data: CreditData) {
        this.id = id;
        this.customerId = customerId;
        Object.assign(this, data);
    }
    toData(): CreditData {
        const { id, customerId, ...data } = this;
        return data;
    }
    static sum(creditsValues: AppointmentCredit[]) {
        return creditsValues.reduce((partialSum, a) => +partialSum + a.value, 0);
    }
    static apply(creditsValues: AppointmentCredit[], originalPrice: number) {
        const creditDiscount = creditsValues.reduce((partialSum, a) => +partialSum + a.value, 0);
        return originalPrice - creditDiscount;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromApi(serialized: any) {
        const { id, customerId, ...data } = serialized;
        return new Credit(id, customerId, data);
    }
}

export { Credit, CREDIT_LABEL };
export type { CreditData };