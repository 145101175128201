import { DateTime } from "luxon";
import { DatePicker as DefaultDatePicker, MobileDatePicker } from "@mui/x-date-pickers";

interface InputProps {
    [x: string]: unknown
}

interface Props {
    forceMobile?: true,
    label?: string,
    error?: boolean,
    helperText?: React.ReactNode,
    minDate?: Date,
    maxDate?: Date,
    value: Date | null,
    format?: string,
    onChange: (date: Date | null) => void,
    inputProps?: InputProps,
    [x: string]: unknown
}

export const DatePicker = ({ forceMobile, label, error, helperText, minDate, maxDate, value, format, onChange, inputProps, ...rest }: Props) => {
    const Component = forceMobile ? MobileDatePicker : DefaultDatePicker;
    return (
        <Component
            {...rest}
            slotProps={{
                textField: {
                    variant: forceMobile && "standard", label, error, helperText, ...rest, ...inputProps
                },
                actionBar: {
                    actions: ["today", "cancel", "accept"]
                }
            }}
            format={format ?? "MM/dd/yyyy"}
            minDate={minDate && DateTime.fromJSDate(minDate)}
            maxDate={maxDate && DateTime.fromJSDate(maxDate)}
            value={value ? DateTime.fromJSDate(value) : value}
            onChange={(date: DateTime | null) => {
                onChange(date && date.isValid ? date.toJSDate() : null);
            }}
        />
    );
};