import { DateTime } from "luxon";

enum GroomerLogActivities {
    conversationOpened = "Conversation opened",
    conversationClosed = "Conversation closed",
    chatbotTurnedOn = "Chatbot turned on",
    chatbotTurnedOff = "Chatbot turned off"
}

interface GroomerLogData {
    description: string,
    date: Date,
    user?: string
}

interface GroomerLog extends GroomerLogData {
    id: string
    groomerId: string
}

class GroomerLog {
    constructor(id: string, groomerId: string, data: GroomerLogData) {
        this.id = id;
        this.groomerId = groomerId;
        Object.assign(this, data);
    }
    toData(): GroomerLogData {
        const { id, groomerId, ...data } = this;
        return data;
    }
    toText() {
        return (
            this.description +
            (this.user ? ` by ${this.user}` : "") +
            ` on ${DateTime.fromJSDate(this.date).toFormat("MMMM d")} at ${DateTime.fromJSDate(this.date).toFormat("h:mm a")}`
        );
    }
}

export { GroomerLog, GroomerLogActivities };
export type { GroomerLogData };