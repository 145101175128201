enum CreditCardStatus {
    Active = "active",
    Inactive = "inactive"
}

interface CreditCardData {
    square_id: string,
    card_brand: string,
    last_segment: string,
    expiration_month: number,
    expiration_year: number,
    status: CreditCardStatus
}

interface CreditCard extends CreditCardData {
    id: string,
    customerId: string
}

class CreditCard {
    constructor(id: string, customerId: string, data: CreditCardData) {
        this.id = id;
        this.customerId = customerId;
        Object.assign(this, data);
    }
    toData(): CreditCardData {
        const { id, customerId, ...data } = this;
        return data;
    }
    toFormattedStringLine1(): string {
        return `${this.card_brand} **** ${this.last_segment}`;
    }
    toFormattedStringLine2(): string {
        const expirationMonth = this.expiration_month.toString().padStart(2, "0");
        const expirationYear = this.expiration_year.toString().substring(2);
        return `${expirationMonth}/${expirationYear}`;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromApi(serialized: any) {
        const { id, customerId, ...data } = serialized;
        return new CreditCard(id, customerId, data);
    }
}

export { CreditCard, CreditCardStatus };
export type { CreditCardData };