
import { Pet } from "@marathon/common/entities/Pet";
import { Breed } from "@marathon/common/entities/Breed";
import { PetInput } from "@marathon/client-side/entities/PetInput";
import PetFormWrapper from "../PetFormWrapper";
import { PetRepository } from "@marathon/client-side/repositories/PetRepository";

interface Props {
  pets: Pet[],
  editingPetIndex: number,
  handlePetChange: (index: number, newValue: PetInput) => void,
  onNext: (index: number) => void,
  breeds: Breed[],
  formError: string,
  isLoading: boolean
}

export default function LeadStepPetDetails({
  pets,
  editingPetIndex,
  handlePetChange,
  onNext,
  breeds,
  formError,
  isLoading
}: Props) {

  const handleNext = () => {
    onNext(editingPetIndex);
  };

  if (!pets.length)
    return null;

  const pet = pets[editingPetIndex];
  if (!pet)
    return null;

  return (
    <PetFormWrapper
      pet={PetRepository.toInput(pet)}
      handlePetChange={newValue => handlePetChange(editingPetIndex, newValue)}
      onNext={handleNext}
      lastPetIndex={editingPetIndex}
      breeds={breeds}
      formError={formError}
      isLoading={isLoading}
    />
  );
}
