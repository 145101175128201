import loadGoogleMapsApi from "@marathon/web/loadGoogleMapsApi";
import { useState, useEffect } from "react";

export const GoogleMapsLoader = ({ children }: { children: React.ReactNode }) => {
    const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false);

    useEffect(() => {
        const loadMaps = async () => {
            try {
                await loadGoogleMapsApi();
                setIsGoogleMapsLoaded(true);
            } catch (error) {
                console.error("Failed to load Google Maps API", error);
            }
        };

        loadMaps();
    }, []);

    if (!isGoogleMapsLoaded) {
        return <></>;
    }

    return (
        <>
            {children}
        </>
    );
};