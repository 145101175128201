import { CustomerAddressData } from "./Customer";

interface SecondaryAddressData extends CustomerAddressData {
    alias: string,
    status: SecondaryAddressStatus
}

interface SecondaryAddress extends SecondaryAddressData {
    id: string,
    customerId: string
}

enum SecondaryAddressStatus {
    active = "active",
    hidden = "hidden"
}

class SecondaryAddress {
    constructor(id: string, customerId: string, data: SecondaryAddressData) {
        this.id = id;
        this.customerId = customerId;
        Object.assign(this, data);
    }

    toData(): SecondaryAddressData {
        const { id, customerId, ...data } = this;
        return data;
    }
    coordinates() {
        return SecondaryAddress.getCoordinates(this.lat, this.lng);
    }
    finderValidAddress() {
        if (!this.drive_time || !this.place_id || !this.lat || !this.lng) {
            return null;
        }
        else {
            return {
                lat: this.lat,
                lng: this.lng,
                place_id: this.place_id,
                drive_time: this.drive_time
            };
        }
    }
    static getCoordinates(lat?: number, lng?: number) {
        return lat && lng ? { lat, lng } : undefined;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromApi(serialized: any) {
        const { id, customerId, ...data } = serialized;
        return new SecondaryAddress(id, customerId, data);
    }
}

export { SecondaryAddress, SecondaryAddressStatus };
export type { SecondaryAddressData };
