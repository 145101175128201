export const heicImageType = "image/heic";
export const jpegImageType = "image/jpeg";
export const gifImageType = "image/gif";

export const acceptedImageTypes = [
    gifImageType,
    heicImageType,
    jpegImageType,
    "image/jpg",
    "image/png"
];

export const unsupportedVideoTypes = [
    "video/3gpp",
    "video/3gpp2",
    "video/3gpp-tt"
];

export const acceptedVideoTypes = [
    "video/mpeg",
    "video/mp4",
    "video/quicktime",
    "video/webm",
    "video/3gpp",
    "video/3gpp2",
    "video/3gpp-tt",
    "video/h261",
    "video/h263",
    "video/h263-1998",
    "video/h263-2000",
    "video/h264"
];

export const vcardFileTypes = [
    "text/vcard",
    "text/x-vcard"
];