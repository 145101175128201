import { forwardRef, useState, useEffect, useCallback } from "react";
import TextField from "@mui/material/TextField";
import { default as Phone, getCountryCallingCode } from "react-phone-number-input/input";
import { isPossiblePhoneNumber, Country } from "react-phone-number-input";

interface Props {
    countryCode?: Country,
    label: string,
    value: string,
    useLocalFormat: boolean,
    error: boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: any,
    onValidate: (value: boolean) => void,
    autoFocus?: boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [x: string]: any
}

const PhoneInput = ({ countryCode = "US", label = "Phone Number", value, useLocalFormat, error, onChange, onValidate, autoFocus = false, ...rest }: Props) => {

    const [phoneNumber, setPhoneNumber] = useState();
    const getCountryCode = useCallback(() => {
        return "+" + getCountryCallingCode(countryCode) ?? "";
    }, [countryCode]);

    const getInternationalPhoneNumber = useCallback((value) => {
        let internationalNumber = value;
        if (useLocalFormat && value && !value.startsWith(getCountryCode())) {
            internationalNumber = getCountryCode() + value;
        }
        return internationalNumber;
    }, [useLocalFormat, getCountryCode]);

    const validatePhoneNumber = useCallback((value) => {
        onValidate(isPossiblePhoneNumber(value ?? ""));
    }, [onValidate]);

    useEffect(() => {
        const internationalPhoneNumber = getInternationalPhoneNumber(value);
        setPhoneNumber(internationalPhoneNumber);
        validatePhoneNumber(internationalPhoneNumber);

    }, [value, getInternationalPhoneNumber, validatePhoneNumber]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChange = (value: any) => {
        setPhoneNumber(value);
        let localNumber = value;
        if (useLocalFormat && value) {
            localNumber = value.replace(getCountryCode(), "");
        }
        onChange(localNumber);
        validatePhoneNumber(value);
    };

    return (
        <Phone
            {...rest}
            country={countryCode}
            value={getInternationalPhoneNumber(phoneNumber)}
            onChange={handleChange}
            // @ts-ignore
            inputComponent={input}
            label={label}
            error={error}
            autoFocus={autoFocus}
        />
    );
};

export default PhoneInput;

const input = forwardRef((props, ref) => (
    <TextField
        {...props}
        inputRef={ref}
    />
));