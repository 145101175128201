import { AddToCalendar } from "@marathon/web/components/AddToCalendar";
import { BookingSuggestion } from "@marathon/common/api/BookingSuggestion";
import { AppointmentInfoHeader } from "@marathon/web/components/appointment/AppointmentInfoHeader";
import { Recurrence } from "@marathon/common/entities/Recurrence";
import { Appointment } from "@marathon/common/entities/Appointment";
import { Pet } from "@marathon/common/entities/Pet";

interface AppointmentScheduledCardProps {
    suggestion: BookingSuggestion,
    appointment?: Appointment | Recurrence | null,
    onBlurAddToCalendarCallback?: () => void,
    pets: Pet[],
}

export const AppointmentScheduledCard = ({
    suggestion,
    appointment,
    onBlurAddToCalendarCallback,
    pets }: AppointmentScheduledCardProps) => {

    const getEvent = () => {
        if (!appointment)
            return null;

        return {
            title: "Barkbus Appointment",
            description: "Appointment with Barkbus at the scheduled time",
            location: appointment.customer.city,
            startTime: appointment.start_time,
            endTime: appointment.end_time,
            frequency: appointment.frequency,
            timeZone: appointment.time_zone
        };
    };

    const petsToShow = appointment
        ? pets.filter(x => appointment.selected_pets.map(y => y.petId).includes(x.id))
        : [];

    return (
        <div className="appointment-suggestion">
            <AppointmentInfoHeader suggestion={suggestion} isFromOnlineBooking />
            {petsToShow.length > 0 &&
                <>
                    <br />{`Pet${petsToShow.length > 1 ? "s" : ""}: ${petsToShow.map(x => x.name).join(", ")}`}
                </>}
            <AddToCalendar event={getEvent()} onBlurCallback={onBlurAddToCalendarCallback} />
        </div>
    );
};

