import { Box } from "@mui/material";
import { OnlineBookingContentWrapper } from "../OnlineBookingContentWrapper";
import { TitleWrapper } from "../shared/CommonWrappers";
import AddressForm, { AddressFormProps } from "../shared/AddressForm";

export default function LeadStepAddress({
  address,
  onAddressAutocompleteChange,
  handleChange,
  onNext,
  isLoading,
  isGeocoding
}: AddressFormProps) {

  return (
    <OnlineBookingContentWrapper>
      <Box display="flex" flexDirection="column">
        <TitleWrapper
          title="Welcome!"
          headerSubtitle="What address will we be servicing?"
          fullWidth
        />
        <AddressForm
          address={address}
          handleChange={handleChange}
          isGeocoding={isGeocoding}
          isLoading={isLoading}
          onAddressAutocompleteChange={onAddressAutocompleteChange}
          onNext={onNext}
        />
      </Box>
    </OnlineBookingContentWrapper>
  );
}
