import { UserCredential } from "firebase/auth";
import { AppointmentSeed } from "@marathon/client-side/entities/AppointmentSeed";
import { Groomer } from "@marathon/common/entities/Groomer";
import { User } from "@marathon/common/entities/User";
import { AvailableRecurringSlotInput } from "@marathon/client-side/components/AvailableRecurringSlotWrapper";
import { AVAILABLE_RECURRING_SLOT_INPUT_KEY } from "@marathon/client-side/utilities/DeviceStorage";
import DeviceStorageCache from "@marathon/client-side/utilities/DeviceStorageCache";

const UID_KEY = "uidfir";
const USERNAME_KEY = "username";
const USERROLE_KEY = "userrole";
const APPOINTMENT_SEED_KEY = "appointmentSeed";

function dateTimeReviver(_: unknown, value: unknown) {
    let a;
    if (typeof value === "string") {
        a = Date.parse(value);
        if (a) {
            return new Date(+a);
        }
    }
    return value;
}

class LocalStorage {
    public static setCurrentUser(firebaseResult: UserCredential, currentUser: Groomer | User) {
        const uid = firebaseResult.user.uid;
        const name = `${currentUser.firstname} ${currentUser.lastname}`;
        const role = currentUser.role;

        localStorage.setItem(UID_KEY, uid);
        localStorage.setItem(USERNAME_KEY, name);
        localStorage.setItem(USERROLE_KEY, role);

        DeviceStorageCache.setCurrentUser(uid, name, role);
    }
    public static clearCurrentUser() {
        localStorage.removeItem(UID_KEY);
        localStorage.removeItem(USERNAME_KEY);
        localStorage.removeItem(USERROLE_KEY);

        DeviceStorageCache.setCurrentUser(null, null, null);
    }
    public static hydrateCache() {
        const uid = localStorage.getItem(UID_KEY);
        const name = localStorage.getItem(USERNAME_KEY);
        const role = localStorage.getItem(USERROLE_KEY);
        DeviceStorageCache.setCurrentUser(uid, name, role);
    }
    public static isUserAuthenticated() {
        return localStorage.getItem(UID_KEY) !== null;
    }
    public static setStoredAppointmentSeed(appointmentSeed: AppointmentSeed) {
        localStorage.setItem(APPOINTMENT_SEED_KEY, JSON.stringify(appointmentSeed));
    }
    public static getStoredAppointmentSeed() {
        const localData = localStorage.getItem(APPOINTMENT_SEED_KEY);
        return (
            localData
                ? JSON.parse(localData, dateTimeReviver) as AppointmentSeed
                : null
        );
    }
    public static clearStoredAppointmentSeed() {
        localStorage.removeItem(APPOINTMENT_SEED_KEY);
    }
    public static setAvailableRecurringSlotInput(recurringSlotInput: AvailableRecurringSlotInput) {
        localStorage.setItem(AVAILABLE_RECURRING_SLOT_INPUT_KEY, JSON.stringify(recurringSlotInput));
    }
    public static getStoredAvailableRecurringSlotInput() {
        const localData = localStorage.getItem(AVAILABLE_RECURRING_SLOT_INPUT_KEY);
        return (
            localData
                ? JSON.parse(localData, dateTimeReviver) as AvailableRecurringSlotInput
                : null
        );
    }
    public static clearAvailableRecurringSlotInput() {
        localStorage.removeItem(AVAILABLE_RECURRING_SLOT_INPUT_KEY);
    }
}

export default LocalStorage;