export default function setupAddEvent(license: string) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).addeventasync = function () {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).addeventatc.settings({
            license: license,
            appleical: { show: true, text: "Apple" },
            google: { show: true, text: "Google" },
            office365: { show: false, text: "Office 365" },
            outlook: { show: false, text: "Outlook" },
            outlookcom: { show: true, text: "Outlook" },
            yahoo: { show: true, text: "Yahoo" },
            mouse: true,
            css: false
        });
    };
}