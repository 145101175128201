import { getFunctions, httpsCallable } from "firebase/functions";
import { IFunctionService } from "@marathon/client-side/utilities/IFunctionService";

export class FunctionService implements IFunctionService {
    async callFunction<T, R>(functionName: string, data: T, options?: { timeout: number }): Promise<{ data: R }> {
        const functions = getFunctions();
        const callableFunction = httpsCallable<T, R>(functions, functionName, options);
        const result = await callableFunction(data);
        return { data: result.data as R };
    }
}