import "reflect-metadata";
import ReactDOM from "react-dom";
import App from "components/App";
import "@marathon/web/setupApp";
import setupAddEvent from "@marathon/web/helpers/addEventHelper";
import mixpanel from "mixpanel-browser";

if (process.env.REACT_APP_MIXPANEL_TOKEN) {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: process.env.REACT_APP_MIXPANEL_DEBUG_MODE === "true" });
}


if (process.env.REACT_APP_ADD_EVENT_LICENSE) {
    setupAddEvent(process.env.REACT_APP_ADD_EVENT_LICENSE);
}

ReactDOM.render(<App />, document.querySelector("#root"));