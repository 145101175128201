import { Grid } from "@mui/material";
import { DateTime } from "luxon";
import Swal from "sweetalert2";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import LocalDate from "@marathon/common/utilities/LocalDate";
import { TimeZone } from "@marathon/common/helpers/timeZoneHelper";
import useStyles from "@marathon/web/components/onlineBooking/styles";
import { DatePicker } from "@marathon/web/components/DatePicker";
import { Appointment, AppointmentTimeFilter } from "@marathon/common/entities/Appointment";
import { Groomer } from "@marathon/common/entities/Groomer";
import { Pet } from "@marathon/common/entities/Pet";

const ANY_STYLIST_FILTER = "any-stylist";
const ANY_TIME_FILTER = "any-time";

interface AppointmentSuggestionFilterProps {
    setSpecificDate: React.Dispatch<React.SetStateAction<LocalDate | null>>,
    setupNewSearch?: () => void,
    specificDate: LocalDate | null,
    selectedTimeFilter: AppointmentTimeFilter | null,
    setSelectedTimeFilter: React.Dispatch<React.SetStateAction<AppointmentTimeFilter | null>>,
    selectedGroomerId: string | null,
    setSelectedGroomerId: React.Dispatch<React.SetStateAction<string | null>>,
    customerTimeZone: TimeZone,
    groomers: Groomer[],
    selectedPetIds?: string[] | null,
    setSelectedPetIds?: React.Dispatch<React.SetStateAction<string[] | null>>,
    pets?: Pet[]
}

export const AppointmentSuggestionFilter = ({
    specificDate,
    setSpecificDate,
    setupNewSearch,
    selectedTimeFilter,
    setSelectedTimeFilter,
    selectedGroomerId,
    setSelectedGroomerId,
    customerTimeZone,
    groomers,
    selectedPetIds,
    setSelectedPetIds,
    pets }: AppointmentSuggestionFilterProps) => {
    const classes = useStyles();

    return (
        <>
            <div className="suggestion-filters">
                <Grid container spacing={2}>
                    {pets && pets.length > 0 &&
                        <Grid item xs={12} display="inline-flex" justifyContent="flex-start">
                            <FormControl className={classes.suggestionsFilterControl}>
                                <Select
                                    disableUnderline={true}
                                    labelId="online-booking-pets-filter"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={selectedPetIds || []}
                                    onChange={(e) => {
                                        setSelectedPetIds && setSelectedPetIds(e.target.value as string[]);
                                    }}
                                    inputProps={{
                                        "aria-labelledby": "online-booking-pets-filter",
                                        classes: {
                                            select: classes.suggestionsFilterControl
                                        }
                                    }}
                                >
                                    {pets.map((pet, index) => (
                                        <MenuItem key={index} value={pet.id}>
                                            {pet.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>}
                    <Grid item xs={6} display="inline-flex" justifyContent="flex-start" className="specific-date-picker">
                        <FormControl variant="standard" className={classes.suggestionsFilterControl}>
                            <DatePicker
                                minDate={DateTime.now().plus({ days: 1 }).startOf("day").toJSDate()}
                                margin="normal"
                                value={specificDate?.toDayStartForSystemTimeZone() || null}
                                onChange={date => {
                                    if (!date) {
                                        setSpecificDate(null);
                                        setupNewSearch && setupNewSearch();
                                    }
                                    else {
                                        const _specificDate = LocalDate.forSystemTimeZone(date, customerTimeZone);
                                        if (!Appointment.isValidSuggestionsDateFilter(_specificDate)) {
                                            Swal.fire({ title: "Please select a future date" });
                                        }
                                        else {
                                            setSpecificDate(_specificDate);
                                            setupNewSearch && setupNewSearch();
                                        }
                                    }
                                }}
                                inputVariant="outlined"
                                inputProps={{
                                    "aria-label": "Specific Date",
                                    classes: {
                                        root: classes.filterControlPicker
                                    }
                                }}
                                KeyboardButtonProps={{
                                    "aria-label": "Select Date"
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} display="inline-flex" justifyContent="flex-start">
                        <FormControl className={classes.suggestionsFilterControl}>
                            <Select
                                disableUnderline={true}
                                labelId="online-booking-time-filter"
                                value={selectedTimeFilter || ANY_TIME_FILTER}
                                onChange={(e) => {
                                    setSelectedTimeFilter(
                                        e.target.value === ANY_TIME_FILTER
                                            ? null
                                            : e.target.value as AppointmentTimeFilter
                                    );
                                    setupNewSearch && setupNewSearch();
                                }}
                                inputProps={{
                                    "aria-labelledby": "online-booking-time-filter",
                                    classes: {
                                        select: classes.suggestionsFilterControl
                                    }
                                }}
                            >
                                <MenuItem value={ANY_TIME_FILTER}>Time</MenuItem>
                                {Object.values(AppointmentTimeFilter).map((timeFilter, idx) => <MenuItem key={idx} value={timeFilter}>{Appointment.getAppointmentTimeFilterLabel(timeFilter)}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} display="inline-flex" justifyContent="flex-start">
                        <FormControl className={classes.suggestionsFilterControl}>
                            <Select
                                disableUnderline={true}
                                labelId="online-booking-stylist-filter"
                                value={selectedGroomerId || ANY_STYLIST_FILTER}
                                onChange={(e) => {
                                    setSelectedGroomerId(e.target.value === ANY_STYLIST_FILTER ? null : e.target.value as string);
                                    setupNewSearch && setupNewSearch();
                                }}
                                inputProps={{
                                    "aria-labelledby": "online-booking-stylist-filter",
                                    classes: {
                                        select: classes.suggestionsFilterControl
                                    }
                                }}
                            >
                                <MenuItem value={ANY_STYLIST_FILTER}>Stylist</MenuItem>
                                {groomers.map((x, idx) => <MenuItem key={idx} value={x.id}>{x.obfuscatedName()}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};