import { Appointment } from "@marathon/common/entities/Appointment";
import { Customer } from "@marathon/common/entities/Customer";
import { Pet } from "@marathon/common/entities/Pet";
import { Credit } from "@marathon/common/entities/Credit";
import { Recurrence } from "@marathon/common/entities/Recurrence";
import { BookingSuggestion, BookingSuggestionSearchParameters } from "@marathon/common/api/BookingSuggestion";
import { HuntedSuggestion, HuntedSuggestionSearchParameters } from "@marathon/common/api/HuntedSuggestion";
import { GroomerCalendarGap, GroomerCalendarGapSearchParameters } from "@marathon/common/api/GroomerCalendarGap";
import { CalendarOverlap, CalendarOverlapSearchParameters } from "@marathon/common/api/CalendarOverlap";
import { AppointmentsReportFilterBy, AppointmentsReportParameters } from "@marathon/common/api/AppointmentsReport";
import { CustomersReportParameters } from "@marathon/common/api/CustomersReport";
import { GetCustomerResponseRawData, GetCustomerResponse } from "@marathon/common/api/GetCustomer";
import { GetTwilioTokenResponse } from "@marathon/common/api/TwilioToken";
import { AppointmentOrOccurrence } from "@marathon/common/entities/Occurrence";
import { CustomerPublicInfoParameters, CustomerPublicInfoResponse, CustomerEmailParameters, CustomerEmailResponse } from "@marathon/common/api/CustomerInfo";
import { InvitationCodeInfoParameters, InvitationCodeInfoResponse } from "@marathon/common/api/InvitationCodeInfo";
import { DeleteUserRequest, RegisterUserParameters, RegisterUserResponse, UpdatePasswordRequest } from "@marathon/common/api/User";
import { EnableChatbotParameters } from "@marathon/common/api/EnableChatbot";
import { AppointmentOnHoldDataResponse, AppointmentOnHoldDataResponseRawData, AppointmentOnHoldRequest } from "@marathon/common/api/AppointmentOnHold";
import { TokenizedSuggestionDataResponse, TokenizedSuggestionRequest, TokenizedSuggestionResponseRawData } from "@marathon/common/api/TokenizedSuggestion";
import { SyncAssistantParameters } from "@marathon/common/api/SyncAssistant";
import { BroadcastMessageParameters } from "@marathon/common/api/BroadcastMessage";
import { IsNewCustomerParameters } from "@marathon/common/api/IsNewCustomer";
import { IsFirstAppointmentParameters } from "@marathon/common/api/IsFirstAppointment";
import { CustomerAppointmentsRequest } from "@marathon/common/api/CustomerAppointments";
import { CreateSquareCardRequest, DisableCreditCardResponse, DisableSquareCardRequest, GetSquareCardsRequest, CreateSquareProfileRequest } from "@marathon/common/api/Square";
import { TimeZone } from "@marathon/common/helpers/timeZoneHelper";
import LocalDate from "@marathon/common/utilities/LocalDate";
import { Gender } from "@marathon/common/entities/Groomer";
import { CustomerAddressData } from "@marathon/common/entities/Customer";
import { SecondaryAddress } from "@marathon/common/entities/SecondaryAddress";
import { FunctionParamsBase } from "@marathon/common/api/FunctionParamsBase";
import { CreditCard } from "@marathon/common/entities/CreditCard";
import { RouteMatchingDrivetimeParameters, RouteMatchingDrivetimeResponse } from "@marathon/common/api/RouteMatchingDrivetime";
import { DiscountCode } from "@marathon/common/entities/DiscountCode";
import { datesToTimestamps, timestampsToDates } from "../helpers/serializationHelper";
import { container, inject, singleton } from "tsyringe";
import type { IFunctionService } from "./IFunctionService";
import { PromptId } from "@marathon/common/entities/GPTPromptUtils";

@singleton()
export default class CallableFunctions {
    private functionService: IFunctionService;
    constructor(@inject("IFunctionService") functionService: IFunctionService) {
        this.functionService = functionService;
    }
    static get current() {
        return container.resolve(CallableFunctions);
    }
    get artificialIntelligence() {
        const prefix = "artificialIntelligence";
        return {
            enableChatbot: async (customerId: string, initialMessageCount: number, turnedOnBy: string, context?: string) => {
                const functionName = `${prefix}-enableChatbot`;
                await callFunction<EnableChatbotParameters, void>(this.functionService, functionName, { customerId, initialMessageCount, turnedOnBy, context });
            },
            syncAssistant: async (promptId: PromptId) => {
                const functionName = `${prefix}-syncAssistant`;
                await callFunction<SyncAssistantParameters, void>(this.functionService, functionName, { promptId });
            }
        };
    }
    get broadcastMessage() {
        const prefix = "broadcastMessage";
        return {
            sendBroadcastMessage: async (customerIds: string[], content: string) => {
                const functionName = `${prefix}-sendBroadcastMessage`;
                await callFunction<BroadcastMessageParameters, void>(this.functionService, functionName, { customerIds, content });
            }
        };
    }
    get insights() {
        const prefix = "insights";
        return {
            getHuntedSuggestions: async (
                startDate: LocalDate,
                endDate: LocalDate,
                lapsedForDays: number,
                maximumLapsedDays: number,
                maximumDistanceMiles: number,
                hubId: string,
                noActivityForDays: number,
                targetAppointmentId?: string
            ) => {
                const functionName = `${prefix}-getHuntedSuggestions`;
                const parameters = { startDate, endDate, lapsedForDays, maximumLapsedDays, maximumDistanceMiles, hubId, noActivityForDays, targetAppointmentId } as HuntedSuggestionSearchParameters;
                const data = await callFunction(this.functionService, functionName, parameters, { timeout: 300_000 });
                return data as HuntedSuggestion[];
            },
            getGroomerCalendarGaps: async (
                startDate: LocalDate,
                endDate: LocalDate,
                hubOptionId: string) => {
                const functionName = `${prefix}-getGroomerCalendarGaps`;
                const parameters = { startDate, endDate, hubOptionId } as GroomerCalendarGapSearchParameters;
                const data = await callFunction(this.functionService, functionName, parameters);
                return data as GroomerCalendarGap[];
            },
            getCalendarOverlaps: async (
                startDate: LocalDate,
                endDate: LocalDate,
                hubOptionId: string) => {
                const functionName = `${prefix}-getCalendarOverlaps`;
                const parameters = { startDate, endDate, hubOptionId } as CalendarOverlapSearchParameters;
                const data = await callFunction(this.functionService, functionName, parameters);
                return data as CalendarOverlap[];
            },
            refreshAllRegionsTotals: async () => {
                const functionName = `${prefix}-refreshAllRegionsTotals`;
                return await callFunction(this.functionService, functionName, {});
            }
        };
    }
    get public() {
        const prefix = "public";
        return {
            getCustomerPublicInfo: async (params: CustomerPublicInfoParameters): Promise<CustomerPublicInfoResponse | null> => {
                const functionName = `${prefix}-getCustomerPublicInfo`;
                return await callFunction<CustomerPublicInfoParameters, CustomerPublicInfoResponse | null>(this.functionService, functionName, params);
            },
            getCustomerEmail: async (params: CustomerEmailParameters): Promise<CustomerEmailResponse | null> => {
                const functionName = `${prefix}-getCustomerEmail`;
                return await callFunction<CustomerEmailParameters, CustomerEmailResponse | null>(this.functionService, functionName, params);
            },
            getInvitationCodeInfo: async (params: InvitationCodeInfoParameters): Promise<InvitationCodeInfoResponse | null> => {
                const functionName = `${prefix}-getInvitationCodeInfo`;
                return await callFunction<InvitationCodeInfoParameters, InvitationCodeInfoResponse | null>(this.functionService, functionName, params);
            },
            getCurrentCustomer: async (): Promise<GetCustomerResponse | null> => {
                const functionName = `${prefix}-getCurrentCustomer`;
                const data = await callFunction<FunctionParamsBase, GetCustomerResponseRawData | null>(this.functionService, functionName, {});
                if (!data) {
                    return null;
                }
                else {
                    return {
                        customer: Customer.fromApi(data.customer),
                        pets: data.pets.map(x => Pet.fromApi(x)),
                        credits: data.credits.map(x => Credit.fromApi(x)),
                        secondaryAddresses: data.secondaryAddresses.map(x => SecondaryAddress.fromApi(x))
                    };
                }
            },
            getRouteMatchingDriveTimes: async (params: RouteMatchingDrivetimeParameters): Promise<RouteMatchingDrivetimeResponse | null> => {
                const functionName = `${prefix}-getRouteMatchingDriveTimes`;
                return await callFunction<RouteMatchingDrivetimeParameters, RouteMatchingDrivetimeResponse | null>(this.functionService, functionName, params);
            },
            getBookingSuggestions: async (
                customerId: string,
                serviceIds: string[],
                selectedAddress: CustomerAddressData | SecondaryAddress,
                specificDate?: LocalDate,
                groomerId?: string,
                timeRange?: { min: number, max: number },
                rankingCriteria?: string,
                ignoreDistanceLimit?: true,
                daysOfWeek?: string[],
                groomerGender?: Gender
            ) => {
                const functionName = `${prefix}-getBookingSuggestions`;
                const parameters = { customerId, serviceIds, selectedAddress, specificDate, groomerId, timeRange, rankingCriteria, ignoreDistanceLimit, daysOfWeek, groomerGender } as BookingSuggestionSearchParameters;
                const data = await callFunction(this.functionService, functionName, parameters);
                return data as BookingSuggestion[];
            },
            getAppointmentOnHoldData: async (appointmentId: string): Promise<AppointmentOnHoldDataResponse | null> => {
                const functionName = `${prefix}-getAppointmentOnHoldData`;
                const data = await callFunction<AppointmentOnHoldRequest, AppointmentOnHoldDataResponseRawData | null>(this.functionService, functionName, { appointmentId });
                if (!data) {
                    return null;
                }
                else {
                    return {
                        appointment: Appointment.fromApi(data.appointment),
                        customerPets: data.customerPets.map(x => Pet.fromApi(x))
                    };
                }
            },
            getTokenizedSuggestionData: async (token: string, discountCode?: string): Promise<TokenizedSuggestionDataResponse | null> => {
                const functionName = `${prefix}-getTokenizedSuggestionData`;
                const data = await callFunction<TokenizedSuggestionRequest, TokenizedSuggestionResponseRawData | null>(this.functionService, functionName, { token, discountCode });
                if (!data) {
                    return null;
                }
                else {
                    return {
                        bookingSuggestion: data.bookingSuggestion,
                        customer: Customer.fromApi(data.customer),
                        selectedServices: data.selectedServices as { petId: string, serviceId: string }[],
                        pets: data.pets.map(x => Pet.fromApi(x)),
                        credits: data.credits.map(x => Credit.fromApi(x)),
                        isValid: data.isValid,
                        discount: data.discount ? DiscountCode.fromApi(data.discount) : undefined
                    };
                }
            },
            getFutureCustomerAppointments: async (numberOfOccurrences: number) => {
                const functionName = `${prefix}-getCustomerAppointments`;
                const parameters = { type: "future", numberOfOccurrences } as CustomerAppointmentsRequest;
                const data = await callFunction(this.functionService, functionName, parameters);
                return (data ?? []) as AppointmentOrOccurrence[];
            },
            getPastCustomerAppointments: async () => {
                const functionName = `${prefix}-getCustomerAppointments`;
                const parameters = { type: "past" } as CustomerAppointmentsRequest;
                const data = await callFunction(this.functionService, functionName, parameters);
                return (data ?? []) as AppointmentOrOccurrence[];
            },
            getCustomerRecurrences: async () => {
                const functionName = `${prefix}-getCustomerRecurrences`;
                const data = await callFunction<FunctionParamsBase, unknown[]>(this.functionService, functionName, {});
                return data.map(x => Recurrence.fromApi(x));
            },
            isNewCustomer: async (customerId: string) => {
                const functionName = `${prefix}-isNewCustomer`;
                return await callFunction<IsNewCustomerParameters, boolean>(this.functionService, functionName, { customerId });
            },
            isFirstAppointment: async (groomerId: string, startTime: Date, timeZone: TimeZone) => {
                const functionName = `${prefix}-isFirstAppointment`;
                const parameters = { groomerId, startTime, timeZone } as IsFirstAppointmentParameters;
                return await callFunction<IsFirstAppointmentParameters, boolean>(this.functionService, functionName, parameters);
            }
        };
    }
    get reports() {
        const prefix = "reports";
        return {
            getAppointmentsReport: async (startDate: LocalDate, endDate: LocalDate, timeZone: TimeZone, filterBy: AppointmentsReportFilterBy) => {
                const functionName = `${prefix}-appointments`;
                const parameters = { startDate, endDate, timeZone, timeOffsetInMinutes: new Date().getTimezoneOffset(), filterBy } as AppointmentsReportParameters;
                const data = await callFunction(this.functionService, functionName, parameters, { timeout: 540_000 });
                return data as unknown[];
            },
            getCustomersReport: async (hasUpcoming?: boolean, startAgo?: number, endAgo?: number, createdInYear?: number) => {
                const functionName = `${prefix}-customers`;
                const parameters = { hasUpcoming, startAgo, endAgo, createdInYear, timeOffsetInMinutes: new Date().getTimezoneOffset() } as CustomersReportParameters;
                const data = await callFunction(this.functionService, functionName, parameters, { timeout: 300_000 });
                return data as unknown[];
            },
            getRecurrencesReport: async () => {
                const functionName = `${prefix}-recurrences`;
                const data = await callFunction(this.functionService, functionName, {}, { timeout: 300_000 });
                return data as unknown[];
            }
        };
    }
    get square() {
        const prefix = "square";
        return {
            getCustomerSquareCards: async (customerId?: string): Promise<CreditCard[]> => {
                const functionName = `${prefix}-getCustomerSquareCards`;
                const data = await callFunction<GetSquareCardsRequest, unknown[]>(this.functionService, functionName, { customerId });
                return data.map(x => CreditCard.fromApi(x));
            },
            createCustomerSquareProfile: async (customerId: string): Promise<void> => {
                const functionName = `${prefix}-createCustomerSquareProfile`;
                return await callFunction<CreateSquareProfileRequest, void>(this.functionService, functionName, { customerId });
            },
            createCustomerSquareCard: async (cardToken: string, customerId?: string): Promise<CreditCard | null> => {
                const functionName = `${prefix}-createCustomerSquareCard`;
                const data = await callFunction<CreateSquareCardRequest, unknown>(this.functionService, functionName, { cardToken, customerId });
                return data ? CreditCard.fromApi(data) : null;
            },
            disableCustomerSquareCard: async (creditCardId: string, customerId?: string): Promise<DisableCreditCardResponse> => {
                const functionName = `${prefix}-disableCustomerSquareCard`;
                return await callFunction<DisableSquareCardRequest, DisableCreditCardResponse>(this.functionService, functionName, { creditCardId, customerId });
            }
        };
    }
    get twilio() {
        const prefix = "twilio";
        return {
            getTwilioToken: async () => {
                const functionName = `${prefix}-getToken`;
                return await callFunction<FunctionParamsBase, GetTwilioTokenResponse>(this.functionService, functionName, {});
            }
        };
    }
    get users() {
        const prefix = "users";
        return {
            registerUser: async (params: RegisterUserParameters) => {
                const functionName = `${prefix}-register`;
                return await callFunction<RegisterUserParameters, RegisterUserResponse>(this.functionService, functionName, params);
            },
            deleteUser: async (uid: string) => {
                const functionName = `${prefix}-delete`;
                return await callFunction<DeleteUserRequest, void>(this.functionService, functionName, { uid });
            },
            updateUserPassword: async (uid: string, password: string) => {
                const functionName = `${prefix}-updatePassword`;
                return await callFunction<UpdatePasswordRequest, void>(this.functionService, functionName, { uid, password });
            }
        };
    }
}

async function callFunction<T extends FunctionParamsBase, U>(functionService: IFunctionService, name: string, parameters: T, options?: { timeout: number }): Promise<U> {
    setClientAppVersion(parameters);
    datesToTimestamps(parameters);
    const { data } = await functionService.callFunction<T, U>(name, parameters, options);
    timestampsToDates(data);
    return data;
}

function setClientAppVersion<T extends FunctionParamsBase>(parameters: T) {
    const clientAppVersion =
        process.env.REACT_APP_CLIENT_VERSION ||
        process.env.EXPO_PUBLIC_CLIENT_VERSION;
    if (!clientAppVersion)
        throw new Error("Missing client app version");

    parameters.clientAppVersion = clientAppVersion;
}