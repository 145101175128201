import useStyles from "@marathon/web/components/onlineBooking/styles";
import { OnlineBookingContentWrapper } from "../OnlineBookingContentWrapper";
import { FormControlLabel, FormHelperText, Grid, Radio, TextField } from "@mui/material";
import { Button } from "@marathon/web/components/Button";
import { useState } from "react";
import { TitleWrapper, ContentWrapper, FieldsetWrapper } from "../shared/CommonWrappers";

const optionValues = {
    oneDog: { text: "1 Dog", value: 1 },
    twoDogs: { text: "2 Dogs", value: 2 },
    threeDogs: { text: "3 Dogs", value: 3 },
    fourDogsOrMore: { text: "4+ Dogs!", value: 4 }
};

interface Props {
    onNext: Function;
    petsAmount: number;
    setPetsAmount: (petsAmount: number) => void;
}

export const LeadStepPetsAmount = ({ onNext, petsAmount, setPetsAmount }: Props) => {
    const classes = useStyles();
    const [selectedOption, setSelectedOption] = useState<number>(petsAmount);
    const [freeValue, setFreeValue] = useState<string>("");
    const [error, setError] = useState<string>("");

    const handleRadioChange = (value: number) => {
        setSelectedOption(value);
        setFreeValue("");
        setError("");
    };

    const handleFreeValueInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setError("");
        setFreeValue(value);
    };

    const handleSubmit = () => {
        const amount = selectedOption < optionValues.fourDogsOrMore.value ? selectedOption : parseInt(freeValue);
        if (!amount) {
            setError("Please enter a valid number");
            return;
        }
        setPetsAmount(amount);
        onNext();
    };

    return (
        <OnlineBookingContentWrapper>
            <TitleWrapper
                title="Lets talk dogs..."
                subtitle="How many dogs do you have?"
            />
            <ContentWrapper>
                <FieldsetWrapper>
                    <Grid container spacing={2} className={classes.radioGrid}>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                checked={selectedOption === optionValues.oneDog.value}
                                control={<Radio />}
                                label={optionValues.oneDog.text}
                                onChange={() => handleRadioChange(optionValues.oneDog.value)}
                                classes={{ root: classes.radioButton }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                checked={selectedOption === optionValues.twoDogs.value}
                                control={<Radio />}
                                label={optionValues.twoDogs.text}
                                onChange={() => handleRadioChange(optionValues.twoDogs.value)}
                                classes={{ root: classes.radioButton }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                checked={selectedOption === optionValues.threeDogs.value}
                                control={<Radio />}
                                label="3 Dogs"
                                onChange={() => handleRadioChange(optionValues.threeDogs.value)}
                                classes={{ root: classes.radioButton }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                checked={selectedOption === optionValues.fourDogsOrMore.value}
                                control={<Radio />}
                                label={optionValues.fourDogsOrMore.text}
                                onChange={() => handleRadioChange(optionValues.fourDogsOrMore.value)}
                                classes={{ root: classes.radioButton }}
                            />
                        </Grid>
                        {selectedOption === optionValues.fourDogsOrMore.value &&
                            <Grid item xs={12}>
                                <TextField
                                    className={classes.textField}
                                    autoComplete="off"
                                    fullWidth
                                    type="number"
                                    variant="outlined"
                                    color="primary"
                                    size="medium"
                                    label="Please specify how many"
                                    value={freeValue}
                                    onChange={handleFreeValueInput}
                                />
                            </Grid>}
                    </Grid>
                    <FormHelperText>{error}</FormHelperText>
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        className={classes.button}
                        disabled={!freeValue && !selectedOption}
                        disableElevation>
                        Continue
                    </Button>
                </FieldsetWrapper>
            </ContentWrapper>
        </OnlineBookingContentWrapper>
    );
};