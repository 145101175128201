import { Box, FormControl } from "@mui/material";

interface TitleWrapperProps {
    title?: string;
    subtitle?: string;
    children?: React.ReactNode;
    fullWidth?: boolean;
    marginTop?: number;
    LinkComponent?: React.ReactNode;
    [x: string]: unknown;
}

interface Props {
    children: React.ReactNode;
    fullWidth?: boolean;
    hasError?: boolean;
    [x: string]: unknown;
}

export const TitleWrapper = ({ title, subtitle, children, fullWidth, marginTop, LinkComponent }: TitleWrapperProps) => {
    return (
        <Box
            maxWidth="600px"
            width={{ xs: "100%", sm: fullWidth ? "100%" : "600px" }}
            mt={marginTop !== undefined ? { xs: marginTop } : { xs: 3, sm: 0 }}
            className="titles-container"
        >
            {title && (
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                    <h1>{title}</h1>
                    {LinkComponent}
                </Box>
            )}
            {subtitle && <h2>{subtitle}</h2>}
            {children && <>{children}</>}
        </Box>
    );
};

export const ContentWrapper = ({ children, fullWidth, ...rest }: Props) => {
    return (
        <Box
            mt={2}
            maxWidth="600px"
            width={{ xs: "100%", sm: fullWidth ? "100%" : "600px" }}
            {...rest}
        >
            {children}
        </Box>
    );
};

export const FieldsetWrapper = ({ children, hasError }: Props) => {
    return (
        <FormControl component="fieldset" error={hasError} className="form-controls-container">
            {children}
        </FormControl>
    );
};
