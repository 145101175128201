import { Box } from "@mui/material";
import { CustomerAddressData } from "@marathon/common/entities/Customer";
import { AddressOption } from "./AddressOptions";
import { Hub } from "@marathon/common/entities/Hub";
import { AddressSelectorOriginApp } from "./AddressSelector";
import { SecondaryAddress } from "@marathon/common/entities/SecondaryAddress";

export interface AddressOptionListProps {
    addresses: (SecondaryAddress | CustomerAddressData)[],
    selectedAddress?: SecondaryAddress | CustomerAddressData,
    handleSelectedAddressChange: (address: SecondaryAddress | CustomerAddressData) => void,
    originApp: AddressSelectorOriginApp,
    hubs: Hub[]
}

export const AddressOptionList = ({ addresses, selectedAddress, handleSelectedAddressChange, hubs, originApp }: AddressOptionListProps) => {
    return (
        <Box className="address-selector-row-content multiple">
            {addresses
                .map((address, idx) =>
                    <AddressOption
                        key={idx}
                        isChecked={selectedAddress?.address1 === address.address1}
                        handleSelectedAddressChange={handleSelectedAddressChange}
                        originApp={originApp}
                        address={address}
                        hubs={hubs}
                    />
                )}
        </Box>
    );
};

