import logo from "images/logo.png";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import isMobileViewport from "@marathon/web/hooks/isMobileViewport";
import { CallOrTextButton } from "./shared/CallOrTextButton";
import steps from "@marathon/common/utilities/OnlineBookingSteps";

interface OnlineBookingHeaderProps {
    logoUrl?: string;
    stepNumber?: number;
    substepNumber?: number | null;
    isReturningOnlineBookingFlow?: boolean;
    isLead?: boolean;
    onHide?: () => void;
}

export const OnlineBookingHeader = ({
    logoUrl,
    stepNumber,
    substepNumber,
    isReturningOnlineBookingFlow = false,
    isLead = false,
    onHide
}: OnlineBookingHeaderProps) => {
    const isMobile = isMobileViewport();

    const shouldShowCloseButtonOnReturningFlow = isReturningOnlineBookingFlow && (
        (stepNumber === steps.returning.welcome && substepNumber === steps.returning.welcomeSubsteps.referral) ||
        (stepNumber === steps.returning.welcome && substepNumber === steps.returning.welcomeSubsteps.upcomingAppointmentDetails) ||
        (stepNumber === steps.returning.thankYou && substepNumber === steps.returning.thankYouSubsteps.createPaymentMethod) ||
        stepNumber === steps.returning.manageAccount);

    const shouldShowCloseButtonOnLeadFlow = isLead && (
        (stepNumber === steps.newLead.thankYou && substepNumber === steps.newLead.thankYouSubsteps.createPaymentMethod)
    );

    const showCloseButton =
        shouldShowCloseButtonOnReturningFlow ||
        shouldShowCloseButtonOnLeadFlow;

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pt={isMobile ? "0px" : "14px"}
            pl={isMobile ? "15px" : "30px"}
            pr={isMobile ? "15px" : "40px"}
            width="100%"
            height="80px"
        >
            <Box width={isMobile ? "98px" : "166px"}>
                {
                    logoUrl
                        ?
                        <a rel="noopener noreferrer" href={`${logoUrl}`
                        }>
                            <img src={logo} alt="barkbus" />
                        </a >
                        :
                        <img src={logo} alt="barkbus" />}
            </Box>
            {showCloseButton
                ?
                <IconButton onClick={onHide}>
                    <CloseIcon color="action" />
                </IconButton>
                :
                <CallOrTextButton isLead={isLead} isMobile={isMobile} />}
        </Box>
    );
};