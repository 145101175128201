
import { Customer, CustomerData, CustomerStatus, CustomerType, LeadOrigin, LeadOutcome } from "@marathon/common/entities/Customer";
import DeviceStorageCache from "@marathon/client-side/utilities/DeviceStorageCache";
import { normalizeEmail, normalizePhone } from "@marathon/common/helpers/normalizationHelper";
import { nanoid } from "nanoid/non-secure";
import { CustomerLogRepository } from "./CustomerLogRepository";
import { CustomerLogActivities } from "@marathon/common/entities/CustomerLog";
import CallableFunctions from "@marathon/client-side/utilities/CallableFunctions";
import { CustomerInput } from "../entities/CustomerInput";
import { CustomerOnboardingInput } from "../entities/CustomerOnboardingInput";
import { AppointmentRepository } from "./AppointmentRepository";
import { CustomerNotificationRepository } from "./CustomerNotificationRepository";
import { INJECTED_FIRESTORE_SERVICE_TOKEN } from "./IFirestoreService";
import type { DocResult, IFirestoreService } from "./IFirestoreService";
import { UpdateDataInternal, deleteFieldInternal } from "@marathon/common/utilities/TypeUtils";
import { container, inject, singleton } from "tsyringe";
import { FilterOperator, getFilter, QueryFilter } from "@marathon/common/api/QueryFilter";
import { CollectionPaths } from "@marathon/common/entities/base/CollectionPaths";
import { CustomerMessageRepository } from "./CustomerMessageRepository";
import { User } from "@marathon/common/entities/User";

const mapEntity = function (docs: DocResult<CustomerData>) {
    return new Customer(docs.id, docs.data);
};

const getCustomerType = (outcome?: string) => {
    return outcome === LeadOutcome.closed_won ? CustomerType.customer : CustomerType.lead;
};

const prepareData = (data: Partial<CustomerData>, original?: CustomerData) => {
    const currentUserFullName = DeviceStorageCache.getCurrentUserName() || User.systemUsers.onlineBooking;

    data.phone = data.phone ? normalizePhone(data.phone) : "";
    data.email = data.email ? normalizeEmail(data.email) : "";

    if (original) {
        data.updated_at = new Date();
        data.updated_by = currentUserFullName;

        const isBeingClosed =
            data.lead_info?.outcome === LeadOutcome.closed_won &&
            original.lead_info?.outcome !== LeadOutcome.closed_won;
        if (isBeingClosed) {
            data.closed_by = currentUserFullName;
        }

        const isOutcomeBeingUpdated = original.lead_info?.outcome !== data.lead_info?.outcome;
        if (isOutcomeBeingUpdated) {
            data.outcome_updated_by = currentUserFullName;
        }

        if (data.lead_info) {
            const isBeingResponded = data.lead_info.outcome && data.lead_info.outcome !== original.lead_info?.outcome;
            if (isBeingResponded) {
                data.lead_info.responded_by = currentUserFullName;
            }
        }
    }
    else {
        data.created_at = new Date();
        data.booked_by = currentUserFullName;
        data.new_message = false;
        data.status = CustomerStatus.active;
    }

    if (!original || original.type === CustomerType.lead) {
        data.type = getCustomerType(data.lead_info?.outcome);
    }

    const updateTwilioHub = !original || !data.twilio_hub_id;
    if (updateTwilioHub && data.address?.drive_time)
        data.twilio_hub_id = data.address.drive_time.hub_id;

    if (!!original?.preferred_groomer_id && data.exclude_groomer_ids?.includes(original.preferred_groomer_id))
        data.preferred_groomer_id = deleteFieldInternal;
};

@singleton()
export class CustomerRepository {
    private firestoreService: IFirestoreService<CustomerData>;
    constructor(@inject(INJECTED_FIRESTORE_SERVICE_TOKEN) injectedService: IFirestoreService<CustomerData>) {
        injectedService.collectionPath = CollectionPaths.Customers;
        this.firestoreService = injectedService;
    }
    static get current(): CustomerRepository {
        return container.resolve(CustomerRepository);
    }
    async doesEmailExist(email: string, id?: string) {
        if (!email) return false;
        const filters: QueryFilter<CustomerData>[] = [
            getFilter("email", FilterOperator.equal, normalizeEmail(email)),
            getFilter("status", FilterOperator.equal, CustomerStatus.active)
        ];
        const docs = await this.firestoreService.search({ filters });
        if (id) {
            return docs.some(x => x.id !== id);
        }
        return docs.length > 0;
    }
    async doesPhoneExist(phone: string, id?: string) {
        if (!phone) return false;
        const filters: QueryFilter<CustomerData>[] = [
            getFilter("phone", FilterOperator.equal, normalizePhone(phone)),
            getFilter("status", FilterOperator.equal, CustomerStatus.active)
        ];

        const docs = await this.firestoreService.search({ filters });
        if (id) {
            return docs.some(x => x.id !== id);
        }
        return docs.length > 0;
    }
    async getById(id: string) {
        const doc = await this.firestoreService.getById(id);
        return doc ? mapEntity(doc) : null;
    }
    async searchByIds(ids: string[]) {
        const docs = await this.firestoreService.searchInBatchByIds(ids);
        return docs.map(x => mapEntity(x));
    }
    async getByPhone(phone: string) {
        const filters: QueryFilter<CustomerData>[] = [
            getFilter("phone", FilterOperator.equal, normalizePhone(phone)),
            getFilter("status", FilterOperator.equal, CustomerStatus.active)
        ];
        const docs = await this.firestoreService.search({ filters });
        const doc = docs.at(0);
        return doc ? mapEntity(doc) : null;
    }
    async getByEmail(email: string) {
        const filters: QueryFilter<CustomerData>[] = [
            getFilter("email", FilterOperator.equal, normalizeEmail(email)),
            getFilter("status", FilterOperator.equal, CustomerStatus.active)
        ];
        const docs = await this.firestoreService.search({ filters });
        const doc = docs.at(0);
        return doc ? mapEntity(doc) : null;
    }
    async createFromInput(input: CustomerInput) {
        const data = this.getDataFromInput(input);
        prepareData(data);
        return await this.firestoreService.create(data as CustomerData);
    }
    async updateFromInput(id: string, input: CustomerInput) {
        const data = this.getDataFromInput(input);
        const original = await this.firestoreService.getById(id);
        prepareData(data, original?.data);
        await this.firestoreService.update(id, data);
        return id;
    }
    async createFromOnboardingInput(input: CustomerOnboardingInput) {
        const data = this.getDataFromOnboardingInput(input);
        prepareData(data);
        return await this.firestoreService.create(data as CustomerData);
    }
    async updateFromOnboardingInput(id: string, input: CustomerOnboardingInput) {
        const data = this.getDataFromOnboardingInput(input);
        const original = await this.firestoreService.getById(id);
        prepareData(data, original?.data);
        await this.firestoreService.update(id, data);
        return id;
    }
    private getDataFromInput(input: CustomerInput) {
        const data: Partial<CustomerData> = {
            firstname: input.firstname.trim(),
            lastname: input.lastname.trim(),
            phone: normalizePhone(input.phone),
            email: normalizeEmail(input.email),
            address: input.address,
            notes: input.notes,
            manager_notes: input.manager_notes,
            disallow_chatbot_interaction: input.disallow_chatbot_interaction,
            skip_mobile_service_fee: input.skip_mobile_service_fee,
            reminder_sms_opt_out: input.reminder_sms_opt_out,
            marketing_sms_opt_out: input.marketing_sms_opt_out,
            marketing_email_opt_out: input.marketing_email_opt_out,
            blacklist: input.blacklist,
            postcard_opt_out: input.postcard_opt_out,
            text_ok: input.text_ok,
            lead_info: input.lead_info,
            safety_accepted: input.safety_accepted,
            exclude_groomer_ids: input.exclude_groomer_ids
        };
        return data;
    }
    private getDataFromOnboardingInput(input: CustomerOnboardingInput) {
        const data: Partial<CustomerData> = {
            firstname: input.firstname.trim(),
            lastname: input.lastname.trim(),
            phone: normalizePhone(input.phone),
            email: normalizeEmail(input.email),
            address: input.address,
            notes: input.notes,
            text_ok: input.text_ok,
            lead_info: input.lead_info
        };
        return data;
    }
    async createFromOnlineBooking(getStartedData: InitialLeadData, uid: string) {
        const data = Customer.getDefaultData();

        data.firstname = getStartedData.firstName;
        data.lastname = getStartedData.lastName;
        data.phone = getStartedData.phoneNumber;
        data.email = getStartedData.email;
        data.tos_pp_ok = getStartedData.termsOfServiceOk;
        data.text_ok = getStartedData.textOk;

        data.anonymous_uid = uid;

        if (!data.lead_info)
            throw new Error("Lead info is required at this point");
        data.lead_info.origin = LeadOrigin.onlineBooking;
        data.lead_info.outcome = LeadOutcome.open_address_page;
        data.lead_info.open_conversation = true;

        prepareData(data);
        await this.firestoreService.create(data as CustomerData);
    }
    async updateFromOnlineBooking(id: string, data: UpdateDataInternal<CustomerData>) {
        data.updated_by = User.systemUsers.onlineBooking;
        data.updated_at = new Date();
        await this.firestoreService.update(id, data);
    }
    async updateAnonymousUid(id: string, anonymous_uid: string) {
        await this.firestoreService.update(id, { anonymous_uid });
    }
    async updateVipNotes(id: string, vip_notes: string) {
        await this.firestoreService.update(id, { vip_notes });
    }
    async updateParkingNotes(id: string, parkingNotes: string) {
        await this.firestoreService.update(id, { "address.parking_notes": parkingNotes });
    }
    async updateStandbyExpiration(id: string, until?: Date) {
        await this.firestoreService.update(id, {
            in_standby: true,
            standby_expiration: until ? { until } : deleteFieldInternal,
            updated_by: DeviceStorageCache.getCurrentUserName() ?? undefined
        });
    }
    async updateBlacklistNotes(id: string, blacklist_notes: string) {
        await this.firestoreService.update(id, { blacklist_notes });
    }
    async markLeadAsResponded(id: string) {
        await this.firestoreService.update(id, { "lead_info.new_lead": false });
    }
    async toggleOpenConversation(customer: Customer) {
        if (customer.lead_info?.open_conversation) {
            await this.closeConversation(customer.id);
        }
        else {
            const toUpdate: UpdateDataInternal<CustomerData> = {
                "lead_info.open_conversation": true,
                updated_by: DeviceStorageCache.getCurrentUserName() ?? undefined,
            };
            await this.firestoreService.update(customer.id, toUpdate);
        }
    }
    async toggleStarred(id: string, currentValue: boolean) {
        await this.firestoreService.update(id, { starred: !currentValue });
    }
    async toggleStandby(id: string, currentValue: boolean) {
        const toUpdate: UpdateDataInternal<CustomerData> = {
            in_standby: !currentValue,
            standby_expiration: currentValue ? deleteFieldInternal : undefined,
            updated_by: DeviceStorageCache.getCurrentUserName() ?? undefined
        };
        await this.firestoreService.update(id, toUpdate);
    }
    async toggleVipMark(id: string, currentValue: boolean) {
        await this.firestoreService.update(id, { vip: !currentValue });
    }
    async toggleBlacklist(id: string, currentValue: boolean) {
        await this.firestoreService.update(id, { blacklist: !currentValue });
    }
    async searchAllWithCurrentChatbot() {
        const filters: QueryFilter<CustomerData>[] = [
            getFilter("current_chatbot_activity_id", FilterOperator.notEqual, "")
        ];
        const docs = await this.firestoreService.search({ filters });
        return docs.map(x => mapEntity(x));
    }
    async toggleChatbotEnabled(customer: Customer, initialMessageCount?: number, context?: string) {
        if (customer.current_chatbot_activity_id) {
            await this.checkAndDisableChatbot(customer);
        }
        else {
            await CallableFunctions.current.artificialIntelligence.enableChatbot(
                customer.id,
                initialMessageCount ?? 0,
                DeviceStorageCache.getNonEmptyCurrentUserName(),
                context
            );
        }
    }
    async checkAndDisableChatbot(customer: Customer) {
        await this.removeLiveAgentRequestedMark(customer.id);
        if (customer.current_chatbot_activity_id) {
            await this.firestoreService.update(customer.id, { current_chatbot_activity_id: deleteFieldInternal });
            await CustomerLogRepository.current.create(customer.id, {
                description: CustomerLogActivities.chatbotTurnedOff,
                date: new Date(),
                user: DeviceStorageCache.getNonEmptyCurrentUserName()
            });
        }
    }
    async snoozeConversation(id: string, until?: Date) {
        const dataToUpdate: UpdateDataInternal<CustomerData> = {
            "lead_info.open_conversation": false,
            snoozed_conversation: until ? { until } : deleteFieldInternal,
            updated_by: DeviceStorageCache.getCurrentUserName() ?? undefined
        };
        await this.firestoreService.update(id, dataToUpdate);
    }
    async markNewMessage(id: string, status = false) {
        await this.firestoreService.update(id, { new_message: status });
    }
    async removeLiveAgentRequestedMark(id: string) {
        await this.firestoreService.update(id, { live_agent_requested: deleteFieldInternal });
    }
    async updateTwilioHub(id: string, twilioHubId?: string) {
        const data: UpdateDataInternal<CustomerData> = {
            twilio_hub_id: twilioHubId || deleteFieldInternal
        };
        await this.firestoreService.update(id, data);
    }
    async getInvitationCode(customer: Customer) {
        if (customer.invitation_code) {
            return customer.invitation_code;
        }
        else {
            const code = nanoid(8);
            await this.firestoreService.update(customer.id, { invitation_code: code });
            return code;
        }
    }
    async getByInvitationCode(invitationCode: string) {
        const filters: QueryFilter<CustomerData>[] = [
            getFilter("invitation_code", FilterOperator.equal, invitationCode)
        ];
        const docs = await this.firestoreService.search({ filters });
        const doc = docs.at(0);
        return doc ? mapEntity(doc) : null;
    }
    async cleanOnlineBookingSession(id: string) {
        await this.firestoreService.update(id, { open_online_booking_session: deleteFieldInternal });
    }
    async closeConversation(id: string) {
        const customer = await this.getById(id);
        if (!customer)
            throw new Error(`Customer ${id} not found`);

        const toUpdate: UpdateDataInternal<CustomerData> = {
            "lead_info.open_conversation": false,
            updated_by: DeviceStorageCache.getNonEmptyCurrentUserName(),
            "lead_info.assigned_user_id": deleteFieldInternal,
            "lead_info.assigned_team_id": deleteFieldInternal
        };
        await this.firestoreService.update(id, toUpdate);

        await this.checkAndDisableChatbot(customer);
    }
    async assignUser(id: string, userId: string, optionalNote: string) {
        const toUpdate: UpdateDataInternal<CustomerData> = {
            "lead_info.assigned_user_id": userId,
            "lead_info.open_conversation": true,
            updated_by: DeviceStorageCache.getNonEmptyCurrentUserName()
        };

        if (optionalNote) {
            await CustomerMessageRepository.current.createManagerNote({
                parentId: id,
                content: optionalNote
            });
        }
        await this.firestoreService.update(id, toUpdate);
    }
    async markAsUnassigned(id: string) {
        await this.firestoreService.update(id, {
            "lead_info.assigned_user_id": deleteFieldInternal,
            updated_by: DeviceStorageCache.getNonEmptyCurrentUserName()
        });
    }
    async assignTeam(id: string, teamId: string) {
        await this.firestoreService.update(id, {
            "lead_info.assigned_team_id": teamId,
            "lead_info.open_conversation": true,
            updated_by: DeviceStorageCache.getNonEmptyCurrentUserName()
        });
    }
    async markAsUnassignedTeam(id: string) {
        await this.firestoreService.update(id, {
            "lead_info.assigned_team_id": deleteFieldInternal,
            updated_by: DeviceStorageCache.getNonEmptyCurrentUserName()
        });
    }
    async searchUpcomingAppointmentOrOccurrence(id: string) {
        const futureAppointments = await AppointmentRepository.current.searchFuture(id);

        return (
            futureAppointments.length > 0
                ? futureAppointments.sortByFieldAscending(x => x.start_time)[0]
                : null
        );
    }
    private listenByLastMessages(queries: QueryFilter<CustomerData>[], callback: (data: Customer[]) => void, onFinish?: () => void) {
        const constraints: QueryFilter<CustomerData>[] = [
            getFilter("status", FilterOperator.equal, CustomerStatus.active),
            ...queries
        ];
        return this.firestoreService.onQuerySnapshot({ filters: constraints }, (data) => {
            const customers = data.map(x => mapEntity(x));
            callback(customers.sort((a, b) =>
                (b.last_message?.getTime() || 0) -
                (a.last_message?.getTime() || 0)
            ));
        }, undefined, onFinish);
    }
    listenByLastMessagesOpen(callback: (data: Customer[]) => void, onFinish?: () => void) {
        const constraints: QueryFilter<CustomerData>[] = [
            getFilter("lead_info.open_conversation", FilterOperator.equal, true)
        ];
        return this.listenByLastMessages(constraints, callback, onFinish);
    }
    listenByLastMessagesSnoozed(callback: (data: Customer[]) => void, onFinish?: () => void) {
        const constraints: QueryFilter<CustomerData>[] = [
            getFilter("snoozed_conversation.until", FilterOperator.greaterThan, new Date())
        ];
        return this.listenByLastMessages(constraints, callback, onFinish);
    }
    listenChanges(id: string, callback: (data: Customer) => void) {
        return this.firestoreService.onDocumentSnapshot(id, (snapshot) => {
            if (!snapshot) return;
            callback(mapEntity(snapshot));
        });
    }
    async notifyAppointmentChange(appointmentId: string) {
        const appointment = await AppointmentRepository.current.getById(appointmentId);
        if (!appointment)
            throw new Error(`Appointment ${appointmentId} not found`);

        await CustomerNotificationRepository.current.create(appointment.customer.id, appointmentId);
    }
    async remove(id: string) {
        await this.firestoreService.update(id, { status: CustomerStatus.deleted });
    }
}

export interface InitialLeadData {
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    termsOfServiceOk: boolean,
    textOk: boolean
}