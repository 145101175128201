interface AdsActivityData {
    click_id: string,
    click_type: AdsClickIdType,
    customer_id: string,
    created_at: Date,
    activity_type: ActivityType
}

interface AdsActivity extends AdsActivityData {
    id: string
}

interface AdsClickId {
    value: string,
    type: AdsClickIdType
}

enum AdsClickIdType {
    gclid = "gclid",
    msclkid = "msclkid",
    fbclid = "fbclid",
    gbraid = "gbraid",
    wbraid = "wbraid"
}

enum ActivityType {
    customerConversion = "customer_conversion"
}

class AdsActivity {
    constructor(id: string, data: AdsActivityData) {
        this.id = id;
        Object.assign(this, data);
    }
    toData(): AdsActivityData {
        const { id, ...data } = this;
        return data;
    }
}

export { AdsActivity, AdsClickId, AdsClickIdType, ActivityType };
export type { AdsActivityData };