import { Box, Button, Typography } from "@mui/material";

interface Props {
    isMobile?: boolean;
    isLead?: boolean;
}
export const CallOrTextButton = ({ isMobile, isLead }: Props) => {
    const handleButtonClick = (actionType: string) => {
        if (actionType === "call") {
            window.location.href = "tel:+18007429255";
        } else if (actionType === "text") {
            window.location.href = `sms:+18007429255${isLead ? "&body=Hi! I'd like to learn more about Barkbus mobile grooming" : ""}`;
        }
    };

    return (
        <>
            {isMobile
                ? <Box display="flex" justifyContent="space-between">
                    <StyledButton handleClick={() => handleButtonClick("call")} label={"Call"} />
                    <StyledButton handleClick={() => handleButtonClick("text")} label={"Text"} />
                </Box>
                : <Box>
                    <Typography fontSize="17px" style={{ marginBottom: 10 }}>
                        <b style={{ fontFamily: "Plain bold" }}>Call or Text</b> (800) 742-9255
                    </Typography>
                </Box>
            }
        </>
    );
};

interface StyledButtonProps {
    handleClick: () => void;
    label: string;
}
const StyledButton = ({ handleClick, label }: StyledButtonProps) => {
    return (
        <Button style={{
            color: "#6D6D6D",
            fontSize: "12px",
            fontWeight: "600",
            backgroundColor: "#F2F2F2",
            marginLeft: "8px",
            textTransform: "none",
            width: "58px",
            height: "40px",
            borderRadius: "5px",
        }}
            disableElevation
            onClick={handleClick}
            variant="contained"
            color="inherit">
            {label}
        </Button>
    );
};