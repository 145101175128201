import { useState } from "react";
import { AnimatePresence } from "framer-motion";
import useStyles from "@marathon/web/components/onlineBooking/styles";
import { Button } from "@marathon/web/components/Button";
import { OnlineBookingAppointmentItem } from "./OnlineBookingAppointmentItem";
import { OnlineBookingContentWrapper } from "../OnlineBookingContentWrapper";
import { AppointmentOrOccurrence } from "@marathon/common/entities/Occurrence";
import { Box, Typography } from "@mui/material";
import { TitleWrapper, ContentWrapper } from "../shared/CommonWrappers";
import { Customer } from "@marathon/common/entities/Customer";
import { Service } from "@marathon/common/entities/Service";
import { Pet } from "@marathon/common/entities/Pet";
import { Credit } from "@marathon/common/entities/Credit";

interface OnlineBookingStep3AppointmentsProps {
    customer: Customer,
    pets: Pet[],
    services: Service[],
    customerUpcomingAppointments: AppointmentOrOccurrence[],
    customerPastAppointments: AppointmentOrOccurrence[],
    onNext: (shouldReplace: boolean) => void,
    isBlockedUser: boolean,
    onReferralClick: () => void,
    onManageAccountClick: () => void,
    onUpcomingAppointmentClick: (appointment: AppointmentOrOccurrence) => void,
    credits?: Credit[]
}

const SHOW_REFERRAL_BUTTON = true;
const DEFAULT_VISIBLE_PAST_APPOINTMENTS = 3;
const DEFAULT_VISIBLE_UPCOMING_APPOINTMENTS = 1;
const DEFAULT_VISIBLE_RECURRENT_UPCOMING_APPOINTMENTS = 3;

export default function OnlineBookingStep3Appointments({
    customer,
    pets,
    services,
    customerUpcomingAppointments,
    customerPastAppointments,
    onNext,
    isBlockedUser,
    onReferralClick,
    onManageAccountClick,
    credits,
    onUpcomingAppointmentClick,
}: OnlineBookingStep3AppointmentsProps) {

    const classes = useStyles();
    const [showAllPastAppointments, setShowAllPastAppointments] = useState(customerPastAppointments.length <= DEFAULT_VISIBLE_PAST_APPOINTMENTS);
    const [showAllUpcomingAppointments, setShowAllUpcomingAppointments] = useState(customerUpcomingAppointments.length <= DEFAULT_VISIBLE_UPCOMING_APPOINTMENTS);
    const isRecurrent = customer.isRecurrent();

    const visiblePastAppointments = showAllPastAppointments
        ? customerPastAppointments
        : customerPastAppointments.slice(0, DEFAULT_VISIBLE_PAST_APPOINTMENTS);

    const defaultVisibleUpcomingAppointment = isRecurrent ? DEFAULT_VISIBLE_RECURRENT_UPCOMING_APPOINTMENTS : DEFAULT_VISIBLE_UPCOMING_APPOINTMENTS;
    const visibleUpcomingAppointments = showAllUpcomingAppointments
        ? customerUpcomingAppointments
        : customerUpcomingAppointments.slice(0, defaultVisibleUpcomingAppointment);

    const upcomingAppointmentTitle = isRecurrent ? "Next 3 Upcoming Appointments" : "Upcoming appointments";

    return (
        <OnlineBookingContentWrapper>
            <TitleWrapper
                title={`Hi ${customer.firstname.trim()}!`}
                subtitle={customerUpcomingAppointments.length === 0 ? "You have no upcoming appointments" : undefined}
                LinkComponent={(
                    <Typography onClick={onManageAccountClick} style={{ color: "#000", fontWeight: "bold", fontSize: "17px", textDecoration: "underline", cursor: "pointer" }}>
                        Manage Account
                    </Typography>
                )}
            />
            <ContentWrapper className="returning-customer-home">
                {isBlockedUser &&
                    <div className="MuiFormHelperText-root">
                        <div>
                            There is an issue with your account
                        </div>
                        <div>
                            Please call or text us at <a className="phone-number" href={"tel:+18007429255"}>(800) 742-9255</a>
                        </div>
                    </div>}
                {!isBlockedUser &&
                    <>
                        {credits && credits.length > 0 &&
                            <div className="referral-banner">
                                <div className="banner-title">${Credit.sum(credits)} Referral Credit (Thank you!)</div>
                                <div className="banner-subtitle">Will be applied to your next appointment.
                                </div>
                            </div>}
                        <Button
                            variant="contained"
                            className={classes.button}
                            onClick={() => onNext(false)}
                            disableElevation>
                            Book an Appointment
                        </Button>
                        {SHOW_REFERRAL_BUTTON &&
                            <Box>
                                <Button
                                    variant="contained"
                                    className={classes.button}
                                    onClick={onReferralClick}
                                    disableElevation>
                                    Refer a Friend and Save!
                                </Button>
                            </Box>}
                        {visibleUpcomingAppointments.length > 0 &&
                            <>
                                <div className="titles-container">
                                    <h2>
                                        {upcomingAppointmentTitle}
                                    </h2>
                                </div>
                                <AnimatePresence>
                                    {visibleUpcomingAppointments.map((appointment, idx) => (
                                        <OnlineBookingAppointmentItem
                                            key={idx}
                                            appointment={appointment}
                                            pets={pets}
                                            services={services}
                                            onClick={() => onUpcomingAppointmentClick(appointment)}
                                        />
                                    ))}
                                </AnimatePresence>
                                {!showAllUpcomingAppointments &&
                                    <>
                                        <br />
                                        <span className={classes.link} onClick={() => setShowAllUpcomingAppointments(true)}>Show more</span>
                                    </>}
                            </>}
                        {visiblePastAppointments.length > 0 &&
                            <>
                                <div className="titles-container">
                                    <h2>
                                        Past appointments
                                    </h2>
                                </div>
                                <AnimatePresence>
                                    {visiblePastAppointments.map((appointment, idx) => (
                                        <OnlineBookingAppointmentItem
                                            key={idx}
                                            appointment={appointment}
                                            pets={pets}
                                            services={services}
                                        />
                                    ))}
                                </AnimatePresence>
                                {!showAllPastAppointments &&
                                    <>
                                        <br />
                                        <span className={classes.link} onClick={() => setShowAllPastAppointments(true)}>Show all</span>
                                    </>}
                            </>}
                    </>}
            </ContentWrapper>
        </OnlineBookingContentWrapper>
    );
}