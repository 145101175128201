import { CollectionPaths } from "@marathon/common/entities/base/CollectionPaths";
import { CustomerNotification, CustomerNotificationData, CustomerNotificationStatus } from "@marathon/common/entities/CustomerNotification";
import { INJECTED_FIRESTORE_SERVICE_TOKEN } from "./IFirestoreService";
import type { IFirestoreService } from "./IFirestoreService";
import { container, inject, singleton } from "tsyringe";

@singleton()
export class CustomerNotificationRepository {
    private firestoreService: IFirestoreService<CustomerNotificationData>;
    constructor(@inject(INJECTED_FIRESTORE_SERVICE_TOKEN) injectedService: IFirestoreService<CustomerNotificationData>) {
        injectedService.parentCollectionPath = CollectionPaths.Customers;
        injectedService.collectionPath = CollectionPaths.Notifications;
        this.firestoreService = injectedService;
    }
    static get current() {
        return container.resolve(CustomerNotificationRepository);
    }
    async create(customerId: string, appointmentId: string) {
        const data = {
            appointment_id: appointmentId,
            status: CustomerNotificationStatus.unsent
        };
        const docId = await this.firestoreService.create(data, customerId);
        return new CustomerNotification(docId, customerId, data);
    }
}