interface CustomerNotificationData {
    appointment_id: string,
    status: CustomerNotificationStatus
}

interface CustomerNotification extends CustomerNotificationData {
    id: string,
    customerId: string
}

class CustomerNotification {
    constructor(id: string, customerId: string, data: CustomerNotificationData) {
        this.id = id;
        this.customerId = customerId;
        Object.assign(this, data);
    }
    toData(): CustomerNotificationData {
        const { id, customerId, ...data } = this;
        return data;
    }
}

enum CustomerNotificationStatus {
    unsent = "unsent",
    sent = "sent"
}

export { CustomerNotification, CustomerNotificationStatus };
export type { CustomerNotificationData };