interface AppointmentPetInputData {
    petId: string | null
    serviceId: string | null
    servicePrice: number
    customServiceTime?: number
}

interface AppointmentPetInput extends AppointmentPetInputData { }

class AppointmentPetInput {
    constructor(input?: AppointmentPetInputData) {
        if (input) {
            Object.assign(this, input);
        }
        else {
            this.petId = null;
            this.serviceId = null;
            this.servicePrice = 0;
        }
    }
    isValid() {
        return (
            this.petId &&
            this.serviceId &&
            this.servicePrice >= 0
        );
    }
    static isArray(object: unknown): object is AppointmentPetInput[] {
        if (!object || !Array.isArray(object))
            return false;

        return object.every(x => x instanceof AppointmentPetInput);
    }
}

export { AppointmentPetInput };