export function normalizePhone(phone: string) {
    let normalized = phone.replace("+1", "").replace("+549", "").replace("+54", "").replace(/\D/g, "");
    if (normalized.length === 11)
        normalized = normalized.substring(1);
    return normalized;
}

export function normalizeEmail(email: string) {
    return email.trim().toLowerCase();
}

export function toPascalCase(s: string) {
    if (!s) return "";
    return s.replace(/\w\S*/g, function (t) { return t.charAt(0).toUpperCase() + t.substring(1).toLowerCase(); });
}