import { Loader } from "@googlemaps/js-api-loader";

const loader = new Loader({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? "",
    libraries: ["places", "geometry"],
    id: "__googleMapsScriptId"
});

const loadGoogleMapsApi = async (): Promise<void> => {
    if (!process.env.REACT_APP_GOOGLE_MAPS_API_KEY) {
        throw new Error("Invalid Google Maps API key");
    }

    if (window.google) {
        return;
    }

    await loader.load();
};

export default loadGoogleMapsApi;