import { Hub } from "@marathon/common/entities/Hub";
import { DateTime } from "luxon";
import { getOrdinalSuffix } from "@marathon/common/helpers/timeFormatHelper";
import { ZERO_BASED_DAYS_OF_WEEK } from "@marathon/common/entities/Groomer";

export const getDateText = (startTime: Date, customerHub: Hub) => {
    const fromDateTime = DateTime.fromJSDate(startTime, { zone: customerHub.time_zone });
    const weekday = fromDateTime.weekdayLong || ZERO_BASED_DAYS_OF_WEEK[fromDateTime.weekday];
    return `${weekday}, ${fromDateTime.toFormat("MMM dd")}${getOrdinalSuffix(fromDateTime.day)}`;
};

export const getTimeText = (startTime: Date, endTime: Date, customerHub: Hub) => {
    const fromDateTime = DateTime.fromJSDate(startTime, { zone: customerHub.time_zone });
    const toDateTime = DateTime.fromJSDate(endTime, { zone: customerHub.time_zone });
    return `${fromDateTime.toFormat("h:mm a")}-${toDateTime.toFormat("h:mm a")}`;
};

export const getFrecuencyText = (interval: number) => {
    return `Every ${interval} ${interval === 1 ? "week" : "weeks"}`;
};