enum MixPanelEvent {
    ob_onlineBookingStart = "OB - Online Booking Start",
    ob_new_getStarted = "OB - New - Get Started",
    ob_new_phoneConfirm = "OB - New - Phone Confirm",
    ob_new_accountExist = "OB - New - Account Exist",
    ob_new_location = "OB - New - Location",
    ob_new_dogInfo = "OB - New - Dog Info",
    ob_new_exceedServiceArea = "OB - New - Exceed Service Area",
    ob_new_exceedServiceArea_waitList = "OB - New - Exceed Service Area Wait List",
    ob_new_prePricing = "OB - New - Pre Pricing",
    ob_new_pricing = "OB - New - Pricing",
    ob_new_dates = "OB - New - Dates",
    ob_new_confirm = "OB - New - Confirm",
    ob_new_safety = "OB - New - Safety",
    ob_new_finalStep = "OB - New - Final Step",
    ob_new_finalStep_addToCal = "OB - New - Final Step - Add to Cal",
    ob_new_finalStep_blogClick = "OB - New - Final Step - Blog Click",
    ob_returningCustomer = "OB - Returning Customer",
    ob_return_phoneConfirm = "OB - Return - Phone Confirm",
    ob_return_welcomeBack = "OB - Return - Welcome Back",
    ob_return_pricing = "OB - Return - Pricing",
    ob_return_pricing_addDog = "OB - Return - Pricing - Add Dog",
    ob_return_dates = "OB - Return - Dates",
    ob_return_confirm = "OB - Return - Confirm",
    ob_return_safety = "OB - Return - Safety",
    ob_return_finalStep = "OB - Return - Final Step",
    ob_return_finalStep_addToCal = "OB - Return - Final Step - Add to Cal",
    ob_return_finalStep_blogClick = "OB - Return - Final Step - Blog Click",
    ob_return_klaviyoBookingSuggestion_confirm = "OB - Return - Booking Suggestion - Confirm"

}

export { MixPanelEvent };