import { initializeApp } from "firebase/app";
import { initializeAnalytics } from "firebase/analytics";
import { browserLocalPersistence, getAuth, initializeAuth } from "firebase/auth";
import { FirestoreSettings, initializeFirestore, persistentLocalCache, persistentMultipleTabManager } from "firebase/firestore";

const firebaseConfiguration = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const firebaseApp = initializeApp(firebaseConfiguration);
initializeAnalytics(firebaseApp);
initializeAuth(firebaseApp, { persistence: browserLocalPersistence });

const firestoreSettings = { ignoreUndefinedProperties: true } as FirestoreSettings;
if (process.env.REACT_APP_OFFLINE_MODE_ENABLED === "true")
    firestoreSettings.localCache = persistentLocalCache({ tabManager: persistentMultipleTabManager() });
initializeFirestore(firebaseApp, firestoreSettings);

export const authentication = getAuth(firebaseApp);

export default firebaseApp;