import { ReferralActivity, ReferralActivityData, ReferralEventType, ReferralOrigin } from "@marathon/common/entities/ReferralActivity";
import LocalDate from "@marathon/common/utilities/LocalDate";
import { Customer } from "@marathon/common/entities/Customer";
import { DocResult, getLastDocument, INJECTED_FIRESTORE_SERVICE_TOKEN } from "./IFirestoreService";
import type { DocSnapshot, IFirestoreService } from "./IFirestoreService";
import { container, inject, singleton } from "tsyringe";
import { FilterOperator, getFilter, getOrderBy, OrderDirection } from "@marathon/common/api/QueryFilter";
import { CollectionPaths } from "@marathon/common/entities/base/CollectionPaths";

const mapEntity = function (snapshot: DocResult<ReferralActivityData>) {
    return new ReferralActivity(snapshot.id, snapshot.data);
};

@singleton()
export class ReferralActivityRepository {
    private firestoreService: IFirestoreService<ReferralActivityData>;
    constructor(@inject(INJECTED_FIRESTORE_SERVICE_TOKEN) injectableService: IFirestoreService<ReferralActivityData>) {
        injectableService.collectionPath = CollectionPaths.ReferralActivities;
        this.firestoreService = injectableService;
    }
    static get current() {
        return container.resolve(ReferralActivityRepository);
    }
    async searchForReport(fromDate: LocalDate, toDate: LocalDate, pageSize?: number, afterDocument?: DocSnapshot) {
        const docs = await this.firestoreService.search({
            filters: [
                getFilter("created_at", FilterOperator.greaterThanOrEqual, fromDate.toDayStart()),
                getFilter("created_at", FilterOperator.lessThanOrEqual, toDate.toDayEnd())
            ],
            orders: [
                getOrderBy("created_at", OrderDirection.desc)
            ],
            afterDocument,
            limit: pageSize
        });
        return {
            activities: docs.map(x => mapEntity(x)),
            lastDocument: getLastDocument(docs)
        };
    }
    async create(customer: Customer, type: ReferralEventType, sharedUrl: string, origin: ReferralOrigin) {
        const data: ReferralActivityData = {
            customer_id: customer.id,
            customer_name: customer.fullname(),
            created_at: new Date(),
            type,
            sharedUrl,
            origin
        };
        const docId = await this.firestoreService.create(data);
        return mapEntity({ id: docId, data });
    }
}