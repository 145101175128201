import { Breed, BreedData } from "@marathon/common/entities/Breed";
import { DocResult, INJECTED_FIRESTORE_SERVICE_TOKEN } from "./IFirestoreService";
import type { IFirestoreService } from "./IFirestoreService";
import { container, inject, singleton } from "tsyringe";
import { getOrderBy, OrderDirection } from "@marathon/common/api/QueryFilter";
import { CollectionPaths } from "@marathon/common/entities/base/CollectionPaths";

const mapEntity = function (snapshot: DocResult<BreedData>) {
    return new Breed(snapshot.id, snapshot.data);
};

@singleton()
export class BreedRepository {
    private firestoreService: IFirestoreService<BreedData>;
    constructor(@inject(INJECTED_FIRESTORE_SERVICE_TOKEN) injectedService: IFirestoreService<BreedData>) {
        injectedService.collectionPath = CollectionPaths.Breeds;
        this.firestoreService = injectedService;
    }
    static get current() {
        return container.resolve(BreedRepository);
    }
    async getById(id: string) {
        const doc = await this.firestoreService.getById(id);
        return doc ? mapEntity(doc) : null;
    }
    async search() {
        const docs = await this.firestoreService.search({
            orders: [
                getOrderBy("name", OrderDirection.asc)
            ]
        });
        return docs.map(x => mapEntity(x));
    }
}
