import { HubGroup } from "@marathon/common/entities/Hub";
import { DayOfWeek, ZERO_BASED_DAYS_OF_WEEK } from "@marathon/common/entities/Groomer";

interface UserScheduleData {
    day: DayOfWeek,
    active: boolean
}

interface UserData {
    appointments: number,
    firstname: string,
    lastname: string,
    email: string,
    photo: string,
    role: string,
    status: UserStatus,
    uid: string,
    created_at: Date,
    updated_at?: Date,
    is_assign_eligible?: boolean,
    allow_chatbot_control?: boolean,
    allow_broadcast_control?: {
        max_customers_limit: number,
        is_enabled: boolean
    },
    allow_auto_sms_control?: true,
    is_from_third_party_team?: true,
    calendar_revenue_filter?: number,
    preferred_hub_group?: HubGroup,
    start_date?: Date,
    end_date?: Date,
    schedule: UserScheduleData[],
    device_token?: string,
    notifications_enabled?: boolean,
    notification_subscriptions?: string[],
    goal: {
        value: number,
        distinction: string
    }
    is_super_admin?: true,
    allow_remove_groomer?: true,
}

interface User extends UserData {
    id: string
}

class User {
    constructor(id: string, data: UserData) {
        this.id = id;
        Object.assign(this, data);
    }
    fullname(): string {
        return this.firstname + " " + this.lastname;
    }
    toData(): UserData {
        const { id, ...data } = this;
        return data;
    }
    static isDayOff(schedule: UserScheduleData[], date: Date) {
        if (!schedule)
            return false;
        const workingDays = schedule.filter(d => d.active).map(x => x.day);
        return ZERO_BASED_DAYS_OF_WEEK
            .filter(weekDay => !workingDays.includes(weekDay))
            .some(weekDay => ZERO_BASED_DAYS_OF_WEEK.findIndex(x => x === weekDay) === date.getDay());
    }
    static defaultSalesGoal() {
        return {
            value: 0,
            distinction: "Z"
        };
    }
    static getDefault() {
        return new User("", {
            appointments: 0,
            firstname: "",
            lastname: "",
            email: "",
            photo: "",
            role: "",
            uid: "",
            created_at: new Date(),
            status: UserStatus.active,
            is_assign_eligible: false,
            allow_chatbot_control: false,
            preferred_hub_group: undefined,
            schedule: [
                { day: "Sunday", active: false },
                { day: "Monday", active: true },
                { day: "Tuesday", active: true },
                { day: "Wednesday", active: true },
                { day: "Thursday", active: true },
                { day: "Friday", active: true },
                { day: "Saturday", active: false }
            ],
            goal: User.defaultSalesGoal()
        });
    }
    static get systemUsers() {
        return {
            marathon: "Marathon",
            marathonAi: "Marathon AI",
            marathonChatbotAi: "Marathon Chatbot AI",
            marathonVoiceAi: "Marathon Voice AI",
            onlineBooking: "Online Booking",
            customer: "Customer",
            aircall: "Aircall"
        };
    }
}

enum UserStatus {
    active = "active",
    inactive = "inactive",
    deleted = "deleted"
}

enum UserRole {
    admin = "Admin",
    groomer = "Groomer"
}

export { User, UserStatus, UserRole };
export type { UserData, UserScheduleData };