import { useEffect, useState } from "react";

interface Props {
    targetDate: Date,
    onExpire: () => void
}

export default function TimerCountDown({ targetDate, onExpire }: Props) {
    const [timeLeft, setTimeLeft] = useState<string | null>(null);
    const [countDownDate] = useState(new Date(targetDate).getTime());

    useEffect(() => {
        const x = setInterval(function () {
            const now = new Date().getTime();
            const distance = countDownDate - now;
            const dateParts = [];
            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            if (days > 0) dateParts.push(days + "d");
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            if (days > 0 || hours > 0) dateParts.push(hours + "h");
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            if (days > 0 || hours > 0 || minutes >= 0) dateParts.push(minutes + "m");
            const timeLeftResult = dateParts.filter(v => v).join(" ");
            setTimeLeft(timeLeftResult);
            if (distance < 0) {
                clearInterval(x);
                onExpire();
            }
        }, 1000);
        return () => clearInterval(x);
    }, [countDownDate, onExpire]);

    return (
        <span>{timeLeft || "..."}</span>
    );
}

interface iCountDown {
    startAt: number,
    onExpire: () => void,
    every?: number,
}
export function CountDown({ every = 1000, startAt, onExpire }: iCountDown) {
    const [count, setCount] = useState<number>(startAt);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        const discount = (count: number) => {
            if (count === 0) {
                onExpire();
                return;
            }
            timeoutId = setTimeout(() => {
                setCount(count);
            }, every);
        };
        discount(count - 1);
        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count]);

    return (
        <span>{count || "..."}</span>
    );
}