import { BookingSuggestion, getSuggestionArrivalTime } from "@marathon/common/api/BookingSuggestion";
import { formatDateForOnlineBooking } from "@marathon/common/helpers/timeFormatHelper";
import { Box } from "@mui/material";

interface AppointmentInfoHeaderProps {
    suggestion: BookingSuggestion,
    isFromOnlineBooking: boolean,
    variant?: "primary" | "secondary",
}

export const AppointmentInfoHeader = ({ suggestion, isFromOnlineBooking, variant = "primary" }: AppointmentInfoHeaderProps) => {
    return (
        <div className="appointment-suggestion-header">
            <DateSection suggestion={suggestion} variant={variant} />
            <TimeContextSection suggestion={suggestion} variant={variant} showEmptyDay={!isFromOnlineBooking} />
            <StylistSection
                stylistName={isFromOnlineBooking ? suggestion.groomer.obfuscatedName : suggestion.groomer.fullname}
                variant={variant}
            />
        </div>
    );
};

interface DateSectionProps {
    suggestion: BookingSuggestion,
    variant?: "primary" | "secondary",
    style?: React.CSSProperties
}

export const DateSection = ({ suggestion, variant = "primary", style }: DateSectionProps) => {
    return (
        <span className={variant === "primary" ? "suggestion-date" : "suggestion-date-secondary"} style={style}>
            {formatDateForOnlineBooking(suggestion.arrivalTime, suggestion.timeZone)}
        </span>
    );
};

interface TimeSectionProps {
    suggestion: BookingSuggestion,
    variant?: "primary" | "secondary",
    style?: React.CSSProperties
}

export const TimeSection = ({ suggestion, variant = "primary", style }: TimeSectionProps) => {
    return (
        <div className={variant === "primary" ? "suggestion-time" : "suggestion-time-secondary"} style={style}>
            {getSuggestionArrivalTime(suggestion)}
        </div>
    );
};

interface ContextSectionProps {
    suggestion: BookingSuggestion,
    variant?: "primary" | "secondary",
    showEmptyDay?: boolean,
    style?: React.CSSProperties
}

export const ContextSection = ({ suggestion, variant = "primary", showEmptyDay, style }: ContextSectionProps) => {
    return (
        <>
            <div className={variant === "primary" ? "suggestion-context" : "suggestion-context-secondary"} style={style}>
                {suggestion.showExactTime ? "Arrival Time" : "Arrival Window"}
            </div>
            {showEmptyDay &&
                <>
                    <br />
                    <strong style={{ marginLeft: 10 }}>(Empty Day)</strong>
                </>}
        </>
    );
};

interface TimeContextSectionProps {
    suggestion: BookingSuggestion,
    variant?: "primary" | "secondary",
    showEmptyDay?: boolean,
    column?: boolean,
}

export const TimeContextSection = ({ suggestion, variant = "primary", column = false, showEmptyDay }: TimeContextSectionProps) => {
    return (
        <Box display="flex" flexDirection={column ? "column" : "row"}>
            <TimeSection suggestion={suggestion} variant={variant} />
            {!column && <>&nbsp;</>}
            <ContextSection
                suggestion={suggestion}
                variant={variant}
                showEmptyDay={suggestion.isForEmptyDay && showEmptyDay}
            />
        </Box>

    );
};

interface StylistSectionProps {
    stylistName: string,
    variant?: "primary" | "secondary",
    style?: React.CSSProperties
}

export const StylistSection = ({ stylistName, variant, style }: StylistSectionProps) => {
    return (
        <span className={variant === "primary" ? "suggestion-stylist" : "suggestion-stylist-secondary"} style={style}>
            Stylist: {stylistName}
        </span>
    );
};