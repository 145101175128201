import { useState, useEffect } from "react";
import * as React from "react";
import { motion } from "framer-motion";
import { Box } from "@mui/material";
import { formatDateForOnlineBooking } from "@marathon/common/helpers/timeFormatHelper";
import { AppointmentOrOccurrence } from "@marathon/common/entities/Occurrence";
import { GroomerRepository } from "@marathon/client-side/repositories/GroomerRepository";
import isMobileViewport from "@marathon/web/hooks/isMobileViewport";
import { Pet } from "@marathon/common/entities/Pet";
import { Service } from "@marathon/common/entities/Service";
import { Appointment } from "@marathon/common/entities/Appointment";
import { Groomer } from "@marathon/common/entities/Groomer";

interface OnlineBookingAppointmentItemProps {
    appointment: AppointmentOrOccurrence,
    pets: Pet[],
    services: Service[],
    onClick?: () => void
}

interface ContentWrapperProps {
    children: React.ReactNode,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [x: string]: any;
}

export const OnlineBookingAppointmentItem = ({ appointment, pets, services, onClick }: OnlineBookingAppointmentItemProps) => {
    const [groomer, setGroomer] = useState<Groomer | null>(null);

    useEffect(() => {
        let isMounted = true;
        const fetchGroomer = async () => {
            const temp = await GroomerRepository.current.getById(appointment.groomer.id);
            if (isMounted) {
                setGroomer(temp);
            }
        };
        fetchGroomer();
        return () => {
            isMounted = false;
        };
    }, [appointment.groomer.id]);

    if (!groomer)
        return null;

    const petsToShow = pets.filter(x => appointment.selected_pets.map(y => y.petId).includes(x.id));
    const servicesToShow = services.filter(x => appointment.selected_pets.some(y => y.serviceId === x.id));

    return (
        <ContentWrapper className="appointment-suggestion">
            <Box display="flex" flexDirection="column" className={`${onClick ? "upcoming-appointment-item" : ""}`} onClick={onClick}>
                <Box display="flex" justifyContent="space-between">
                    <Box style={{ width: "100%" }} className="appointment-suggestion-header">
                        {appointment.frequency && !appointment.recurrence_id &&
                            <div style={{ float: "right", fontWeight: "bold" }}>
                                Recurring
                                <br />
                                {appointment.frequency.interval} {appointment.frequency.interval === 1 ? "week" : "weeks"}
                            </div>}
                        <span className="suggestion-date">
                            {formatDateForOnlineBooking(appointment.start_time, appointment.time_zone)}
                        </span>
                        <br />
                        <span className="suggestion-date">
                            {Appointment.getArrivalTime(appointment)}
                        </span>
                        <br />
                        <div style={{ marginTop: 10, fontSize: "15px", lineHeight: "1.3rem" }}>
                            <span className="suggestion-context">
                                Stylist: {groomer.obfuscatedName()}
                            </span>
                            <br />
                            <span className="suggestion-context">
                                {`Pet${petsToShow.length > 1 ? "s" : ""}: ${petsToShow.map(x => x.name).join(", ")}`}
                            </span>
                            {servicesToShow.length > 0 &&
                                <>
                                    <br />
                                    <span className="suggestion-context">
                                        {servicesToShow.map(x => x.customers_description || x.description).join(", ")}
                                    </span>
                                </>}
                        </div>
                    </Box>
                    <Box>
                        {appointment.pet_image_url && <img className="appointment-image" src={appointment.pet_image_url} alt="Groomed pet" height="125px" />}
                    </Box>
                </Box>
            </Box>
        </ContentWrapper>
    );
};

const ContentWrapper = ({ children, ...rest }: ContentWrapperProps) => {
    const isMobile = isMobileViewport();
    if (isMobile) {
        return (
            <div {...rest}>
                {children}
            </div>
        );
    }
    else {
        return (
            <motion.div
                {...rest}
                initial={{ y: -100, opacity: 0 }}
                exit={{ y: -100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                {children}
            </motion.div>
        );
    }
};