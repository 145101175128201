import { Service, ServiceStatus } from "@marathon/common/entities/Service";
import { Pet, PetService } from "@marathon/common/entities/Pet";
import { Breed } from "@marathon/common/entities/Breed";

interface Props {
    breeds: Breed[],
    checked?: boolean,
    children: (params: ChildrenParams) => JSX.Element,
    onAddNewAddOnService?: (petId: string, serviceId: string) => void,
    onChangeAddOnService?: (petId: string, oldServiceId: string, newServiceId: string) => void,
    onRemoveAddOnService?: (petId: string, serviceId: string) => void,
    pet: Pet,
    selectedAddOnServiceIds?: string[],
    selectedServiceId?: string,
    services: Service[],
    showCheckbox?: boolean
}

interface ChildrenParams {
    addOnServices: Service[],
    availableAddOnServices: Service[],
    breed: Breed,
    canAddMoreServices: boolean,
    formatAddOnServiceLabel: (serviceId: string) => string,
    getDescription: () => string,
    handleAddNewService: () => void,
    handleChangeSelectedAddOnService: (oldServiceId: string, newServiceId: string) => void,
    handleRemoveSelectedAddOnService: (index: number) => void,
    selectedPetService?: PetService,
    selectedService?: Service,
    isUnchecked: boolean
}

const PetServiceSelectionWrapper = ({
    breeds,
    checked,
    children,
    onAddNewAddOnService,
    onChangeAddOnService,
    onRemoveAddOnService,
    pet,
    selectedAddOnServiceIds = [],
    selectedServiceId,
    services,
    showCheckbox = true
}: Props) => {
    const selectedService = services.find(s => s.id === selectedServiceId);
    const selectedPetService = pet.services.find(s => s.id === selectedServiceId);
    const addOnServices = services.filter(x => x.status !== ServiceStatus.hidden && x.is_addon);
    const canAddMoreServices = selectedAddOnServiceIds.length < addOnServices.length;
    const availableAddOnServices = addOnServices.filter(x => !selectedAddOnServiceIds.includes(x.id));

    const getDescription = () => {
        if (pet.services.length === 1)
            return "The Signature Service includes all of the following: a warm bath, your choice of all-natural shampoos & conditioners, a gentle brush out & blow-dry, teeth brushing, ear cleaning & nail trimming & filing. Anal gland expression is available upon request. This service does not include any hair cutting.";

        if (selectedService?.getAcronym() === "CR")
            return `Curly coated dogs like ${pet.name} have multiple options to choose from. Whether you need a full-body haircut, in-between clean-up or just a warm bath and brush out, we tailor each experience to your pup! All services include our one-on-one Signature Service which comes with a warm bath, your choice of all-natural shampoos & conditioners, a gentle brush out & blow-dry, teeth brushing, ear cleaning & nail trimming & filing. Anal gland expression is available upon request. See specific details for each service below!`;

        return `Dogs like ${pet.name} have multiple service options to choose from. All services include our one-on-one Signature Service which comes with a warm bath, your choice of all-natural shampoos & conditioners, a gentle brush out & blow-dry, teeth brushing, ear cleaning & nail trimming & filing. Anal gland expression is available upon request.`;
    };

    const breed = breeds.find(x => x.id === pet.breed_id);
    if (!breed)
        throw new Error(`Missing breed for dog ${pet.id}`);

    const isUnchecked = showCheckbox && !checked;

    const handleAddNewService = () => {
        const defaultService = availableAddOnServices[0];
        if (defaultService && onAddNewAddOnService) {
            onAddNewAddOnService(pet.id, defaultService.id);
        }
    };

    const handleChangeSelectedAddOnService = (oldServiceId: string, newServiceId: string) => {
        if (onChangeAddOnService) {
            onChangeAddOnService(pet.id, oldServiceId, newServiceId);
        }
    };

    const handleRemoveSelectedAddOnService = (index: number) => {
        if (onRemoveAddOnService) {
            const serviceToRemoveId = selectedAddOnServiceIds[index];
            onRemoveAddOnService(pet.id, serviceToRemoveId);
        }
    };

    const formatAddOnServiceLabel = (serviceId: string) => {
        const service = addOnServices.find(x => x.id === serviceId);
        if (!serviceId) {
            return "Service";
        }
        else if (!service) {
            return "Service not found";
        }
        else {
            return service.formatOptionLabel(true);
        }
    };

    const params: ChildrenParams = {
        addOnServices,
        availableAddOnServices,
        breed,
        canAddMoreServices,
        formatAddOnServiceLabel,
        getDescription,
        handleAddNewService,
        handleChangeSelectedAddOnService,
        handleRemoveSelectedAddOnService,
        isUnchecked,
        selectedPetService,
        selectedService,
    };

    return children(params);
};

export default PetServiceSelectionWrapper;