import { authentication } from "@marathon/web/firebaseApp";
import * as Sentry from "@sentry/react";
import { BreedRepository } from "@marathon/client-side/repositories/BreedRepository";
import { DiscountRepository } from "@marathon/client-side/repositories/DiscountRepository";
import { Hub } from "@marathon/common/entities/Hub";
import { ServiceRepository } from "@marathon/client-side/repositories/ServiceRepository";
import { RecaptchaVerifier, signInWithPhoneNumber, AuthErrorCodes, sendSignInLinkToEmail, ActionCodeSettings, signInAnonymously } from "firebase/auth";
import { normalizePhone } from "@marathon/common/helpers/normalizationHelper";
import { useEffect, useState } from "react";
import { OnlineBookingContext, Props } from "./OnlineBookingContext";
import { Loading } from "@marathon/web/components/Loading";
import { HubRepository } from "@marathon/client-side/repositories/HubRepository";
import { ConfigurationRepository } from "@marathon/client-side/repositories/ConfigurationRepository";
import { GroomerRepository } from "@marathon/client-side/repositories/GroomerRepository";
import { PricingPresetRepository } from "@marathon/client-side/repositories/PricingPresetRepository";
import { MobileServiceFeeConfiguration } from "@marathon/common/entities/Configuration";
import { logErrorAndShowTitleOnlyAlert } from "@marathon/web/helpers/errorHandlingHelper";
import { AdsClickId } from "@marathon/common/entities/AdsActivity";
import { PricingPreset } from "@marathon/common/entities/PricingPreset";
import { Service } from "@marathon/common/entities/Service";
import { Breed } from "@marathon/common/entities/Breed";
import { Discount } from "@marathon/common/entities/Discount";
import { Groomer } from "@marathon/common/entities/Groomer";

export const OnlineBookingProvider = (props: { children: React.ReactNode }) => {
    const [hubs, setHubs] = useState<Hub[]>([]);
    const [breeds, setBreeds] = useState<Breed[]>([]);
    const [services, setServices] = useState<Service[]>([]);
    const [pricingPresets, setPricingPresets] = useState<PricingPreset[]>([]);
    const [groomers, setGroomers] = useState<Groomer[]>([]);
    const [discounts, setDiscounts] = useState<Discount[]>();
    const [loadingContextData, setLoadingContextData] = useState(false);
    const [errorLoadingContextData, setErrorLoadingContextData] = useState(false);
    const [isAppointmentBooked, setIsAppointmentBooked] = useState(false);
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [loadingAuth, setLoadingAuth] = useState(true);
    const [isFromSms, setIsFromSms] = useState(false);
    const [mobileServiceFee, setMobileServiceFee] = useState<MobileServiceFeeConfiguration | null>(null);
    const [clickId, setClickId] = useState<AdsClickId | undefined>(undefined);

    const setUpRecaptcha = (recaptcha_id: string) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const recaptchaVerifier = (window as any).recaptchaVerifier as RecaptchaVerifier;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (recaptchaVerifier && (window as any).recaptchaId === recaptcha_id) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).recaptchaVerifier = new RecaptchaVerifier(authentication, recaptcha_id, {
            "size": "invisible",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            "callback": (response: any) => {
                console.log(response);
            }
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).recaptchaId = recaptcha_id;
    };

    const onSignInAnonymously = async () => {
        return await signInAnonymously(authentication);
    };

    const onSignIn = async ({ phoneNumber, recaptcha_id = "recaptcha-container" }: { phoneNumber: string, recaptcha_id?: string }) => {
        if (!process.env.REACT_APP_FIREBASE_AUTH_COUNTRY_CODE) {
            throw new Error("Invalid Firebase Phone Authentication country code");
        }
        try {
            setUpRecaptcha(recaptcha_id);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const appVerifier = (window as any).recaptchaVerifier;
            const firebaseAuthPhone = `${process.env.REACT_APP_FIREBASE_AUTH_COUNTRY_CODE}${normalizePhone(phoneNumber)}`;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any).confirmationResult = await signInWithPhoneNumber(authentication, firebaseAuthPhone, appVerifier);
        } catch (error) {
            logErrorAndShowTitleOnlyAlert(error, getErrorMessage(error));
            throw error;
        }
    };

    const onSendSignInLinkToEmail = async (email: string, url: string) => {
        try {
            const actionCodeSettings = {
                url,
                handleCodeInApp: true,
            } as ActionCodeSettings;
            await sendSignInLinkToEmail(authentication, email, actionCodeSettings);
        } catch (error) {
            logErrorAndShowTitleOnlyAlert(error, getErrorMessage(error));
            throw error;
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getErrorMessage = (error: any) => {
        switch (error.code) {
            case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
                return "Your account has been blocked for security reasons, please try again in 5 minutes";
            case AuthErrorCodes.INVALID_PHONE_NUMBER:
                return "This is an invalid phone number, please call or text us at (800) 742-9255";
            case AuthErrorCodes.NETWORK_REQUEST_FAILED:
            case AuthErrorCodes.TIMEOUT:
                return "A network error has occurred, please try again";
            default:
                return "Whoops! An error has occurred. If this keeps happening, please call or text us at (800) 742-9255";
        }
    };

    const toExport = {
        hubs,
        breeds,
        services,
        pricingPresets,
        groomers,
        discounts,
        mobileServiceFee,
        loadingContextData,
        errorLoadingContextData,
        isAppointmentBooked,
        setIsAppointmentBooked,
        onSignIn,
        onSignInAnonymously,
        isSignedIn,
        isFromSms,
        setIsFromSms,
        onSendSignInLinkToEmail,
        clickId,
        setClickId,
    };

    useEffect(() => {
        async function loadContextData() {
            try {
                setLoadingContextData(true);
                const [_hubs, _services, _pricingPresets, _breeds, _groomers, _discounts, _mobileServiceFee] = await Promise.all([
                    HubRepository.current.search(),
                    ServiceRepository.current.search(),
                    PricingPresetRepository.current.search(),
                    BreedRepository.current.search(),
                    GroomerRepository.current.searchAvailableOnline(),
                    DiscountRepository.current.search(),
                    ConfigurationRepository.current.getMobileServiceFee()
                ]);
                setHubs(_hubs);
                setServices(_services);
                setPricingPresets(_pricingPresets);
                setBreeds(_breeds);
                setGroomers(_groomers);
                setDiscounts(_discounts);
                setMobileServiceFee(_mobileServiceFee);
                setLoadingContextData(false);
            } catch (error) {
                Sentry.captureException(error);
                setErrorLoadingContextData(true);
            }
            finally {
                setLoadingContextData(false);
            }
        }

        loadContextData();

        authentication.onAuthStateChanged(user => {
            if (user) {
                setIsSignedIn(true);
                Sentry.setUser({ id: user.uid, username: user.phoneNumber ?? user.email ?? "" });
            }
            else {
                setIsSignedIn(false);
                Sentry.configureScope(scope => scope.setUser(null));
            }
            setLoadingAuth(false);
        });
    }, []);

    return (
        <OnlineBookingContext.Provider value={{ ...toExport } as Props}>
            <div className="online-booking-wizard-container">
                {loadingAuth || loadingContextData ? <Loading /> : props.children}
            </div>
        </OnlineBookingContext.Provider>
    );
};