const steps = {
    newLead: {
        getStarted: 2,
        getStartedSubsteps: {
            foundExisting: 1
        },
        addressInput: 3,
        addressInputSubsteps: {
            thankYou: 1
        },
        petsAmount: 4,
        petDetails: 5,
        codeVerification: 6,
        acknowledgements: 7,
        pricing: 8,
        suggestions: 9,
        appointmentOptions: 10,
        confirmation: 11,
        safety: 12,
        thankYou: 14,
        thankYouSubsteps: {
            createPaymentMethod: 1
        }
    },
    returning: {
        initial: 1,
        phoneInput: 2,
        phoneInputSubsteps: {
            codeVerification: 1
        },
        welcome: 3,
        welcomeSubsteps: {
            petSelection: 1,
            petName: 2,
            petForm: 3,
            referral: 4,
            upcomingAppointmentDetails: 5,
            changeAppointment: 6,
            cancelAppointment: 7
        },
        manageAccount: 4,
        manageAccountSubsteps: {
            newAddress: 1,
            editAddress: 2,
            petInfo: 3,
            newPetForm: 4,
            createPaymentMethod: 5,
            editPaymentMethod: 6,
            notificationSettings: 7
        },
        suggestions: 5,
        appointmentOptions: 6,
        confirmation: 7,
        safety: 8,
        thankYou: 9,
        thankYouSubsteps: {
            createPaymentMethod: 1
        }
    }
};

export enum CustomStepSegment {
    referral = "referral",
    notificationSettings = "notification-settings",
    manageAccount = "manage-account",
    addCard = "add-card"
}

export const getNumbersForCustomStep = (segment: CustomStepSegment) => {
    if (segment === CustomStepSegment.referral) {
        return { step: steps.returning.welcome, substep: steps.returning.welcomeSubsteps.referral };
    }
    else if (segment === CustomStepSegment.notificationSettings) {
        return { step: steps.returning.manageAccount, substep: steps.returning.manageAccountSubsteps.notificationSettings };
    }
    else if (segment === CustomStepSegment.manageAccount) {
        return { step: steps.returning.manageAccount, substep: steps.returning.manageAccount };
    }
    else if (segment === CustomStepSegment.addCard) {
        return { step: steps.returning.manageAccount, substep: steps.returning.manageAccountSubsteps.createPaymentMethod };
    }
    else {
        throw new Error(`Invalid custom step segment: ${segment}`);
    }
};

export default steps;