import { motion } from "framer-motion";
import { Box } from "@mui/material";
import isMobileViewport from "@marathon/web/hooks/isMobileViewport";

interface OnlineBookingContentWrapperProps {
    children: React.ReactNode,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [x: string]: any;
}

export const OnlineBookingContentWrapper = ({ children, ...rest }: OnlineBookingContentWrapperProps) => {
    const isMobile = isMobileViewport();
    if (isMobile) {
        return (
            <Box className="online-booking-step-container" {...rest}>
                {children}
            </Box>
        );
    }
    else {
        return (
            <motion.div
                className="online-booking-step-container"
                initial={{ x: 100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                {...rest}
            >
                {children}
            </motion.div>
        );
    }
};