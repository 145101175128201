import { SecondaryAddress, SecondaryAddressData, SecondaryAddressStatus } from "@marathon/common/entities/SecondaryAddress";
import { DocResult, INJECTED_FIRESTORE_SERVICE_TOKEN } from "./IFirestoreService";
import type { IFirestoreService } from "./IFirestoreService";
import { container, inject, singleton } from "tsyringe";
import { FilterOperator, getFilter } from "@marathon/common/api/QueryFilter";
import { CollectionPaths } from "@marathon/common/entities/base/CollectionPaths";

const mapEntity = function (snapshot: DocResult<SecondaryAddressData>) {
    if (!snapshot.parentId) {
        throw new Error("Parent id is required");
    }
    return new SecondaryAddress(snapshot.id, snapshot.parentId, snapshot.data);
};

@singleton()
export class SecondaryAddressRepository {
    private firestoreService: IFirestoreService<SecondaryAddressData>;
    constructor(@inject(INJECTED_FIRESTORE_SERVICE_TOKEN) injectedService: IFirestoreService<SecondaryAddressData>) {
        injectedService.parentCollectionPath = CollectionPaths.Customers;
        injectedService.collectionPath = CollectionPaths.SecondaryAddresses;
        this.firestoreService = injectedService;
    }
    static get current() {
        return container.resolve(SecondaryAddressRepository);
    }
    async getById(customerId: string, id: string) {
        const doc = await this.firestoreService.getById(id, customerId);
        return doc ? mapEntity(doc) : null;
    }
    async search(customerId: string) {
        const docs = await this.firestoreService.search({
            filters: [
                getFilter("status", FilterOperator.notEqual, SecondaryAddressStatus.hidden)
            ]
        }, customerId);
        return docs.map(x => mapEntity(x));
    }
    async searchByHub(hubId: string) {
        const docs = await this.firestoreService.searchAcross({
            filters: [
                getFilter("status", FilterOperator.equal, SecondaryAddressStatus.active),
                getFilter("drive_time.hub_id", FilterOperator.equal, hubId)
            ]
        });
        return docs.map(x => mapEntity(x));
    }
    async searchAll() {
        const docs = await this.firestoreService.searchAcross({
            filters: [
                getFilter("status", FilterOperator.notEqual, SecondaryAddressStatus.hidden)
            ]
        });
        return docs.map(x => mapEntity(x));
    }
    async create(customerId: string, values: SecondaryAddressInput) {
        const data = SecondaryAddressRepository.fromInput(values);
        const docId = await this.firestoreService.create(data, customerId);
        return mapEntity({ id: docId, parentId: customerId, data });
    }
    async update(customerId: string, secondaryAddressId: string, values: SecondaryAddressInput) {
        const data = {
            address1: values.address1,
            address2: values.address2,
            parking_notes: values.parking_notes,
            state: values.state,
            city: values.city,
            country: values.country,
            zip: values.zip,
            area: values.area,
            street_name: values.street_name,
            place_id: values.place_id,
            lat: values.lat,
            lng: values.lng,
            drive_time: values.drive_time,
            alias: values.alias,
            created_at: new Date(),
            status: values.status
        } as SecondaryAddressData;
        await this.firestoreService.update(secondaryAddressId, data, customerId);
    }
    async remove(customerId: string, secondaryContactId: string) {
        const data = {
            status: SecondaryAddressStatus.hidden,
        } as SecondaryAddressData;
        await this.firestoreService.update(secondaryContactId, data, customerId);
    }
    static fromInput(values: SecondaryAddressInput) {
        return {
            address1: values.address1,
            address2: values.address2,
            parking_notes: values.parking_notes,
            state: values.state,
            city: values.city,
            country: values.country,
            zip: values.zip,
            area: values.area,
            street_name: values.street_name,
            place_id: values.place_id,
            lat: values.lat,
            lng: values.lng,
            drive_time: values.drive_time,
            alias: values.alias,
            created_at: new Date(),
            status: values.status
        } as SecondaryAddressData;
    }
    static toInput(secondaryAddress: SecondaryAddress): SecondaryAddressInput {
        return ({
            id: secondaryAddress.id,
            address1: secondaryAddress.address1,
            address2: secondaryAddress.address2,
            parking_notes: secondaryAddress.parking_notes,
            state: secondaryAddress.state,
            city: secondaryAddress.city,
            country: secondaryAddress.country,
            zip: secondaryAddress.zip,
            area: secondaryAddress.area,
            street_name: secondaryAddress.street_name,
            place_id: secondaryAddress.place_id,
            lat: secondaryAddress.lat,
            lng: secondaryAddress.lng,
            drive_time: secondaryAddress.drive_time,
            alias: secondaryAddress.alias,
            status: secondaryAddress.status
        });
    }
}

export interface SecondaryAddressInput {
    id?: string,
    alias?: string,
    address1?: string,
    address2?: string,
    parking_notes?: string,
    state?: string,
    city?: string,
    country?: string,
    zip?: string,
    area?: string,
    street_name?: string,
    place_id?: string,
    lat?: number,
    lng?: number,
    drive_time?: {
        duration_text: string,
        duration_value: number,
        hub_id: string
    }
    status?: SecondaryAddressStatus,
}
