import { Button as DefaultButton } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

interface Props {
    showSpinner?: boolean,
    disabled?: boolean,
    children: string | React.ReactNode,
    iconName?: string,
    iconOnLeftEdge?: true,
    color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning",
    variant?: "contained" | "text" | "outlined",
    [x: string]: unknown
}

export const Button = ({ showSpinner = false, disabled = false, children, iconName = undefined, iconOnLeftEdge = undefined, color = "primary", variant = "text", ...rest }: Props) => {
    return (
        <DefaultButton
            disabled={disabled || showSpinner}
            color={color}
            variant={variant}
            {...rest}
        >
            {iconName && iconOnLeftEdge && !showSpinner && <i className="material-icons" style={{ marginLeft: 10 }}>{iconName}</i>}
            {!showSpinner && <>{children}</>}
            {showSpinner && <CircularProgress color="inherit" size={20} style={{ marginLeft: 10 }} />}
            {iconName && !iconOnLeftEdge && !showSpinner && <i className="material-icons" style={{ marginLeft: 10 }}>{iconName}</i>}
        </DefaultButton>
    );
};