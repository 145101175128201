import { Box } from "@mui/material";
import { EventAvailable, EventBusy } from "@mui/icons-material";
import { Appointment, AppointmentStatus } from "@marathon/common/entities/Appointment";
import TimerCountDown from "@marathon/web/components/TimerCountDown";

interface HoldAppointmentHeaderProps {
    appointment: Appointment,
    onRefresh: () => Promise<void>
}

export const HoldAppointmentStatus = ({ appointment, onRefresh }: HoldAppointmentHeaderProps) => {

    const renderAppointmentStatus = () => {
        if (appointment.status === AppointmentStatus.pending) {
            return <Pending appointment={appointment} onRefresh={onRefresh} />;
        }
        else if (appointment.status === AppointmentStatus.scheduled) {
            return <Booked />;
        }
        else if (appointment.status === AppointmentStatus.declined) {
            return <NoBooked text="Declined" />;
        }
        else if (appointment.status === AppointmentStatus.expired) {
            return <NoBooked text="Expired" />;
        }
        else {
            return <></>;
        }
    };

    return (
        <Box mt={2} fontWeight={400} fontSize={16}>
            {renderAppointmentStatus()}
        </Box>
    );
};

const Pending = ({ appointment, onRefresh }: HoldAppointmentHeaderProps) => {
    if (!appointment.expiration_time)
        throw new Error(`${appointment.id} is not a valid hold`);
    return (
        <Box>
            Will auto release in <TimerCountDown targetDate={appointment.expiration_time} onExpire={onRefresh} />
        </Box>
    );
};

const Booked = () => {
    return (
        <Box display="flex" alignItems="center">
            Booked <EventAvailable sx={{ ml: 1 }} />
        </Box>
    );
};

const NoBooked = ({ text }: { text: string }) => {
    return (
        <Box display="flex" alignItems="center">
            {text} <EventBusy sx={{ ml: 1 }} />
        </Box>
    );
};