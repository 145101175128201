import { Box } from "@mui/material";
import { Credit, CREDIT_LABEL } from "@marathon/common/entities/Credit";

interface Props {
    credits?: Credit[]
}

export const CreditPriceRow = ({ credits }: Props) => {
    return (
        <Box className="pricing-row-container">
            <Box className="pricing-row-label">
                {CREDIT_LABEL}
            </Box>
            <Box className="pricing-row-amount">
                {`- $${credits?.reduce((partialSum, cre) => partialSum + cre.value, 0)}`}
            </Box>
        </Box>
    );
};