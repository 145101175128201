import { useState } from "react";
import { Box, TextField } from "@mui/material";
import { Button } from "@marathon/web/components/Button";
import useStyles from "@marathon/web/components/onlineBooking/styles";
import isMobileViewport from "@marathon/web/hooks/isMobileViewport";
import { REFERRAL_DISCOUNT_VALUE } from "@marathon/common/entities/Discount";

interface InvitationProps {
    link?: string,
    handleClickQr: () => Promise<void>,
    logActivity: () => Promise<void>
}

export default function InvitationLink({ link, handleClickQr, logActivity }: InvitationProps) {
    const isMobile = isMobileViewport();
    const [hasCopiedUrl, sethasCopiedUrl] = useState(false);

    const copyUrl = () => {
        navigator.clipboard.writeText(`${link}`);
        sethasCopiedUrl(true);
        logActivity();
    };

    const onShareUrl = () => {
        if (navigator.share) {
            navigator
                .share({
                    text: `💙🐶 Hi! Sharing this special link so you can save $${REFERRAL_DISCOUNT_VALUE} on your first Barkbus grooming appointment! Book now at:`,
                    url: `${link}`,
                })
                .then(() => console.log("shared"))
                .catch(error => console.log("error", error));
        }
        navigator.clipboard.writeText(`${link}`);
        logActivity();
    };

    const classes = useStyles();

    const showShareUrl = isMobile && !!navigator.share;

    return (
        <>
            <Box>
                <TextField
                    type="text"
                    value={link}
                    disabled={true}
                    InputProps={{ name: "link" }}
                    size="small"
                    fullWidth
                    variant="outlined"
                />
                <Box display="flex" flexDirection="column">
                    {!showShareUrl &&
                        <Button
                            variant="contained"
                            className={classes.button}
                            style={{ backgroundColor: hasCopiedUrl && "#000" }}
                            onClick={copyUrl}
                            iconName="content_copy"
                            iconOnLeftEdge={true}
                            disableElevation>
                            {!hasCopiedUrl && "Copy URL"}
                            {hasCopiedUrl && "URL Copied"}
                        </Button>}
                    {showShareUrl &&
                        <Button
                            variant="contained"
                            className={classes.button}
                            onClick={onShareUrl}
                            iconName="share"
                            iconOnLeftEdge={true}
                            disableElevation>
                            Share URL
                        </Button>}
                    {isMobile &&
                        <Button
                            variant="contained"
                            className={classes.button}
                            onClick={handleClickQr}
                            iconName="qr_code"
                            iconOnLeftEdge={true}
                            disableElevation>
                            View QR Code
                        </Button>}
                </Box>
            </Box>
        </>
    );
}
