export const deleteFieldInternal: DeleteField = "deleteField" as const;

export declare type DeleteField = "deleteField";

export type UpdateDataInternal<T> = {
    [K in keyof T]?: T[K] extends Primitive
    ? T[K] | DeleteField
    : UpdateDataInternal<T[K]> | DeleteField;
} | UpdateDataInternalDot<T>;

type UpdateDataInternalDot<T> = Partial<
    Record<DotNestedKeys<T>, Primitive | DeleteField>
>;

type DotPrefix<T extends string> = T extends "" ? "" : `.${T}`;

type DotNestedKeys<T> = (
    T extends object
    ? {
        [K in keyof T]: K extends string
        ? `${K}${DotPrefix<DotNestedKeys<T[K]>>}`
        : never;
    }[keyof T]
    : ""
) extends infer D
    ? Extract<D, string>
    : never;

type Join<K, P> = K extends string | number ?
    P extends string | number ?
    `${K}${"" extends P ? "" : "."}${P}`
    : never : never;

type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
    11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...0[]]

export type Paths<T, D extends number = 3> = [D] extends [never] ? never : T extends object ?
    { [K in keyof T]-?: K extends string | number ?
        `${K}` | Join<K, Paths<T[K], Prev[D]>>
        : never
    }[keyof T] : ""

type Primitive = string | number | boolean | object | null | undefined;