import { Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";

interface Props {
    hasError: boolean,
    successAccepted: boolean,
    successDeclined: boolean,
    onCloseError: () => void,
    onCloseSuccessAccepted: () => void,
    onCloseSucessDeclined: () => void
}

export const HoldAppointmentNotifications = ({
    hasError,
    successAccepted,
    successDeclined,
    onCloseError,
    onCloseSuccessAccepted,
    onCloseSucessDeclined }: Props) => {
    return (
        <>
            <Snackbar open={hasError} autoHideDuration={6000} onClose={onCloseError}>
                <Alert severity="error">
                    Something went wrong, please try again!
                </Alert>
            </Snackbar>
            <Snackbar open={successAccepted} autoHideDuration={6000} onClose={onCloseSuccessAccepted}>
                <Alert severity="success">
                    Appointment Successfully Booked!
                </Alert>
            </Snackbar>
            <Snackbar open={successDeclined} autoHideDuration={6000} onClose={onCloseSucessDeclined}>
                <Alert severity="info">
                    Appointment Successfully Declined!
                </Alert>
            </Snackbar>
        </>
    );
};