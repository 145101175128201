import { Box } from "@mui/material";
import { Button } from "@marathon/web/components/Button";
import useStyles from "@marathon/web/components/onlineBooking/styles";
import useImagePreloader from "@marathon/web/hooks/useImagePreloader";
import { Loading } from "@marathon/web/components/Loading";
import viewPricingImg from "images/view-pricing.jpg";
import { getAcknowledgementsDescriptions, getAcknowledgementsTitle } from "./petTextHelper";
import { OnlineBookingContentWrapper } from "../OnlineBookingContentWrapper";
import { TitleWrapper } from "../shared/CommonWrappers";
import { Service } from "@marathon/common/entities/Service";
import { Pet } from "@marathon/common/entities/Pet";

interface Props {
  onNext: Function,
  isLoading: boolean,
  pets: Pet[],
  services: Service[]
}

const preloadSrcList: string[] = [viewPricingImg];

export default function LeadStepAcknowledgements({
  onNext,
  isLoading,
  pets,
  services
}: Props) {
  const classes = useStyles();
  const { imagesPreloaded } = useImagePreloader(preloadSrcList);

  const petServices = pets.map(pet => {
    const petServiceId = pet.services[0].id;
    const service = services.find(s => s.id === petServiceId);
    return {
      pet: pet.name,
      serviceAcronym: service?.getAcronym() ?? ""
    };
  });

  const descriptions = getAcknowledgementsDescriptions(pets, petServices);

  if (!imagesPreloaded) {
    return (
      <Loading />
    );
  }

  return (
    <OnlineBookingContentWrapper>
      <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} alignItems="center">
        <Box
          width={{ xs: 250, sm: 540 }}
          display={{
            xs: "block",
            sm: "none",
            md: "none",
            lg: "block"
          }}>
          <img src={viewPricingImg} alt="Barkbus - View Pricing" width="100%" height="100%" />
        </Box>
        <Box width={{ xs: "100%", sm: "500px" }} mt={{ xs: 3, sm: 0 }} ml={{ xs: 0, sm: 5 }} justifyContent="center" alignItems="center">
          <TitleWrapper title={getAcknowledgementsTitle(pets)} />
          <Box display="flex" justifyContent="left">
            <Box fontSize={16} mt={3} fontWeight={400} textAlign="left" lineHeight={"22px"} maxWidth={500} color="#262626">
              {descriptions[0]}
              <br />
              <br />
              {descriptions[1]}
            </Box>
          </Box>
          <Button
            showSpinner={isLoading}
            onClick={() => onNext()}
            variant="contained"
            className={classes.button}
            disableElevation
          >
            View pricing
          </Button>
        </Box>
      </Box>
    </OnlineBookingContentWrapper>
  );
}