import { AppointmentPetInput } from "./AppointmentPetInput";
import { AppointmentOrOccurrence } from "@marathon/common/entities/Occurrence";
import { Appointment, AppointmentStatus, AppointmentCredit, PaymentStatus, CancellationReason, SystemCancellationReason } from "@marathon/common/entities/Appointment";
import DeviceStorageCache from "@marathon/client-side/utilities/DeviceStorageCache";
import { TimeZone } from "@marathon/common/helpers/timeZoneHelper";
import { MobileServiceFeeConfiguration } from "@marathon/common/entities/Configuration";
import { DiscountData } from "@marathon/common/entities/Discount";
import { WEEKLY_FREQUENCY_TYPE } from "@marathon/common/entities/Recurrence";
import { getMinutesBetweenDates } from "@marathon/common/helpers/timeHelper";
import { AppointmentSeed } from "./AppointmentSeed";
import { Service } from "@marathon/common/entities/Service";
import { Customer } from "@marathon/common/entities/Customer";
import { User } from "@marathon/common/entities/User";

interface AppointmentInputData {
    id?: string
    customerId: string | null
    groomerId: string | null
    startTime: Date
    frequency: { type: string, interval: number } | null
    selectedPets: AppointmentPetInput[]
    extraItems?: { description: string, duration: number, price: number }[]
    extraTime: number
    notes: string
    collected: number | null
    isOnHold: boolean
    expiration: number
    isFromSignup: boolean
    suggestionAppointment?: {
        id: string,
        status: AppointmentStatus.pending | AppointmentStatus.declined | AppointmentStatus.expired
    }
    suggestionForAppointmentId?: string
    bookedBy?: string
    createdAt?: Date
    occurrenceIndex?: number
    status?: AppointmentStatus
    paymentStatus?: PaymentStatus
    expirationTime?: Date
    recurrenceId?: string
    serviceEndTime?: Date
    isFromSms?: boolean,
    attributedTo?: string,
    isFromOnlineBooking: boolean,
    notesFromCustomer?: string,
    discounts?: DiscountData[],
    credits?: AppointmentCredit[],
    timeZone: TimeZone,
    invitationCode?: string,
    groomingNotes?: string,
    secondaryAddressId?: string,
    origin?: string,
    unconfirmed?: {
        by: string,
        at: Date
    },
    cancellation?: {
        reason: CancellationReason | SystemCancellationReason,
        customer_notified?: true,
        collected_fee?: number
    }
}

interface AppointmentInput extends AppointmentInputData {
    rescheduleAppointment: AppointmentOrOccurrence | null;
}

class AppointmentInput {
    constructor(input: AppointmentInputData | null, mobileServiceFee: MobileServiceFeeConfiguration | null, customer: Customer | null) {
        if (input) {
            Object.assign(this, input);
        }
        else {
            if (!mobileServiceFee)
                throw new Error("Mobile service fee configuration is required at this point");

            this.customerId = null;
            this.groomerId = null;
            this.startTime = new Date();
            this.frequency = null;
            this.selectedPets = [];
            this.extraItems = mobileServiceFee.is_enabled && (customer === null || !customer.skip_mobile_service_fee)
                ? [Appointment.mobileServiceFeeToExtraItem(mobileServiceFee, customer)]
                : undefined;
            this.extraTime = 0;
            this.notes = "";
            this.collected = null;
            this.isOnHold = false;
            this.expiration = 60;
            this.isFromSignup = false;
            this.attributedTo = DeviceStorageCache.getCurrentUserName() || User.systemUsers.onlineBooking;
            this.rescheduleAppointment = null;
            this.isFromOnlineBooking = false;
            this.notesFromCustomer = "";
            this.timeZone = TimeZone.PacificTime;
            this.groomingNotes = "";
        }
    }
    getDuration(services: Service[]) {
        return Appointment.getDuration(
            services,
            this.selectedPets,
            this.extraItems,
            this.extraTime
        );
    }
    grossPrice() {
        return Appointment.getGrossPrice(
            this.selectedPets,
            this.extraItems
        );
    }
    finalPrice() {
        return Appointment.getFinalPrice(
            this.selectedPets,
            this.extraItems,
            this.discounts,
            this.credits
        );
    }
    isPaid() {
        return this.paymentStatus === PaymentStatus.paid;
    }
    isCompleted() {
        return this.status === AppointmentStatus.completed;
    }
    isCancelled() {
        return this.status === AppointmentStatus.cancelled;
    }
    isUnconfirmed() {
        return this.status === AppointmentStatus.unconfirmed;
    }
    isTimeSuggestion() {
        return !!this.suggestionForAppointmentId;
    }
    canBeCancelled() {
        const cannot = this.isPaid() || this.isCancelled();
        return !cannot;
    }
    canSuggestNewTime() {
        const cannot = this.hasPendingSuggestion() || this.isTimeSuggestion() || this.isCancelled();
        return !cannot;
    }
    static getExtraTime(appointment: AppointmentOrOccurrence, services: Service[]) {
        const durationWithoutExtraTime = Appointment.getDuration(services, appointment.selected_pets, appointment.extra_items);
        return getMinutesBetweenDates(appointment.start_time, appointment.end_time) - durationWithoutExtraTime;
    }
    static getFromAppointment(appointment: AppointmentOrOccurrence, services: Service[]) {
        const input = new AppointmentInput({
            id: appointment.id,
            customerId: appointment.customer.id,
            groomerId: appointment.groomer.id,
            startTime: appointment.start_time,
            expirationTime: appointment.expiration_time,
            frequency: appointment.frequency || null,
            selectedPets: appointment.selected_pets.map(x => new AppointmentPetInput(x)),
            extraItems: appointment.extra_items?.map(x => ({ ...x })),
            extraTime: this.getExtraTime(appointment, services) || 0,
            notes: appointment.notes || "",
            bookedBy: appointment.booked_by,
            createdAt: appointment.created_at,
            serviceEndTime: appointment.service_end_time,
            occurrenceIndex: appointment.occurrenceIndex,
            status: appointment.status,
            paymentStatus: appointment.payment_status,
            collected: Appointment.getFinalPriceFromData(appointment) + (appointment.tip || 0),
            recurrenceId: appointment.recurrence_id,
            isOnHold: false,
            expiration: 0,
            isFromSignup: false,
            isFromSms: appointment.is_from_sms,
            attributedTo: appointment.attributed_to,
            isFromOnlineBooking: appointment.is_from_online_booking || false,
            notesFromCustomer: appointment.notes_from_customer,
            discounts: appointment.discounts,
            credits: appointment.credits,
            timeZone: appointment.time_zone,
            groomingNotes: appointment.grooming_notes,
            secondaryAddressId: appointment.customer.secondary_address_id,
            suggestionForAppointmentId: appointment.suggestion_for_appointment_id,
            suggestionAppointment: appointment.suggestion_appointment,
            origin: appointment.origin,
            unconfirmed: appointment.unconfirmed,
            cancellation: appointment.cancellation
        }, null, null);

        return input;
    }
    static getForCustomer(customer: Customer, mobileServiceFee: MobileServiceFeeConfiguration | null) {
        const input = new AppointmentInput(null, mobileServiceFee, customer);
        input.customerId = customer.id;
        return input;
    }
    static getFromSeed(seed: AppointmentSeed, mobileServiceFee: MobileServiceFeeConfiguration | null, customer: Customer | null) {
        const input = new AppointmentInput(null, mobileServiceFee, customer);
        input.customerId = seed.customerId;
        input.groomerId = seed.groomerId;
        input.startTime = seed.startTime;
        input.timeZone = seed.timeZone;
        input.selectedPets = seed.selectedServices?.map(selectedServiceByPet => {
            const petInput = new AppointmentPetInput();
            petInput.petId = selectedServiceByPet.petId;
            petInput.serviceId = selectedServiceByPet.serviceId;
            return petInput;
        }) ?? [];
        input.rescheduleAppointment = seed.rescheduleAppointment || null;
        input.frequency = seed.interval ? { type: WEEKLY_FREQUENCY_TYPE, interval: seed.interval } : null;
        input.secondaryAddressId = seed.secondaryAddressId;
        return input;
    }
    hasPendingSuggestion() {
        return this.suggestionAppointment?.status === AppointmentStatus.pending;
    }
    hasGroomerInExcludeList(customer: Customer, groomerId: string) {
        return customer?.exclude_groomer_ids?.includes(groomerId) || false;
    }
}

export { AppointmentInput };