import { DateTime } from "luxon";
import { DayOfWeek, ZERO_BASED_DAYS_OF_WEEK } from "@marathon/common/entities/Groomer";
import { TimeZone, getTimeZoneBaseAcronym } from "../helpers/timeZoneHelper";

export default class LocalDate {
    month: number;
    day: number;
    year: number;
    contextTimeZone: TimeZone;

    constructor(month: number, day: number, year: number, contextTimeZone: TimeZone) {
        this.month = month;
        this.day = day;
        this.year = year;
        this.contextTimeZone = contextTimeZone;
    }

    static forSystemTimeZone(date: Date, contextTimeZone: TimeZone) {
        return new LocalDate(date.getMonth() + 1, date.getDate(), date.getFullYear(), contextTimeZone);
    }

    static forContextTimeZone(date: Date, contextTimeZone: TimeZone) {
        const dateTime = DateTime.fromJSDate(date).setZone(contextTimeZone);
        return new LocalDate(dateTime.month, dateTime.day, dateTime.year, contextTimeZone);
    }

    static fromLocalDate(localDate: LocalDate, contextTimeZone: TimeZone) {
        return new LocalDate(localDate.month, localDate.day, localDate.year, contextTimeZone);
    }

    toDayStartForSystemTimeZone() {
        return (
            DateTime
                .fromObject({ year: this.year, month: this.month, day: this.day })
                .toJSDate()
        );
    }

    toDayStart() {
        return (
            DateTime
                .fromObject(
                    { year: this.year, month: this.month, day: this.day },
                    { zone: this.contextTimeZone }
                )
                .toJSDate()
        );
    }

    toIsoDateString() {
        return (
            DateTime
                .fromObject(
                    { year: this.year, month: this.month, day: this.day },
                    { zone: this.contextTimeZone }
                )
                .toFormat("yyyy-MM-dd")
        );
    }

    toAbbreviatedTimeZoneString() {
        return (
            getTimeZoneBaseAcronym(this.contextTimeZone)
        );
    }

    toDayEnd() {
        return (
            DateTime
                .fromObject(
                    { year: this.year, month: this.month, day: this.day },
                    { zone: this.contextTimeZone }
                )
                .endOf("day")
                .toJSDate()
        );
    }

    private toWeekStartInternal() {
        const dateTime = DateTime.fromObject(
            { year: this.year, month: this.month, day: this.day },
            { zone: this.contextTimeZone }
        );

        //HACK: We need to force Sunday as the week start, since luxon hard-coded the start of week as Monday.
        return (
            dateTime.weekday === 7
                ? dateTime
                : dateTime.startOf("week").minus({ days: 1 })
        );
    }

    toWeekStart() {
        return this.toWeekStartInternal().toJSDate();
    }

    toWeekEnd() {
        return this.toWeekStartInternal().plus({ days: 6 }).endOf("day").toJSDate();
    }

    toDateWithParsedTime(timeString: string) {
        const time = DateTime.fromFormat(timeString, "HH:mm");
        return DateTime
            .fromJSDate(this.toDayStart())
            .setZone(this.contextTimeZone)
            .set({ hour: time.hour, minute: time.minute })
            .toJSDate();
    }

    toDateWithTime(time: Date) {
        const dateTime = DateTime.fromJSDate(time).setZone(this.contextTimeZone);
        return DateTime
            .fromJSDate(this.toDayStart())
            .setZone(this.contextTimeZone)
            .set({ hour: dateTime.hour, minute: dateTime.minute })
            .toJSDate();
    }

    getPlusDays(days: number) {
        const newDateTime = DateTime
            .fromObject(
                { year: this.year, month: this.month, day: this.day },
                { zone: this.contextTimeZone }
            )
            .plus({ days });
        return (
            new LocalDate(newDateTime.month, newDateTime.day, newDateTime.year, this.contextTimeZone)
        );
    }

    includesDate(date: Date) {
        return (
            this.toDayStart().getTime() <= date.getTime() &&
            this.toDayEnd().getTime() >= date.getTime()
        );
    }

    isGreaterThanDate(date: Date, correctTimeZone?: TimeZone) {
        //HACK: This is to support cases where the date paremeter represents a day (with no time component)
        // and the local date's time zone could be incorrect (like when the All Hubds calendar deals with groomer exceptions).
        const correctLocalDate = correctTimeZone ? LocalDate.fromLocalDate(this, correctTimeZone) : this;
        return (
            correctLocalDate.toDayStart() > date
        );
    }

    isLessThanDate(date: Date, correctTimeZone?: TimeZone) {
        //HACK: This is to support cases where the date paremeter represents a day (with no time component)
        // and the local date's time zone could be incorrect (like when the All Hubds calendar deals with groomer exceptions).
        const correctLocalDate = correctTimeZone ? LocalDate.fromLocalDate(this, correctTimeZone) : this;
        return (
            correctLocalDate.toDayEnd() < date
        );
    }

    isToday() {
        return (
            this.includesDate(new Date())
        );
    }

    precedesOrEqual(other: LocalDate) {
        return (
            this.toDayStart().getTime() <= other.toDayStart().getTime()
        );
    }

    getDayOfWeek() {
        const zeroBasedDay = this.getZeroBasedWeekDay();
        return ZERO_BASED_DAYS_OF_WEEK[zeroBasedDay] as DayOfWeek;
    }

    getZeroBasedWeekDay() {
        return (
            DateTime
                .fromObject({ year: this.year, month: this.month, day: this.day })
                .toJSDate()
                .getDay()
        );
    }
}