import * as Sentry from "@sentry/react";
import LocalDate from "@marathon/common/utilities/LocalDate";
import { BookingSuggestion } from "@marathon/common/api/BookingSuggestion";
import { TimeZone } from "@marathon/common/helpers/timeZoneHelper";
import useStyles from "@marathon/web/components/onlineBooking/styles";
import { Box } from "@mui/material";
import { TrackData, TrackEventCallback } from "./tracker/useTracker";
import { OnlineBookingContentWrapper } from "../OnlineBookingContentWrapper";
import { TitleWrapper, ContentWrapper } from "./CommonWrappers";
import { AppointmentSuggestionsItem } from "@marathon/web/components/onlineBooking/AppointmentSuggestionItem";
import { AppointmentSuggestionFilter } from "@marathon/web/components/onlineBooking/AppointmentSuggestionFilter";
import AppointmentSuggestionsLoader from "@marathon/web/components/appointmentSuggestion/AppointmentSuggestionsLoader";
import { Groomer } from "@marathon/common/entities/Groomer";
import { LeadOutcome } from "@marathon/common/entities/Customer";
import { AppointmentTimeFilter } from "@marathon/common/entities/Appointment";

interface FindSuggestionsProps {
    isLoading: boolean,
    suggestions: BookingSuggestion[],
    groomers: Groomer[],
    specificDate: LocalDate | null,
    setSpecificDate: React.Dispatch<React.SetStateAction<LocalDate | null>>,
    selectedGroomerId: string | null,
    setSelectedGroomerId: React.Dispatch<React.SetStateAction<string | null>>,
    selectedTimeFilter: AppointmentTimeFilter | null,
    setSelectedTimeFilter: React.Dispatch<React.SetStateAction<AppointmentTimeFilter | null>>,
    onBookAppointmentClick: () => void,
    setBookedSuggestion: React.Dispatch<React.SetStateAction<BookingSuggestion | null>>,
    tryUpdateLeadInfoOutcome?: (initialStatus: LeadOutcome, finalStatus: LeadOutcome) => Promise<void>,
    trackEvent?: TrackEventCallback,
    trackEventData?: TrackData,
    customerTimeZone: TimeZone,
    isFromAppointmentChange?: boolean
}

export default function FindSuggestions({
    isLoading,
    suggestions,
    groomers,
    specificDate,
    setSpecificDate,
    selectedGroomerId,
    setSelectedGroomerId,
    selectedTimeFilter,
    setSelectedTimeFilter,
    onBookAppointmentClick,
    setBookedSuggestion,
    tryUpdateLeadInfoOutcome,
    trackEvent,
    trackEventData,
    customerTimeZone,
    isFromAppointmentChange
}: FindSuggestionsProps) {

    const classes = useStyles();

    const showSorryMessage =
        !!specificDate &&
        suggestions.length > 0 &&
        suggestions.every(x => !specificDate.includesDate(x.arrivalTime));

    const setupNewSearch = () => {
        if (trackEvent && trackEventData)
            trackEvent(trackEventData);
    };

    const handleBookAppointment = async (suggestion: BookingSuggestion) => {
        try {
            setBookedSuggestion(suggestion);
            await tryUpdateLeadInfoOutcome?.(LeadOutcome.open_find_appointment, LeadOutcome.open_appointment_options);
            onBookAppointmentClick();
        }
        catch (error) {
            Sentry.captureException(error);
        }
    };

    if (isLoading) {
        return (
            <AppointmentSuggestionsLoader style={{ marginTop: "-50px" }} />
        );
    }

    return (
        <OnlineBookingContentWrapper>
            {!isFromAppointmentChange &&
                <TitleWrapper title="Select an appointment">
                    <Box
                        display={{
                            xs: "none",
                            sm: "block",
                            md: "block",
                            lg: "block"
                        }}>
                        <h2>
                            Suggestions prioritize lower drive times for our staff.
                            <br />
                            Soonest may not always appear at the top of the list.
                        </h2>
                    </Box>
                </TitleWrapper>}
            <ContentWrapper>
                <AppointmentSuggestionFilter
                    setSpecificDate={setSpecificDate}
                    setupNewSearch={setupNewSearch}
                    specificDate={specificDate}
                    selectedTimeFilter={selectedTimeFilter}
                    setSelectedTimeFilter={setSelectedTimeFilter}
                    selectedGroomerId={selectedGroomerId}
                    setSelectedGroomerId={setSelectedGroomerId}
                    customerTimeZone={customerTimeZone}
                    groomers={groomers}
                />
                <div className="suggestions-list">
                    {showSorryMessage &&
                        <div className="suggestions-sorry-message">
                            Note: We couldn't find any suggestions for the date you selected.
                            <br />
                            Try picking another day, or chose from the options below
                        </div>}
                    {suggestions.map((suggestion, idx) =>
                        <AppointmentSuggestionsItem
                            key={idx}
                            suggestion={suggestion}
                            onBookAppointment={handleBookAppointment}
                        />
                    )}
                    {suggestions.length === 0 &&
                        <div className="suggestions-sorry-message">
                            No available appointments.
                            <br />
                            Please try different search criteria.
                        </div>}
                    {suggestions.length > 0 &&
                        <div className={classes.auxiliaryButtonWrapper}>
                            <span
                                className={classes.suggestionsAuxiliaryButton}
                                onClick={() => window.scrollTo({
                                    top: 133,
                                    behavior: "smooth"
                                })}
                            >
                                Try a different search criteria for more suggestions
                            </span>
                        </div>}
                </div>
            </ContentWrapper>
        </OnlineBookingContentWrapper>
    );
};