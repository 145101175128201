import { Fragment, useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { ReferralEventType, ReferralOrigin } from "@marathon/common/entities/ReferralActivity";
import { CustomerRepository } from "@marathon/client-side/repositories/CustomerRepository";
import { ReferralActivityRepository } from "@marathon/client-side/repositories/ReferralActivityRepository";
import referralProgramImg from "images/referral-program.jpg";
import InvitationLink from "../shared/InvitationLink";
import { Button } from "@marathon/web/components/Button";
import useStyles from "@marathon/web/components/onlineBooking/styles";
import { OnlineBookingContentWrapper } from "../OnlineBookingContentWrapper";
import { TitleWrapper, ContentWrapper } from "../shared/CommonWrappers";
import { REFERRAL_CREDIT_VALUE, REFERRAL_DISCOUNT_VALUE } from "@marathon/common/entities/Discount";
import QRCode from "react-qr-code";
import { Customer } from "@marathon/common/entities/Customer";

interface InvitationProps {
    customer: Customer
}

const ReferFriend = ({ customer }: InvitationProps) => {
    const [invitationLink, setInvitationLink] = useState<string | null>(null);
    const classes = useStyles();
    const [showQr, setShowQr] = useState(false);

    const logActivity = async (link: string) => {
        await ReferralActivityRepository.current.create(customer, ReferralEventType.SharedUrl, link, ReferralOrigin.customer);
    };

    const handleClickQr = async (link: string) => {
        setShowQr(true);
        await logActivity(link);
    };

    useEffect(() => {
        const fetchInvitationCode = async () => {
            setInvitationLink(null);
            const code = await CustomerRepository.current.getInvitationCode(customer);
            setInvitationLink(`${process.env.REACT_APP_CUSTOMERS_BASE_URL}/online-booking-signup?invitation=${code}`);
        };
        fetchInvitationCode();
    }, [customer]);

    return (
        <OnlineBookingContentWrapper>
            {!invitationLink && <CircularProgress size={80} style={{ marginTop: 100, marginBottom: 100 }} />}
            {!!invitationLink &&
                <Fragment>
                    {showQr &&
                        <Box>
                            <div style={{ fontSize: 20, marginBottom: 20 }}>${REFERRAL_DISCOUNT_VALUE} off your first groom</div>
                            <QRCode value={invitationLink} size={320} />
                            <ContentWrapper mt={2}>
                                <Button
                                    variant="outlined"
                                    className={classes.button}
                                    onClick={() => setShowQr(false)}
                                    iconName="arrow_back"
                                    iconOnLeftEdge={true}
                                    disableElevation>
                                    Back
                                </Button>
                            </ContentWrapper>
                        </Box>}
                    {!showQr &&
                        <TitleWrapper st>
                            <Box style={{ textAlign: "center" }}>
                                <img src={referralProgramImg} alt="Barkbus referral program" width="180" />
                                <h1>
                                    Share Barkbus & save!
                                </h1>
                                <h2>
                                    Share this ${REFERRAL_DISCOUNT_VALUE} discount URL with a friend
                                    <br />
                                    and you’ll save ${REFERRAL_CREDIT_VALUE} each time it’s used.
                                </h2>
                                <Box width={{ xs: "100%", sm: 600 }} mt={4}>
                                    <InvitationLink
                                        link={invitationLink}
                                        handleClickQr={async () => await handleClickQr(invitationLink)}
                                        logActivity={async () => await logActivity(invitationLink)}
                                    />
                                </Box>
                            </Box>
                        </TitleWrapper>}
                </Fragment>}
        </OnlineBookingContentWrapper>
    );
};

export default ReferFriend;