import { container, inject, singleton } from "tsyringe";
import { Pet, PetData, PetFilter, PetStatus } from "@marathon/common/entities/Pet";
import LocalDate from "@marathon/common/utilities/LocalDate";
import { calculatePetAge, getBirthday } from "@marathon/common/helpers/timeHelper";
import { PetInput, serviceInputToPetService } from "../entities/PetInput";
import { Breed } from "@marathon/common/entities/Breed";
import { PricingPreset } from "@marathon/common/entities/PricingPreset";
import { DocResult, INJECTED_FIRESTORE_SERVICE_TOKEN } from "./IFirestoreService";
import type { IFirestoreService } from "./IFirestoreService";
import { FilterOperator, getFilter, getOrderBy, OrderBy, OrderDirection, QueryFilter } from "@marathon/common/api/QueryFilter";
import { deleteFieldInternal } from "@marathon/common/utilities/TypeUtils";
import { CollectionPaths } from "@marathon/common/entities/base/CollectionPaths";
import { CustomerLogActivities } from "@marathon/common/entities/CustomerLog";
import DeviceStorageCache from "@marathon/client-side/utilities/DeviceStorageCache";
import { CustomerLogRepository } from "./CustomerLogRepository";

const mapEntity = function (snapshot: DocResult<PetData>) {
    if (!snapshot.parentId)
        throw new Error("Parent id is required");
    return new Pet(snapshot.id, snapshot.parentId, snapshot.data);
};

@singleton()
export class PetRepository {
    private firestoreService: IFirestoreService<PetData>;
    constructor(@inject(INJECTED_FIRESTORE_SERVICE_TOKEN) injectedService: IFirestoreService<PetData>) {
        injectedService.parentCollectionPath = CollectionPaths.Customers;
        injectedService.collectionPath = CollectionPaths.Pets;
        this.firestoreService = injectedService;
    }
    static get current() {
        return container.resolve(PetRepository);
    }
    async create(customerId: string, input: PetInput) {
        const data = getPetDataForCreate(input, customerId);
        const docId = await this.firestoreService.create(getPetDataForCreate(input, customerId), customerId);
        return mapEntity({ id: docId, parentId: customerId, data });
    }
    async search(customerId: string, filter = PetFilter.validForBooking) {
        const docs = await this.firestoreService.search({}, customerId);
        return docs.map(x => mapEntity(x)).filter(x => Pet.filter(x, filter));
    }
    async searchByCustomerIds(customerIds: string[], filter = PetFilter.validForBooking) {
        const uniqueCustomerIds = Array.from(new Set(customerIds));
        const docs = await this.firestoreService.searchInBatchByField("customer_id", uniqueCustomerIds, true);
        return docs.map(x => mapEntity(x)).filter(x => Pet.filter(x, filter));
    }
    async searchDeceased(fromDate: LocalDate, toDate: LocalDate) {
        const filters: QueryFilter<PetData>[] = [
            getFilter("is_deceased", FilterOperator.equal, true),
            getFilter("deceased_at", FilterOperator.greaterThanOrEqual, fromDate.toDayStart()),
            getFilter("deceased_at", FilterOperator.lessThanOrEqual, toDate.toDayEnd())
        ];
        const orders: OrderBy<PetData>[] = [getOrderBy("deceased_at", OrderDirection.desc)];
        const docs = await this.firestoreService.searchAcross({
            filters,
            orders
        });
        return docs.map(x => mapEntity(x));
    }
    async updateNotes(customerId: string, petId: string, notes: string) {
        await this.firestoreService.update(petId, { notes }, customerId);
    }
    async updatePetsWeight(originalPets: Pet[], grownPets: Pet[], pricingPreset: PricingPreset, breeds: Breed[]) {
        await Promise.all(grownPets.map(async grownPet => {
            const originalPet = originalPets.find(x => x.id === grownPet.id);
            if (!originalPet)
                throw new Error(`Pet with id ${grownPet.id} not found`);

            const hasNewWeight = originalPet.weight !== grownPet.weight;
            if (hasNewWeight) {
                await this.firestoreService.update(
                    grownPet.id,
                    { weight: grownPet.weight, services: grownPet.getServices(pricingPreset, breeds) },
                    grownPet.customerId
                );
            }

            const action = hasNewWeight
                ? CustomerLogActivities.groomerInputtedNewWeight
                : CustomerLogActivities.groomerConfirmedSameWeight;
            await CustomerLogRepository.current.create(grownPet.customerId, {
                date: new Date(),
                description: `${action} for ${grownPet.name} (${grownPet.weight} lbs)`,
                user: DeviceStorageCache.getNonEmptyCurrentUserName()
            });
        }));
    }
    async updateFromInput(customerId: string, petId: string, input: PetInput) {
        if (!input.services || input.services.length === 0)
            throw new Error("Invalid pet services configuration");

        const original = await this.firestoreService.getById(petId, customerId);
        if (!original?.data)
            throw new Error("Pet not found");

        const data = getPetDataForUpdate(input, original.data);
        await this.firestoreService.update(petId, data, customerId);
    }
    async remove(customerId: string, petId: string) {
        await this.firestoreService.update(petId, {
            status: PetStatus.deleted,
        }, customerId);
    }
    async markAsDeceased(customerId: string, petId: string) {
        await this.firestoreService.update(petId, {
            status: PetStatus.deleted,
            is_deceased: true,
            deceased_at: new Date()
        }, customerId);
    }
    async markAsDoNotBook(customerId: string, petId: string) {
        await this.firestoreService.update(petId, {
            do_not_book: true
        }, customerId);
    }
    static getServices(breed: Breed, weight: number, pricingPreset: PricingPreset) {
        const servicesIds = breed.getServiceIdsByWeight(weight);
        if (!servicesIds)
            throw new Error("Is not possible to determine pet's services configuration");

        return servicesIds.map(x => PetRepository.toServiceInput(x, pricingPreset));
    }
    static toServiceInput(serviceId: string, pricingPreset: PricingPreset) {
        const servicePrice = pricingPreset.services.find(x => x.service_id === serviceId);
        if (!servicePrice)
            throw new Error(`Service ${serviceId} not valid for preset ${pricingPreset.id}`);

        return {
            id: serviceId,
            price: servicePrice.price
        };
    }
    static getInstanceFromInput(petId: string, customerId: string, input: PetInput) {
        const data: PetData = {
            name: input.name?.trim() ?? "",
            breed_id: input.breed_id,
            weight: Math.round(input.weight ?? 0),
            notes: input.notes ?? "",
            gender: input.gender ?? "",
            services: input.services?.map(x => serviceInputToPetService(x)) ?? [],
            created_at: new Date(),
            status: PetStatus.active,
            customer_id: customerId
        };

        if (!!input.age && !isNaN(input.age) && input.age > 0) {
            data.age_date = getBirthday(input.age);
        }

        return new Pet(petId, customerId, data);
    }
    async updateProfilePhotoUrl(customerId: string, petId: string, profilePhotoUrl: string) {
        await this.firestoreService.update(petId, { profile_photo_url: profilePhotoUrl }, customerId);
    }
    async removeProfilePhotoUrl(customerId: string, petId: string) {
        await this.firestoreService.update(petId, { profile_photo_url: deleteFieldInternal }, customerId);
    }
    static toInput(pet: Pet): PetInput {
        return ({
            id: pet.id,
            name: pet.name,
            breed_id: pet.breed_id,
            weight: pet.weight,
            age: pet.age_date ? calculatePetAge(pet.age_date) : 0,
            gender: pet.gender,
            notes: pet.notes,
            price: pet.price,
            services: pet.services,
            profile_photo_url: pet.profile_photo_url,
            do_not_book: pet.do_not_book
        });
    }
}

const getPetDataForCreate = (input: PetInput, customerId: string): PetData => {
    if (!input.age || isNaN(input.age) || input.age < 0)
        throw new Error("Age is required at this point");

    if (!input.services || input.services.length === 0)
        throw new Error("Invalid pet services configuration");

    const data: PetData = {
        name: input.name?.trim() ?? "",
        breed_id: input.breed_id,
        weight: Math.round(input.weight ?? 0),
        notes: input.notes ?? "",
        gender: input.gender ?? "",
        services: input.services.map(x => serviceInputToPetService(x)),
        customer_id: customerId,
        age_date: getBirthday(input.age),
        created_at: new Date(),
        status: PetStatus.active
    };

    return data;
};

const getPetDataForUpdate = (input: PetInput, original: PetData): Partial<PetData> => {
    const data: Partial<PetData> = {
        name: input.name?.trim() ?? "",
        breed_id: input.breed_id,
        weight: Math.round(input.weight ?? 0),
        notes: input.notes ?? "",
        gender: input.gender ?? "",
        services: input.services?.map(x => serviceInputToPetService(x)) ?? []
    };

    const shouldSetAge = !!input.age && (!original.age_date || calculatePetAge(original.age_date) !== input.age);
    if (shouldSetAge) {
        if (!input.age || isNaN(input.age) || input.age < 0)
            throw new Error("Age is required at this point");

        data.age_date = getBirthday(input.age);
    }

    return data;
};