import { container, inject, singleton } from "tsyringe";
import { DocResult, INJECTED_FIRESTORE_SERVICE_TOKEN } from "./IFirestoreService";
import type { IFirestoreService } from "./IFirestoreService";
import { CollectionPaths } from "@marathon/common/entities/base/CollectionPaths";
import { Message, MessageData, MessageTagPetData } from "@marathon/common/entities/Message";
import { FilterOperator, getFilter } from "@marathon/common/api/QueryFilter";
import { deleteFieldInternal } from "@marathon/common/utilities/TypeUtils";
import { Appointment } from "@marathon/common/entities/Appointment";
import { Pet } from "@marathon/common/entities/Pet";
import { formatNames } from "@marathon/common/helpers/textHelper";
import { BaseMessageRepository } from "./BaseMessageRepository";
import { PhoneContact } from "@marathon/client-side/hooks/useCustomerPhoneContacts";

@singleton()
export class CustomerMessageRepository extends BaseMessageRepository {
    constructor(@inject(INJECTED_FIRESTORE_SERVICE_TOKEN) injectedService: IFirestoreService<MessageData>) {
        super(injectedService, CollectionPaths.Customers);
    }
    static get current() {
        return container.resolve(CustomerMessageRepository);
    }
    listenChangesWithMedia(
        parentId: string,
        callback: (messages: Message[]) => void) {
        return this.firestoreService.onQuerySnapshot({
            filters: [getFilter("has_media", FilterOperator.equal, true)]
        }, (docs: DocResult<MessageData>[]) => {
            callback(docs.map(doc => this.toEntity(doc)));
        }, parentId);
    }
    async searchWithTaggedImages(parentId: string) {
        const docs = await this.firestoreService.search({
            filters: [getFilter("tags", FilterOperator.notEqual, null)]
        }, parentId);
        return docs.map(doc => this.toEntity(doc));
    }
    async tagPets(customerId: string, messageId: string, tags: MessageTagPetData[]) {
        await this.update(customerId, messageId, { "tags.pets": tags });
    }
    async deletePetsTag(parentId: string, messageId: string) {
        await this.update(parentId, messageId, { "tags.pets": deleteFieldInternal });
    }
    async deleteStageTag(parentId: string, messageId: string) {
        await this.update(parentId, messageId, { "tags.stage": deleteFieldInternal });
    }
    get notify() {
        const getName = (fullName: string) => fullName.split(" ")[0];
        return {
            groomerOnTheirWay: async (appointment: Appointment, contact: PhoneContact) => {
                const content = `Your Barkbus Pet Stylist ${getName(appointment.groomer.name)} is on their way and will arrive in approximately ${appointment.minutes_until_arrival} ${appointment.minutes_until_arrival === 1 ? "minute" : "minutes"}. See you soon!`;
                await this.createSms({
                    parentId: appointment.customer.id,
                    content,
                    phone: contact.phone,
                    secondaryName: contact.isSecondary ? contact.name : undefined
                });
            },
            groomerHasArrived: async (appointment: Appointment, contact: PhoneContact) => {
                const content = `Hi ${getName(contact.name)}! Your Barkbus Pet Stylist ${getName(appointment.groomer.name)} has arrived!`;
                await this.createSms({
                    parentId: appointment.customer.id,
                    content,
                    phone: contact.phone,
                    secondaryName: contact.isSecondary ? contact.name : undefined
                });
            },
            completeGrooming: async (appointment: Appointment, pets: Pet[], contact: PhoneContact) => {
                const appointmentPets = pets.filter(x => appointment.selected_pets.map(y => y.petId).includes(x.id));
                const content = `Hi ${getName(contact.name)} -- I have finished ${formatNames(appointmentPets.map(x => x.name))}'s 'Spaw' Day!`;
                await this.createSms({
                    parentId: appointment.customer.id,
                    content,
                    phone: contact.phone,
                    secondaryName: contact.isSecondary ? contact.name : undefined
                });
            }
        };
    }
}