import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { DateTime } from "luxon";
import { GroomerRepository } from "@marathon/client-side/repositories/GroomerRepository";
import { Appointment } from "@marathon/common/entities/Appointment";
import { Pet } from "@marathon/common/entities/Pet";
import { Groomer } from "@marathon/common/entities/Groomer";

interface HoldAppointmentContentProps {
    appointment: Appointment,
    pets: Pet[]
}

export const HoldAppointmentContent = ({ appointment, pets }: HoldAppointmentContentProps) => {
    const [groomer, setGroomer] = useState<Groomer | null>(null);

    useEffect(() => {
        let isMounted = true;
        const fetchGroomer = async () => {
            const temp = await GroomerRepository.current.getById(appointment.groomer.id);
            if (isMounted) {
                setGroomer(temp);
            }
        };
        fetchGroomer();
        return () => {
            isMounted = false;
        };
    }, [appointment.groomer.id]);

    return (
        <>
            <Box display="flex" flexDirection="column" p={2} alignItems="center">
                <Box>
                    Appointment For:
                </Box>
                <Box fontWeight={400} fontSize={18}>
                    <b>{pets?.map(x => x.name).join(" & ")}</b>
                </Box>
            </Box>
            <Box p={2}>
                <Box>
                    With Pet Stylist
                </Box>
                <Box fontWeight={400} fontSize={18}>
                    <b>{groomer?.obfuscatedName()}</b>
                </Box>
            </Box>
            <Box p={2} mb={3}>
                <Box>
                    Date / Time
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center" fontWeight={400} fontSize={18}>
                    <b>{DateTime.fromJSDate(appointment.start_time).toFormat("cccc MMMM dd")}</b>
                    <b>Arrival {Appointment.getArrivalTimeWithLabel(appointment)}</b>
                </Box>
            </Box>
        </>
    );
};