import { useState } from "react";
import { Box } from "@mui/material";
import isMobileViewport from "@marathon/web/hooks/isMobileViewport";

export const ServiceDescription = ({ description }: { description: string }) => {
    const isMobile = isMobileViewport();
    const [expanded, setExpanded] = useState(false);

    const numberOfCharacters = isMobile ? 110 : 190;

    const handleToggleExpand = () => {
        setExpanded(!expanded);
    };

    const truncatedText = (fullText: string, maxLength: number) => {
        if (fullText.length <= maxLength)
            return fullText;

        const truncated = fullText.slice(0, maxLength);
        const lastSpaceIndex = truncated.lastIndexOf(" ");
        return truncated.slice(0, lastSpaceIndex);
    };

    return (
        <Box className={`service-description-text-content ${expanded ? "service-description-expanded" : ""}`}>
            {expanded ? description : truncatedText(description, numberOfCharacters)}
            {description.length > numberOfCharacters &&
                <span className="service-description-show-more" onClick={handleToggleExpand}>
                    {expanded ? "less" : "more"}
                </span>}
        </Box>
    );
};