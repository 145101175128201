import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import firebaseApp from "@marathon/web/firebaseApp";
import { CustomLuxonAdapter } from "@marathon/web/components/CustomLuxonAdapter";
import Navigations from "./Navigations";
import { OnlineBookingProvider } from "./onlineBooking/OnlineBookingProvider";
import "fonts/Plain/plain566.otf";
import "styles/all.scss";

console.log("Initialized firebase", firebaseApp);

const App = () => {
    return (
        <Router>
            <ThemeProvider theme={createTheme({
                palette: { primary: { main: "#0066FF" } },
                typography: {
                    fontFamily: "Plain",
                    button: { fontFamily: "Plain Medium" },
                }
            })}>
                <LocalizationProvider dateAdapter={CustomLuxonAdapter}>
                    <div id="barkbus-container-public">
                        <OnlineBookingProvider>
                            <Navigations />
                        </OnlineBookingProvider>
                    </div>
                </LocalizationProvider>
            </ThemeProvider>
        </Router>
    );
};

export default App;
