import { SerializableTimestamp } from "@marathon/common/api/SerializableTimestamp";
import { isObject } from "@marathon/common/helpers/objectHelper";
import { Timestamp } from "./Timestamp";

export const timestampsToDates = (object: unknown) => {

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const resolveValue = (value: any, key: string | number, object: any) => {
        if (isObject(value) && "_seconds" in value && "_nanoseconds" in value) {
            object[key] = toDate(value);
        } else if (Array.isArray(value) || isObject(value)) {
            go(value);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const go = (object: any) => {
        if (Array.isArray(object)) {
            object.forEach((value, i, array) => resolveValue(value, i, array));
        } else if (isObject(object)) {
            Object.keys(object).forEach(key => resolveValue(object[key], key, object));
        }
    };

    go(object);
};

export const datesToTimestamps = (object: unknown) => {

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const resolveValue = (value: any, key: string | number, object: any) => {
        if (value instanceof Date) {
            object[key] = toTimestamp(value);
        } else if (Array.isArray(value) || isObject(value)) {
            go(value);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const go = (object: any) => {
        if (Array.isArray(object)) {
            object.forEach((value, i, array) => resolveValue(value, i, array));
        } else if (isObject(object)) {
            Object.keys(object).forEach(key => resolveValue(object[key], key, object));
        }
    };

    go(object);
};

const toDate = (value: SerializableTimestamp) => {
    const timestamp = new Timestamp(value._seconds, value._nanoseconds);
    return timestamp.toDate();
};

const toTimestamp = (value: Date): SerializableTimestamp => {
    const timestamp = Timestamp.fromDate(value);
    return {
        _seconds: timestamp.seconds,
        _nanoseconds: timestamp.nanoseconds
    };
};