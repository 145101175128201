import { QueryFilter, OrderBy } from "@marathon/common/api/QueryFilter";
import { UpdateDataInternal } from "@marathon/common/utilities/TypeUtils";
import { CollectionPaths } from "@marathon/common/entities/base/CollectionPaths";

export const INJECTED_FIRESTORE_SERVICE_TOKEN = "IFirestoreService";

export interface DocSnapshot {
    id: string
}

export interface DocResult<IEntity> {
    id: string,
    data: IEntity,
    parentId?: string
    baseCollection?: string,
    docSnapshot?: DocSnapshot
}

export interface SearchParams<Entity> {
    filters?: QueryFilter<Entity>[],
    orders?: OrderBy<Entity>[],
    limit?: number,
    afterDocument?: DocSnapshot
}

export interface IFirestoreService<Entity> {
    collectionPath: CollectionPaths | undefined;
    parentCollectionPath: CollectionPaths | undefined;
    getById(id: string, parentId?: string): Promise<DocResult<Entity> | null>;
    search(searchParams?: SearchParams<Entity>, parentId?: string): Promise<DocResult<Entity>[]>;
    searchAcross(searchParams: SearchParams<Entity>): Promise<DocResult<Entity>[]>;
    create(data: Entity, parentId?: string, customId?: string): Promise<string>;
    update(id: string, data: UpdateDataInternal<Entity>, parentId?: string): Promise<void>;
    hardDelete(id: string, parentId?: string): Promise<void>;
    onQuerySnapshot({ filters, orders }: SearchParams<Entity>, callback: (data: DocResult<Entity>[]) => void, parentId?: string, onFinish?: () => void): () => void;
    onDocumentSnapshot(id: string, callback: (data: DocResult<Entity>) => void, parentId?: string): () => void;
    searchInBatchByIds(ids: string[]): Promise<DocResult<Entity>[]>;
    searchInBatchByField(field: string, values: string[], useCollectionGroup: boolean): Promise<DocResult<Entity>[]>;
}

export function getLastDocument(docs: DocResult<unknown>[]) {
    return docs.length ? docs[docs.length - 1].docSnapshot : undefined;
}