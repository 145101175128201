import { CreditCard } from "@marathon/common/entities/CreditCard";
import "./CreditCardDetails.scss";

export const CreditCardDetails = ({ creditCard }: { creditCard: CreditCard }) => {
    return (
        <>
            {creditCard.toFormattedStringLine1()}
            <br />
            <span className="expiration-date">{creditCard.toFormattedStringLine2()}</span>
        </>
    );
};