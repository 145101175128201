import { PricingPreset, PricingPresetData } from "@marathon/common/entities/PricingPreset";
import { DocResult, INJECTED_FIRESTORE_SERVICE_TOKEN } from "./IFirestoreService";
import type { IFirestoreService } from "./IFirestoreService";
import { container, inject, singleton } from "tsyringe";
import { CollectionPaths } from "@marathon/common/entities/base/CollectionPaths";

const mapEntity = function (snapshot: DocResult<PricingPresetData>) {
    return new PricingPreset(snapshot.id, snapshot.data);
};

@singleton()
export class PricingPresetRepository {
    private firestoreService: IFirestoreService<PricingPresetData>;
    constructor(@inject(INJECTED_FIRESTORE_SERVICE_TOKEN) injectedService: IFirestoreService<PricingPresetData>) {
        injectedService.collectionPath = CollectionPaths.PricingPresets;
        this.firestoreService = injectedService;
    }
    static get current() {
        return container.resolve(PricingPresetRepository);
    }
    async getById(id: string) {
        const doc = await this.firestoreService.getById(id);
        return doc ? mapEntity(doc) : null;
    }
    async search() {
        const docs = await this.firestoreService.search();
        return docs.map(x => mapEntity(x));
    }
}