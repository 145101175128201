import { Box } from "@mui/material";
import { getPetAgeText } from "@marathon/common/helpers/timeHelper";
import useStyles from "@marathon/web/components/onlineBooking/styles";
import { OnlineBookingContentWrapper } from "../OnlineBookingContentWrapper";
import { TitleWrapper, ContentWrapper } from "../shared/CommonWrappers";
import { Breed } from "@marathon/common/entities/Breed";
import { Pet } from "@marathon/common/entities/Pet";

interface PetInfoProps {
    pet: Pet,
    breeds: Breed[],
}

const PetInfo = ({ pet, breeds }: PetInfoProps) => {

    const handleButtonClick = (actionType: string) => () => {
        if (actionType === "call") {
            window.location.href = "tel:+18007429255";
        } else if (actionType === "text") {
            window.location.href = "sms:+18007429255&body=Hi! I'd like to learn more about Barkbus mobile grooming";
        }
    };

    const petAge = pet.getAge();
    const classes = useStyles();

    return (
        <OnlineBookingContentWrapper>
            <TitleWrapper title="Dog Profile" />
            <ContentWrapper style={{ fontFamily: "Plain Bold" }}>
                <Box sx={{ backgroundColor: "#F7F7F7", borderRadius: "10px", p: "16px" }}>
                    <h1 style={{ fontSize: "25px", margin: 0 }}>{pet.name}</h1>
                    <Box sx={{ mt: 2 }}>{Pet.getBreedName(pet.breed_id, breeds)}</Box>
                    <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
                        <Box>{pet.weight ?? 0}lb,</Box>
                        {petAge ? <Box>&nbsp;{getPetAgeText(petAge)}</Box> : ""}
                    </Box>
                    {pet.profile_photo_url &&
                        <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
                            <img src={pet.profile_photo_url} alt="pet" style={{ width: "122px", height: "148px", borderRadius: "12px" }} />
                        </Box>}
                </Box>
                <Box mt={4}>
                    <span className={classes.link} onClick={handleButtonClick("call")}>Call</span> or <span className={classes.link} onClick={handleButtonClick("text")}>text</span> us to update this profile
                </Box>
            </ContentWrapper>
        </OnlineBookingContentWrapper>
    );
};

export default PetInfo;
