import isMobileViewport from "@marathon/web/hooks/isMobileViewport";

export default function LineDivider({ marginBottom = 0, marginTop = 0 }: { marginBottom?: number, marginTop?: number }) {
    const isMobile = isMobileViewport();

    return (
        <>
            {!isMobile &&
                <div
                    className="line-divider"
                    style={{ marginTop, marginBottom }}
                >
                </div>}
        </>
    );
}
