import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress";

interface LoadingProps extends CircularProgressProps {
    marginTop?: number;
}

export const Loading = ({ marginTop = 150, ...circularProgressProps }: LoadingProps) => {
    return (
        <div style={{ width: "100%", textAlign: "center", marginTop }}>
            <CircularProgress {...circularProgressProps} />
        </div>
    );
};