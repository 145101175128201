import { TimeZone } from "../helpers/timeZoneHelper";

interface AiIntegration {
    is_chatbot_enabled_for_customers_triage?: true,
    is_voice_chatbot_enabled_for_customers_triage?: true,
    live_agent_phone?: string,
    is_chatbot_enabled_for_leads_triage?: true,
    is_enabled_for_lapsed_customer_reminders?: true,
    is_enabled_for_lapsed_lead_reminders?: true,
    is_enabled_for_automated_hunting?: true,
    is_enabled_for_restricted_automated_hunting?: true
    is_chatbot_enabled_for_groomers?: true,
}

interface AlgoliaConfiguration {
    app_id: string,
    api_key: string,
    customers_index_refresh_key: number,
    groomers_index_refresh_key: number,
}

interface KlaviyoConfiguration {
    api_key: string,
    newsletter_list_id: string
}

interface PostGridConfiguration {
    api_key: string,
    base_url: string,
    from_company_name: string,
    from_address_line_1: string,
    from_city: string,
    from_province_or_state: string,
    from_postal_or_zip: string
}

interface SquareConfiguration {
    access_token: string
}

interface TwilioConfiguration {
    account_sid: string,
    api_key: string,
    api_secret: string,
    twiml_app_sid: string
    auth_token?: string
}

interface AircallConfiguration {
    api_id: string,
    api_token: string
}

interface AutomatedHuntingConfiguration {
    is_enabled: boolean,
    lapsed_for_days: number,
    maximum_lapsed_days: number,
    maximum_distance_miles: number,
    day_range: number,
    no_activity_for_days: number
}

interface DriveTimesScheduler {
    items: DriveTimesSchedulerItem[]
}

interface DriveTimesSchedulerItem {
    last_ran_date: Date,
    ran_successfully: boolean
    time_zone: TimeZone
}

interface FilesConfiguration {
    vcards: {
        hub_id: string,
        url: string,
        media_type: string
    }[]
}

interface GroomerTrackingConfiguration {
    appointment_duration_warning_minutes: number,
    appointment_start_late_warning_minutes: number,
    start_day_late_warning_minutes: number
}

interface MobileServiceFeeConfiguration {
    is_enabled: boolean,
    description: string,
    duration: number,
    price: number,
    old_price?: {
        created_at_to: Date,
        valid_to: Date,
        value: number
    }
}

interface GoogleMapsConfiguration {
    api_key: string
}

interface OpenAiConfiguration {
    api_key: string,
    organization: string,
    common_model: string,
    transcription_model: string
}

interface FleetioConfiguration {
    account_token: string,
    authorization: string
}

interface VapiConfiguration {
    secret: string,
    phone_number: string
}

interface AddEventConfiguration {
    license: string
}

interface GoogleTaskConfiguration {
    callback_api_key: string
}

interface BouncieConfiguration {
    webhook_api_key: string
}

interface PushNotificationsConfiguration {
    is_enabled: boolean
}

export enum ConfigurationDocument {
    AiIntegration = "ai_integration",
    Algolia = "algolia",
    Klaviyo = "klaviyo",
    PostGrid = "postgrid",
    Square = "square",
    Twilio = "twilio",
    Aircall = "aircall",
    GoogleMaps = "google_maps",
    OpenAi = "openai",
    Fleetio = "fleetio",
    Vapi = "vapi",
    AddEvent = "add_event",
    Bouncie = "bouncie",
    GoogleTask = "google_task",
    AutomatedHunting = "automated_hunting",
    DriveTimesScheduler = "drive_times_scheduler",
    Files = "files",
    GroomerTracking = "groomer_tracking",
    MobileServiceFee = "mobile_service_fee",
    RestrictedAutomatedHunting = "restricted_automated_hunting",
    PushNotifications = "push_notifications"
}

export type {
    AiIntegration,
    AlgoliaConfiguration,
    KlaviyoConfiguration,
    PostGridConfiguration,
    SquareConfiguration,
    TwilioConfiguration,
    AircallConfiguration,
    GoogleMapsConfiguration,
    OpenAiConfiguration,
    FleetioConfiguration,
    VapiConfiguration,
    AddEventConfiguration,
    BouncieConfiguration,
    GoogleTaskConfiguration,
    AutomatedHuntingConfiguration,
    DriveTimesScheduler,
    DriveTimesSchedulerItem,
    FilesConfiguration,
    GroomerTrackingConfiguration,
    MobileServiceFeeConfiguration,
    PushNotificationsConfiguration
};