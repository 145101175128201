import { Box, Typography } from "@mui/material";
import { AppointmentOrOccurrence } from "@marathon/common/entities/Occurrence";
import useStyles from "@marathon/web/components/onlineBooking/styles";
import { Button } from "@marathon/web/components/Button";
import { OnlineBookingContentWrapper } from "../OnlineBookingContentWrapper";
import { TitleWrapper, ContentWrapper } from "./CommonWrappers";

interface CancelAppointmentModalProps {
    appointment: AppointmentOrOccurrence,
    isRemovingAppointment: boolean,
    onCancelAppointment: (appointment: AppointmentOrOccurrence) => Promise<void>
    goBack: () => void
}

export const cancelOrUpdateRecurrenceMessage = (
    <Box style={{ color: "#191919" }} mt={1} textAlign="left">
        <Typography>
            This appointment is part of a recurring series.
            You can modify this individual appointment,
            but if you would like to change the entire
            series, please&nbsp;
            <a href="tel:+18007429255" style={{ textDecoration: "underline", color: "inherit" }}>Call</a>
            <span> or </span>
            <a href="sms:+18007429255&body=Hi! I'd like to modify an appointment" style={{ textDecoration: "underline", color: "inherit" }}>text</a>
            <span> us to modify this appointment.</span>
        </Typography>
    </Box>
);

const CancelAppointmentModal = ({
    appointment,
    isRemovingAppointment,
    onCancelAppointment,
    goBack
}: CancelAppointmentModalProps) => {
    const classes = useStyles();

    const isRecurrence = !!appointment.occurrenceIndex || appointment.occurrenceIndex === 0;

    return (
        <OnlineBookingContentWrapper>
            <TitleWrapper title="Cancel Appointment" />
            <ContentWrapper>
                {isRecurrence && cancelOrUpdateRecurrenceMessage}
                <Box mt={2}>
                    <Button
                        variant="outlined"
                        className={classes.buttonSecondary}
                        onClick={() => onCancelAppointment(appointment)}
                        showSpinner={isRemovingAppointment}
                        disableElevation>
                        Cancel this appointment
                    </Button>
                    <Button
                        variant="outlined"
                        className={classes.buttonSecondary}
                        onClick={() => goBack()}
                        disableElevation>
                        Back
                    </Button>
                </Box>
            </ContentWrapper>
        </OnlineBookingContentWrapper>
    );
};

export default CancelAppointmentModal;

