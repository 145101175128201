import { GoogleMap, Marker } from "@react-google-maps/api";
import { GoogleMapsLoader } from "@marathon/web/components/GoogleMapsLoader";

const COORDS_LA = { lat: 34.052235, lng: -118.243683 };

interface Props {
  coords: { lat: number; lng: number; } | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  markerLabel: any,
  height: number
}

const Map = ({ coords, markerLabel, height }: Props) => {
  return (
    <GoogleMapsLoader>
      <GoogleMap
        mapContainerStyle={{
          height: `${height}px`,
          width: "min(100%,768px)",
        }}
        center={coords ?? COORDS_LA}
        zoom={16}
        options={{ disableDefaultUI: true }}
      >
        <Marker
          position={coords ?? COORDS_LA}
          label={markerLabel}
        />
      </GoogleMap>
    </GoogleMapsLoader>
  );
};

export default Map;