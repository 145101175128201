import { OnlineBookingContentWrapper } from "../OnlineBookingContentWrapper";
import { TitleWrapper } from "../shared/CommonWrappers";
import { Box } from "@mui/material";
import AddressForm, { AddressFormProps } from "../shared/AddressForm";
import useStyles from "@marathon/web/components/onlineBooking/styles";
import { Button } from "@marathon/web/components/Button";
import { Customer } from "@marathon/common/entities/Customer";

interface ReturningEditAddressFormProps extends AddressFormProps {
    customer: Customer,
    onRemoveAddress: (addressId: string) => void
    isRemoving: boolean
}

const ReturningEditAddressForm = ({
    address,
    customer,
    handleChange,
    onRemoveAddress,
    onAddressAutocompleteChange,
    onNext,
    isLoading,
    isGeocoding,
    isRemoving
}: ReturningEditAddressFormProps) => {
    const classes = useStyles();

    const isCustomerPrimaryAddress = !("id" in address);

    const renderEditAddressButtons = () => {
        return (
            <>
                <Button
                    disabled={!address.drive_time}
                    onClick={onNext}
                    variant="contained"
                    className={classes.button}
                    disableElevation
                    showSpinner={isLoading || isGeocoding}
                >
                    Change Address
                </Button>
                {!isCustomerPrimaryAddress &&
                    <Button
                        disabled={!address.drive_time}
                        onClick={() => {
                            if (address.id)
                                onRemoveAddress(address.id);
                        }}
                        variant="outlined"
                        className={classes.buttonSecondary}
                        disableElevation
                        showSpinner={isRemoving || isGeocoding}
                    >
                        Remove Address
                    </Button>}
            </>
        );
    };

    return (
        <OnlineBookingContentWrapper>
            <Box display="flex" flexDirection="column">
                <TitleWrapper title="Change Address" fullWidth />
                <AddressForm
                    returningCustomer={customer}
                    address={address}
                    handleChange={handleChange}
                    onAddressAutocompleteChange={onAddressAutocompleteChange}
                    onNext={onNext}
                    isLoading={isLoading}
                    isGeocoding={isGeocoding}
                    isSecondaryAddress={!isCustomerPrimaryAddress}
                    renderReturningAddressButtons={renderEditAddressButtons}
                />
            </Box>
        </OnlineBookingContentWrapper>
    );
};

export default ReturningEditAddressForm;