import { formatShortDate, formatTime } from "@marathon/common/helpers/timeFormatHelper";
import steps from "@marathon/common/utilities/OnlineBookingSteps";
import { GetTrackDataProps, TrackData } from "./useTracker";
import { MixPanelEvent } from "@marathon/client-side/utilities/MixPanel";

const getTrackDataForGetStartedStep = ({ currentStep }: GetTrackDataProps) => {
    const trackData: TrackData = { properties: { new_user: true } };

    switch (currentStep.substepNumber) {
        case undefined:
            trackData.eventName = MixPanelEvent.ob_new_getStarted;
            break;
        case steps.newLead.getStartedSubsteps.foundExisting:
            trackData.eventName = MixPanelEvent.ob_new_accountExist;
            break;
        default:
            return null;
    }

    return trackData;
};

const getTrackDataForAddressInputStep = ({ currentStep, info }: GetTrackDataProps) => {
    const trackData: TrackData = { properties: { new_user: true } };

    switch (currentStep.substepNumber) {
        case undefined:
            trackData.eventName = MixPanelEvent.ob_new_location;
            trackData.properties = {
                ...trackData.properties,
                authentication_type: info?.customer?.authenticationType
            };
            break;
        case steps.newLead.addressInputSubsteps.thankYou:
            trackData.eventName = MixPanelEvent.ob_new_exceedServiceArea_waitList;
            trackData.properties = {
                ...trackData.properties,
                address: info?.customer?.address
            };
            break;
        default:
            return null;
    }

    return trackData;
};

const getTrackDataForPetDetailsStep = ({ currentStep, info }: GetTrackDataProps) => {
    const trackData: TrackData = {
        eventName: MixPanelEvent.ob_new_dogInfo,
        properties: { new_user: true }
    };

    if ((currentStep.substepNumber ?? 1) === 1) {
        trackData.properties = {
            ...trackData.properties,
            address: info?.customer?.address
        };
    }
    else if (currentStep.substepNumber !== undefined) {
        let lastDog = info?.dogs && info.dogs[currentStep.substepNumber - 2];
        trackData.properties = {
            ...trackData.properties,
            dog_breed: lastDog?.breedName,
            dog_age: lastDog?.age,
            dog_gender: lastDog?.gender,
            dog_weight: lastDog?.weight
        };
    }

    return trackData;
};

const getTrackDataForPhoneConfirmStep = ({ info }: GetTrackDataProps) => {
    const trackData: TrackData = {
        eventName: MixPanelEvent.ob_new_phoneConfirm,
        properties: {
            name: info?.customer?.name,
            email: info?.customer?.email,
            dog_quantity: info?.customer?.petQuantity
        }
    };
    return trackData;
};

const getTrackDataForAcknowledgementsStep = ({ info }: GetTrackDataProps) => {
    let lastDog = info?.dogs && info.dogs[info.dogs.length - 1];

    const trackData: TrackData = {
        eventName: MixPanelEvent.ob_new_prePricing,
        properties: {
            new_user: true,
            dog_breed: lastDog?.breedName,
            dog_age: lastDog?.age,
            dog_gender: lastDog?.gender,
            dog_weight: lastDog?.weight
        }
    };

    return trackData;
};

const getTrackDataForPricingStep = () => {
    const trackData: TrackData = {
        eventName: MixPanelEvent.ob_new_pricing,
        properties: { new_user: true }
    };

    return trackData;
};

const getTrackDataForSuggestionsStep = ({ info }: GetTrackDataProps) => {
    const trackData: TrackData = {
        eventName: MixPanelEvent.ob_new_dates,
        properties: {
            new_user: true,
            groom_price: info?.servicePrice,
            service: info?.serviceNames
        }
    };

    return trackData;
};

const getTrackDataForConfirmationStep = ({ info }: GetTrackDataProps) => {
    const trackData: TrackData = {
        eventName: MixPanelEvent.ob_new_confirm,
        properties: {
            new_user: true,
            appointment_time: (info?.appointment?.arrivalTime && info.appointment.timeZone) ? formatTime(info.appointment.arrivalTime, info.appointment.timeZone) : "",
            appointment_date: (info?.appointment?.arrivalTime && info.appointment.timeZone) ? formatShortDate(info.appointment.arrivalTime, info.appointment.timeZone) : ""
        }
    };

    return trackData;
};

const getTrackDataForSafetyStep = () => {
    const trackData: TrackData = {
        eventName: MixPanelEvent.ob_new_safety,
        properties: { new_user: true }
    };

    return trackData;
};

const getTrackDataForThankYouStep = () => {
    const trackData: TrackData = {
        eventName: MixPanelEvent.ob_new_finalStep,
        properties: {
            new_user: true,
            safety_accepted: true
        }
    };

    return trackData;
};

export const getTrackDataForNewCustomerFlow = (trackDataParams: GetTrackDataProps) => {
    switch (trackDataParams.currentStep.stepNumber) {
        case steps.newLead.getStarted:
            return getTrackDataForGetStartedStep(trackDataParams);
        case steps.newLead.addressInput:
            return getTrackDataForAddressInputStep(trackDataParams);
        case steps.newLead.petDetails:
            return getTrackDataForPetDetailsStep(trackDataParams);
        case steps.newLead.codeVerification:
            return getTrackDataForPhoneConfirmStep(trackDataParams);
        case steps.newLead.acknowledgements:
            return getTrackDataForAcknowledgementsStep(trackDataParams);
        case steps.newLead.pricing:
            return getTrackDataForPricingStep();
        case steps.newLead.suggestions:
            return getTrackDataForSuggestionsStep(trackDataParams);
        case steps.newLead.confirmation:
            return getTrackDataForConfirmationStep(trackDataParams);
        case steps.newLead.safety:
            return getTrackDataForSafetyStep();
        case steps.newLead.thankYou:
            return getTrackDataForThankYouStep();
        default:
            return null;
    }
};