import { Button } from "@marathon/web/components/Button";
import useStyles from "@marathon/web/components/onlineBooking/styles";
import { OnlineBookingContentWrapper } from "../OnlineBookingContentWrapper";
import { TitleWrapper, ContentWrapper } from "../shared/CommonWrappers";
import { Customer } from "@marathon/common/entities/Customer";
import { Box } from "@mui/material";
import { Pet } from "@marathon/common/entities/Pet";
import { SecondaryAddress } from "@marathon/common/entities/SecondaryAddress";
import { CustomerAddressData } from "@marathon/common/entities/Customer";
import { CreditCard } from "@marathon/common/entities/CreditCard";
import { CreditCardStatus } from "@marathon/common/entities/CreditCard";
import Skeleton from "react-loading-skeleton";
import { CreditCardDetails } from "@marathon/web/components/paymentMethods/CreditCardDetails";

interface ManageAccountProps {
    pets: Pet[],
    cards: CreditCard[]
    addresses: (SecondaryAddress | CustomerAddressData)[],
    onNewAddressClick: () => void,
    onChangeAddressClick: (address: SecondaryAddress | CustomerAddressData) => void,
    onNewPetClick: () => void,
    onEditPetClick: (pet: Pet) => void,
    onCreatePaymentMethodClick: () => void,
    onNotificationSettingsClick: () => void
    onEditCardClick: (card: CreditCard) => void,
    isLoadingCards: boolean
}

const ManageAccount = ({
    addresses,
    cards,
    pets,
    onCreatePaymentMethodClick,
    onNewAddressClick,
    onNewPetClick,
    onChangeAddressClick,
    onEditPetClick,
    onEditCardClick,
    onNotificationSettingsClick,
    isLoadingCards
}: ManageAccountProps) => {
    const classes = useStyles();

    const renderCardsSkeleton = () => {
        return (
            <Skeleton count={3} style={{ marginTop: 12, height: 18 }} />
        );
    };

    return (
        <OnlineBookingContentWrapper>
            <TitleWrapper title="My Account" />
            <ContentWrapper>
                <Box sx={{ backgroundColor: "#F7F7F7", borderRadius: "10px", p: "16px" }}>
                    <Box sx={{ fontFamily: "Plain Bold" }}>Addresses:</Box>
                    {addresses.map(x => (
                        <Box onClick={() => onChangeAddressClick(x)} key={x.place_id} mt={1} className={classes.link}>{Customer.formattedAddress(x)}</Box>
                    ))}
                </Box>
                <Button
                    style={{ marginTop: "8px" }}
                    variant="outlined"
                    color="secondary"
                    className={classes.buttonSecondary}
                    onClick={onNewAddressClick}
                    disableElevation>
                    Add New Address
                </Button>
                <Box sx={{ backgroundColor: "#F7F7F7", borderRadius: "10px", p: "16px", mt: "20px", fontFamily: "Plain Bold" }}>
                    <Box>My Dogs:</Box>
                    {pets.map(x => (
                        <Box onClick={() => onEditPetClick(x)} mt={2} key={x.id} className={classes.link}>{x.name}</Box>
                    ))}
                </Box>
                <Button
                    style={{ marginTop: "8px" }}
                    variant="outlined"
                    color="secondary"
                    className={classes.buttonSecondary}
                    onClick={onNewPetClick}
                    disableElevation>
                    Add a Dog
                </Button>
                <Box sx={{ backgroundColor: "#F7F7F7", borderRadius: "10px", p: "16px", mt: "20px" }}>
                    <Box sx={{ fontFamily: "Plain Bold" }}>Payment Methods:</Box>
                    {isLoadingCards && renderCardsSkeleton()}
                    {!isLoadingCards && cards.filter(x => x.status === CreditCardStatus.Active).map(x => (
                        <Box onClick={() => onEditCardClick(x)} mt={2} style={{ fontFamily: "Plain Bold", cursor: "pointer", textDecoration: "underline" }} key={x.id}>
                            <CreditCardDetails creditCard={x} />
                        </Box>
                    ))}
                </Box>
                <Button
                    style={{ marginTop: "8px" }}
                    variant="outlined"
                    color="secondary"
                    className={classes.buttonSecondary}
                    onClick={onCreatePaymentMethodClick}
                    disableElevation>
                    Create Payment Method
                </Button>
                <Button
                    variant="outlined"
                    className={classes.buttonSecondary}
                    onClick={onNotificationSettingsClick}
                    disableElevation>
                    Notification Settings
                </Button>
            </ContentWrapper>
        </OnlineBookingContentWrapper>
    );
};

export default ManageAccount;