
import { CSSProperties } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";

const MOBILE_VIEWPORT = "@media (max-width: 600px)";

const baseButton = {
    "&:hover": {
        backgroundColor: "#0066FF",
        boxShadow: "none",
    },
    "&:active": {
        backgroundColor: "#0066FF",
        boxShadow: "none",
    },
    [MOBILE_VIEWPORT]: {
        width: "100%",
        boxShadow: "none",
    },
    paddingLeft: 30,
    paddingRight: 30,
    marginTop: 20,
    background: "#0066FF",
    borderRadius: 6,
    color: "#fff",
    width: "100%",
    height: 50,
    fontWeight: 600,
    boxShadow: "none",
    textTransform: "none",
    fontSize: 17,
} as CSSProperties;

const baseRadioButton = {
    backgroundColor: "#FFFFFF",
    width: "100%",
    height: 60,
    color: "#737373",
    borderRadius: 5,
    fontSize: 16,
    margin: 0,
    border: "1px solid #D7D7D7",
    [MOBILE_VIEWPORT]: {
        width: "100%",
    },
    "&:hover": {
        border: "1px solid #333333",
    },
    "&:active": {
        border: "2px solid #0066FF",
    },
    "&:focus": {
        border: "2px solid #0066FF",
    }
} as CSSProperties;

const baseAuxiliaryButton = {
    fontSize: 15,
    textDecoration: "underline",
    cursor: "pointer",
    float: "left"
} as CSSProperties;

const useStyles = makeStyles((theme) => ({
    servicePrice: {
        fontSize: 15,
        fontWeight: "normal",
        position: "absolute",
        right: 15,
        marginTop: 10,
        color: "#737373",
    },
    servicePriceGroomer: {
        marginTop: 0,
    },
    selectedServicePrice: {
        fontWeight: "normal",
        color: "#191919",
    },
    serviceDescriptionText: {
        fontFamily: "Plain",
        lineHeight: "21px",
        fontSize: 15,
        fontWeight: "normal",
        textAlign: "left",
        marginBottom: -10,
    },
    selectedDescriptionText: {
        fontWeight: "bold",
        color: "#191919",
    },
    serviceDescriptionTextGroomerSide: {
        lineHeight: "0",
    },
    serviceCopyText: {
        fontFamily: "Plain Medium",
        lineHeight: "21px",
        fontSize: 15,
        textAlign: "left",
        fontWeight: 600,
        color: "#191919"
    },
    mostPopular: {
        lineHeight: -1,
        textAlign: "left",
        fontSize: 9,
        color: "#FFFFFF",
        marginLeft: 5,
        textDecoration: "uppercase",
        backgroundColor: "#0066FF",
        padding: "1px 3px",
        borderRadius: 1,
        position: "relative",
        top: -2,
    },
    filterControl: {
        marginLeft: 10
    },
    filterControlSelect: {
        fontWeight: 500,
        fontSize: 18,
        color: "#0066FF",
        background: "none !important"
    },
    filterControlSelectIcon: {
        display: "none"
    },
    suggestionsFilterControl: {
        fontWeight: 500,
        fontSize: 18,
        color: "#B8B8B8",
        borderColor: "#D7D7D7",
        borderRadius: 8,
        width: "100%"
    },
    filterControlPicker: {
        marginTop: 0
    },
    button: {
        ...baseButton
    },
    buttonSecondary: {
        ...baseButton,
        "&:hover": {
            backgroundColor: "#cccccc",
            boxShadow: "none",
            borderColor: "#000000"
        },
        "&:active": {
            backgroundColor: "#cccccc",
            boxShadow: "none",
            borderColor: "#000000"
        },
        backgroundColor: "#ffffff",
        color: "#000000",
        borderColor: "#000000"
    },
    bookButtonDesktop: {
        ...baseButton,
        maxWidth: 280,
        margin: 0,
        [MOBILE_VIEWPORT]: {
            display: "none",
        }
    },
    bookButtonMobile: {
        ...baseButton,
        marginTop: 10,
        marginBottom: 10,
        "@media (min-width: 601px)": {
            display: "none",
        }
    },
    cardButton: {
        ...baseButton,
        margin: "10px 0px 0px 0px !important",
    },
    cardButtonDesktop: {
        ...baseButton,
        margin: "0 !important",
        width: "267px !important",
    },
    radioButton: {
        ...baseRadioButton
    },
    selectedRadioButton: {
        ...baseRadioButton,
        color: "#191919",
        border: "2px solid #0066FF !important"
    },
    radioGrid: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        flexWrap: "wrap",
        [theme.breakpoints?.down("xs")]: {
            flexDirection: "column",
        }
    },
    textField: {
        width: "100%",
        marginTop: 16,
        marginBottom: 0,
        [MOBILE_VIEWPORT]: {
            marginTop: 10
        }
    },
    textFieldWithoutMargin: {
        width: "100%",
        marginTop: 0,
        marginBottom: 0
    },
    auxiliaryButtonWrapper: {
        width: "100%",
        marginTop: 20
    },
    auxiliaryButton: {
        ...baseAuxiliaryButton
    },
    suggestionsAuxiliaryButton: {
        ...baseAuxiliaryButton,
        [MOBILE_VIEWPORT]: {
            float: "none"
        }
    },
    listbox: {
        overflow: "auto",
        padding: "0px",
    },
    option: {
        border: "none",
        borderBottom: "1px solid #efefef",
        height: 40,
        margin: 0
    },
    popupIndicator: {
        display: "none"
    },
    appointmentCancelSecion: {
        fontSize: 14,
        color: "#A3A3A3",
        display: "flex",
        justifyContent: "flex-end"
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
    link: {
        fontWeight: "bold",
        textDecoration: "underline",
        textTransform: "none",
        color: "#000",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "transparent",
            textDecoration: "underline",
            color: "#000",
        },
        "&:active": {
            backgroundColor: "transparent",
            textDecoration: "underline",
            color: "#000",
        },
        "&:focus": {
            backgroundColor: "transparent",
            textDecoration: "underline",
            color: "#000",
        }
    }
}));

export default useStyles;
