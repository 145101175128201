import { useCallback, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { Box } from "@mui/material";
import { AppointmentRepository } from "@marathon/client-side/repositories/AppointmentRepository";
import { useParams } from "react-router-dom";
import { HoldAppointmentStatus } from "./HoldAppointmentHeader";
import { HoldAppointmentContent } from "./HoldAppointmentContent";
import { HoldAppointmentActions } from "./HoldAppointmentActions";
import { HoldAppointmentNotifications } from "./HoldAppointmentNotifications";
import CallableFunctions from "@marathon/client-side/utilities/CallableFunctions";
import { Loading } from "@marathon/web/components/Loading";
import { Appointment, AppointmentStatus } from "@marathon/common/entities/Appointment";
import { OnlineBookingHeader } from "components/onlineBooking/OnlineBookingHeader";
import { OnlineBookingContentWrapper } from "components/onlineBooking/OnlineBookingContentWrapper";
import { ContentWrapper, TitleWrapper } from "components/onlineBooking/shared/CommonWrappers";
import { Pet } from "@marathon/common/entities/Pet";
import { User } from "@marathon/common/entities/User";

interface RouteParams {
    id: string;
}

export default function HoldAppointment() {
    const routeParams = useParams<RouteParams>();
    const [appointment, setAppointment] = useState<Appointment | null>(null);
    const [pets, setPets] = useState<Pet[]>([]);
    const [isBooking, setIsBooking] = useState(false);
    const [isDeclining, setIsDeclining] = useState(false);
    const [successAccepted, setSuccessAccepted] = useState(false);
    const [successDeclined, setSuccessDeclined] = useState(false);
    const [hasError, setHasError] = useState(false);

    const getHoldAppointment = useCallback(async () => {
        const data = await CallableFunctions.current.public.getAppointmentOnHoldData(routeParams.id);
        if (!data)
            throw new Error(`Couldn't find appointment on hold (${routeParams.id})`);

        const appointmentPets = data.customerPets.filter(p => data.appointment.selected_pets.flatMap(x => x.petId).includes(p.id));
        setAppointment(data.appointment);
        setPets(appointmentPets);
    }, [routeParams.id]);

    useEffect(() => {
        getHoldAppointment();
    }, [getHoldAppointment]);

    const handleAccept = async () => {
        if (isBooking || isDeclining)
            return;

        if (!appointment)
            throw new Error("Appointment is required at this point");

        setIsBooking(true);
        try {
            await AppointmentRepository.current.update(appointment.id, { status: AppointmentStatus.scheduled }, User.systemUsers.customer);
            setAppointment(new Appointment(appointment.id, { ...appointment.toData(), status: AppointmentStatus.scheduled }));
            setSuccessAccepted(true);
        }
        catch (error) {
            Sentry.captureException(error);
            setHasError(true);
        }
        finally {
            setIsBooking(false);
        }
    };

    const handleDecline = async () => {
        if (isBooking || isDeclining)
            return;

        if (!appointment)
            throw new Error("Appointment is required at this point");

        setIsDeclining(true);
        try {
            await AppointmentRepository.current.decline(appointment.id, User.systemUsers.customer);
            setAppointment(new Appointment(appointment.id, { ...appointment.toData(), status: AppointmentStatus.declined }));
            setSuccessDeclined(true);
        }
        catch (error) {
            Sentry.captureException(error);
            setHasError(true);
        }
        finally {
            setIsDeclining(false);
        }
    };

    if (!appointment) {
        return <Loading />;
    }

    return (
        <>
            <OnlineBookingHeader />

            <OnlineBookingContentWrapper>
                <TitleWrapper title="Temporary Appointment Hold">
                    <HoldAppointmentStatus appointment={appointment} onRefresh={getHoldAppointment} />
                </TitleWrapper>
                <ContentWrapper>
                    <Box display="flex" flexDirection="column">
                        <HoldAppointmentContent appointment={appointment} pets={pets} />
                        {appointment.status === AppointmentStatus.pending &&
                            <HoldAppointmentActions onAccept={handleAccept} onDecline={handleDecline} isBooking={isBooking} isDeclining={isDeclining} />}
                        <Box display="flex" flexDirection="column" mb={3} color="#858585" alignItems="center">
                            <b>Changes? Call or Text us</b>
                            <b>(800) 742-9255</b>
                        </Box>
                        <HoldAppointmentNotifications
                            hasError={hasError}
                            successAccepted={successAccepted}
                            successDeclined={successDeclined}
                            onCloseError={() => setHasError(false)}
                            onCloseSuccessAccepted={() => setSuccessAccepted(false)}
                            onCloseSucessDeclined={() => setSuccessDeclined(false)} />
                    </Box>
                </ContentWrapper>
            </OnlineBookingContentWrapper>
        </>
    );
}