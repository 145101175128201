import { useState } from "react";
import * as React from "react";
import { Button } from "@marathon/web/components/Button";
import PhoneInput from "@marathon/web/components/PhoneInput";
import FormHelperText from "@mui/material/FormHelperText";
import useStyles from "@marathon/web/components/onlineBooking/styles";
import { OnlineBookingContentWrapper } from "../OnlineBookingContentWrapper";
import { Link } from "react-router-dom";
import { TitleWrapper, ContentWrapper, FieldsetWrapper } from "../shared/CommonWrappers";

interface OnlineBookingStep2Props {
    onNext: () => Promise<void>,
    phoneNumber: string,
    setPhoneNumber: React.Dispatch<React.SetStateAction<string>>,
    isLoading: boolean,
    showError: boolean,
    shortcutPhone?: string | null
}

export default function OnlineBookingStep2({ onNext, phoneNumber, setPhoneNumber, isLoading, showError, shortcutPhone }: OnlineBookingStep2Props) {
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState<boolean>(false);
    const classes = useStyles();

    return (
        <OnlineBookingContentWrapper>
            <TitleWrapper
                title={shortcutPhone
                    ? "Let's verify your identity"
                    : "Welcome back!"}
                subtitle={shortcutPhone
                    ? `We need to send you a code, please confirm (xxx)-xxx-${shortcutPhone.slice(-4)}`
                    : "Enter your phone number"}
            />
            <ContentWrapper>
                <FieldsetWrapper hasError={showError}>
                    {!shortcutPhone &&
                        <PhoneInput
                            autoComplete="off"
                            variant="outlined"
                            color="primary"
                            size="medium"
                            label="Phone number"
                            useLocalFormat
                            value={phoneNumber}
                            onChange={setPhoneNumber}
                            onValidate={setIsValidPhoneNumber}
                            error={showError}
                            autoFocus
                        />}
                    {showError &&
                        <FormHelperText style={{ marginTop: 20 }} className="MuiFormHelperText-root">
                            Whoops! That phone number is not linked to a valid profile
                            <br />
                            Please call or text us at <a className="phone-number" href={"tel:+18007429255"}>(800) 742-9255</a>
                            <br />
                            <Link
                                to="/online-booking-signup"
                                style={{
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                    color: "#0066FF"
                                }}>
                                Click here to create an account
                            </Link>
                        </FormHelperText>}
                    <Button
                        showSpinner={isLoading}
                        type="submit"
                        variant="contained"
                        className={classes.button}
                        onClick={onNext}
                        disabled={!shortcutPhone && !isValidPhoneNumber}
                        disableElevation>
                        {showError
                            ? "Retry"
                            : shortcutPhone ? "Confirm" : "Continue"}
                    </Button>
                </FieldsetWrapper>
            </ContentWrapper>
        </OnlineBookingContentWrapper>
    );
}