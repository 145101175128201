import { Tooltip, TooltipProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
        fontWeight: 400,
        fontSize: "15px",
        padding: "7px 7px",
        top: "20px"
    },
}));

export const CustomTooltip = (props: JSX.IntrinsicAttributes & TooltipProps) => {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
};