import { AppointmentRepository } from "./AppointmentRepository";
import DeviceStorageCache from "@marathon/client-side/utilities/DeviceStorageCache";
import CallableFunctions from "@marathon/client-side/utilities/CallableFunctions";
import { AppointmentStatus, CancellationReason, PaymentStatus, SystemCancellationReason } from "@marathon/common/entities/Appointment";
import { Recurrence, RecurrenceData } from "@marathon/common/entities/Recurrence";
import { Occurrence } from "@marathon/common/entities/Occurrence";
import { AppointmentData } from "@marathon/common/entities/Appointment";
import { TimeZone, getDateWithMergedTime } from "@marathon/common/helpers/timeZoneHelper";
import { CustomerRepository } from "./CustomerRepository";
import { AppointmentInput } from "../entities/AppointmentInput";
import { getFutureOccurrences, getOccurrenceFromIndex, getOccurrencesForDate } from "@marathon/common/helpers/rruleHelper";
import { SecondaryAddressRepository } from "./SecondaryAddressRepository";
import LocalDate from "@marathon/common/utilities/LocalDate";
import { FilterOperator, getFilter, QueryFilter } from "@marathon/common/api/QueryFilter";
import { isFirstHourService } from "@marathon/common/helpers/timeFormatHelper";
import { PetRepository } from "./PetRepository";
import { Groomer } from "@marathon/common/entities/Groomer";
import { Service } from "@marathon/common/entities/Service";
import { DriveTime } from "@marathon/common/entities/DriveTime";
import { DocResult, INJECTED_FIRESTORE_SERVICE_TOKEN } from "./IFirestoreService";
import type { IFirestoreService } from "./IFirestoreService";
import { container, inject, singleton } from "tsyringe";
import { CollectionPaths } from "@marathon/common/entities/base/CollectionPaths";
import { User } from "@marathon/common/entities/User";

const mapEntity = function (snapshot: DocResult<RecurrenceData>) {
    return new Recurrence(snapshot.id, snapshot.data);
};

const updateAudit = (data: Partial<RecurrenceData>) => {
    if (!data) return;
    data.updated_by = DeviceStorageCache.getCurrentUserName() || User.systemUsers.onlineBooking;
    data.updated_at = new Date();
};

interface RecurrenceUpdateInput {
    id: string,
    occurrenceIndex: number,
    originalRecurrence: Recurrence,
    appointmentInput: AppointmentInput,
    groomer: Groomer,
    services: Service[]
}

@singleton()
export class RecurrenceRepository {
    private firestoreService: IFirestoreService<RecurrenceData>;
    constructor(@inject(INJECTED_FIRESTORE_SERVICE_TOKEN) injectableService: IFirestoreService<RecurrenceData>) {
        injectableService.collectionPath = CollectionPaths.Recurrences;
        this.firestoreService = injectableService;
    }
    static get current() {
        return container.resolve(RecurrenceRepository);
    }
    private async searchInternal(customerId?: string, groomerId?: string, timeZone?: TimeZone) {
        const filters: QueryFilter<RecurrenceData>[] = [
            getFilter("status", FilterOperator.equal, AppointmentStatus.scheduled)
        ];

        if (customerId)
            filters.push(getFilter("customer.id", FilterOperator.equal, customerId));

        if (groomerId)
            filters.push(getFilter("groomer.id", FilterOperator.equal, groomerId));

        if (timeZone)
            filters.push(getFilter("time_zone", FilterOperator.equal, timeZone));

        const docs = await this.firestoreService.search({ filters });
        return docs.map(x => mapEntity(x));
    }
    private async addExceptionInternal(recurrence: Recurrence, exceptionIndex: number) {
        const exceptions = recurrence.exceptions
            ? [...recurrence.exceptions, exceptionIndex]
            : [exceptionIndex];
        await this.firestoreService.update(recurrence.id, { exceptions });
    }
    async getById(id: string) {
        const doc = await this.firestoreService.getById(id);
        return doc ? mapEntity(doc) : null;
    }
    async create(data: RecurrenceData) {
        if (await CallableFunctions.current.public.isNewCustomer(data.customer.id)) {
            data.is_new_recurrent_customer = true;
        }
        if (await CallableFunctions.current.public.isFirstAppointment(data.groomer.id, data.start_time, data.time_zone) && isFirstHourService(data.start_time, data.time_zone)) {
            data.show_exact_time = true;
        }
        const docId = await this.firestoreService.create(data);
        return mapEntity({ id: docId, data });
    }
    async update(id: string, data: Partial<RecurrenceData>) {
        updateAudit(data);
        await this.firestoreService.update(id, data);
    }
    async cancel(id: string, reason: CancellationReason, notifyCustomer?: boolean, collectedFee?: number, notes?: string) {
        const data: Partial<RecurrenceData> = {
            status: AppointmentStatus.cancelled,
            cancellation: {
                reason,
                customer_notified: notifyCustomer || undefined
            }
        };
        updateAudit(data);

        await this.firestoreService.update(id, data);
        await this.materializeNextCancelledOccurrence(id, reason, collectedFee, notes);
    }
    async unconfirmOccurrence(occurrence: Occurrence) {
        const toUpdate = {
            status: AppointmentStatus.unconfirmed,
            unconfirmed: {
                by: DeviceStorageCache.getNonEmptyCurrentUserName(),
                at: new Date()
            }
        };

        return await this.createExceptionFromOccurrence(occurrence, toUpdate);
    }
    async cancelOccurrence(occurrence: Occurrence, reason: CancellationReason | SystemCancellationReason, notifyCustomer: boolean, collectedFee?: number, notes?: string) {
        const toUpdate = {
            status: AppointmentStatus.cancelled,
            cancellation: {
                reason,
                customer_notified: notifyCustomer || undefined,
                collected_fee: collectedFee
            },
            payment_status: collectedFee ? PaymentStatus.paid : undefined,
            notes
        };

        return await this.createExceptionFromOccurrence(occurrence, toUpdate);
    }
    async createException(id: string, occurrenceIndex: number, toUpdate: Partial<AppointmentData>) {
        const recurrence = await this.getById(id);
        if (!recurrence)
            throw new Error(`RecurrenceId ${id} not valid`);

        const occurrence = getOccurrenceFromIndex(recurrence, occurrenceIndex);

        recurrence.validateExceptionIndex(occurrence);
        const newAppointment = await AppointmentRepository.current.create(occurrence.createAppointmentData(toUpdate));
        await this.addExceptionInternal(recurrence, occurrenceIndex);
        return newAppointment;
    }
    async createExceptionFromOccurrence(occurrence: Occurrence, toUpdate: Partial<AppointmentData>) {
        const recurrence = await this.getById(occurrence.id);
        if (!recurrence)
            throw new Error(`RecurrenceId ${occurrence.id} not valid`);

        toUpdate.updated_at = new Date();
        toUpdate.updated_by = DeviceStorageCache.getNonEmptyCurrentUserName();

        recurrence.validateExceptionIndex(occurrence);
        const newAppointment = await AppointmentRepository.current.create(occurrence.createAppointmentData(toUpdate));
        await this.addExceptionInternal(recurrence, occurrence.occurrenceIndex);
        return newAppointment;
    }
    async createExceptionFromOnlineBooking(customerOccurrence: Occurrence, toUpdate: Partial<AppointmentData>, customerRecurrences: Recurrence[]) {
        const customerRecurrence = customerRecurrences.find(x => x.id === customerOccurrence.id);
        if (!customerRecurrence)
            throw new Error(`RecurrenceId ${customerOccurrence.id} not valid`);

        const recurrence: Recurrence = new Recurrence(customerRecurrence.id, customerRecurrence);
        const occurrence: Occurrence = new Occurrence(customerOccurrence.id, customerOccurrence.occurrenceIndex, customerOccurrence);

        toUpdate.updated_at = new Date();
        toUpdate.updated_by = DeviceStorageCache.getCurrentUserName() || User.systemUsers.onlineBooking;

        recurrence.validateExceptionIndex(occurrence);
        const newAppointment = await AppointmentRepository.current.create(occurrence.createAppointmentData(toUpdate));
        await this.addExceptionInternal(recurrence, occurrence.occurrenceIndex);
        return newAppointment;
    }
    async cancelOccurrenceFromOnlineBooking(occurrence: Occurrence, reason: CancellationReason | SystemCancellationReason, customerRecurrences: Recurrence[]) {
        const toUpdate = {
            status: AppointmentStatus.cancelled,
            cancellation: { reason }
        };
        return await this.createExceptionFromOnlineBooking(occurrence, toUpdate, customerRecurrences);
    }
    async materializeNextCancelledOccurrence(id: string, cancellationReason: CancellationReason, collectedFee?: number, notes?: string) {
        const recurrence = await this.getById(id);
        if (!recurrence)
            throw new Error(`RecurrenceId ${id} not valid`);

        const nextOccurrence = getFutureOccurrences(recurrence, 1).at(0);
        if (!nextOccurrence)
            throw new Error(`Next occurrence not found for recurrence ${id}`);

        const data = {
            ...nextOccurrence.toAppointmentData(),
            status: AppointmentStatus.cancelled,
            cancellation: { reason: cancellationReason, collected_fee: collectedFee },
            payment_status: collectedFee ? PaymentStatus.paid : undefined,
            notes: notes
        };

        await this.createExceptionFromOccurrence(nextOccurrence, data);
    }
    async search() {
        return await this.searchInternal();
    }
    async searchByHub(hubId: string) {
        const docs = await this.firestoreService.search({
            filters: [
                getFilter("status", FilterOperator.equal, AppointmentStatus.scheduled),
                getFilter("groomer.hub_id", FilterOperator.equal, hubId)
            ]
        });
        return docs.map(x => mapEntity(x));
    }
    async searchForTimeZone(timeZone: TimeZone) {
        return await this.searchInternal(undefined, undefined, timeZone);
    }
    async searchForCustomer(customerId: string) {
        return await this.searchInternal(customerId, undefined, undefined);
    }
    async searchForGroomer(groomerId: string) {
        return await this.searchInternal(undefined, groomerId, undefined);
    }
    async searchOccurrencesForDayAndGroomer(date: LocalDate, groomerId: string) {
        const recurrences = await this.searchForGroomer(groomerId);
        return getOccurrencesForDate(recurrences, date);
    }
    async searchFromOnlineBooking() {
        return await CallableFunctions.current.public.getCustomerRecurrences();
    }
    listenFromCalendar(timeZone: TimeZone | undefined, callback: (recurrences: Recurrence[]) => void): () => void {
        const filters: QueryFilter<RecurrenceData>[] = [
            getFilter("status", FilterOperator.equal, AppointmentStatus.scheduled)
        ];

        if (timeZone) {
            filters.push(
                getFilter("time_zone", FilterOperator.equal, timeZone)
            );
        }

        return this.firestoreService.onQuerySnapshot({ filters }, docs => {
            callback(docs.map(x => mapEntity(x)));
        });
    }
    listenChangesForCustomer(customerId: string, callback: (recurrences: Recurrence[]) => void): () => void {
        return this.firestoreService.onQuerySnapshot({
            filters: [
                getFilter("status", FilterOperator.equal, AppointmentStatus.scheduled),
                getFilter("customer.id", FilterOperator.equal, customerId)
            ]
        }, docs => {
            callback(docs.map(x => mapEntity(x)));
        });
    }
    listenChangesForGroomer(groomerId: string, callback: (recurrences: Recurrence[]) => void): () => void {
        return this.firestoreService.onQuerySnapshot({
            filters: [
                getFilter("status", FilterOperator.equal, AppointmentStatus.scheduled),
                getFilter("groomer.id", FilterOperator.equal, groomerId)
            ]
        }, docs => {
            callback(docs.map(x => mapEntity(x)));
        });
    }
    populateOccurrenceDriveTime(occurrence: Occurrence, driveTimes: DriveTime[]) {
        const driveTime = driveTimes.find(x => x.parentId === occurrence.id && x.id === occurrence.occurrenceIndex.toString());
        if (driveTime) {
            occurrence.drive_time = driveTime;
        }
    }
    async updateRecurrence(recurrenceUpdateInput: RecurrenceUpdateInput) {
        const { id, originalRecurrence, appointmentInput, groomer, services } = recurrenceUpdateInput;

        if (!appointmentInput.customerId)
            throw new Error("Customer selection is required at this point");

        const customer = await CustomerRepository.current.getById(appointmentInput.customerId);
        if (!customer)
            throw new Error(`Customer ${appointmentInput.customerId} not found`);

        const secondaryAddress = appointmentInput.secondaryAddressId
            ? await SecondaryAddressRepository.current.getById(customer.id, appointmentInput.secondaryAddressId)
            : undefined;

        const pets = await PetRepository.current.search(customer.id);

        const toUpdate = AppointmentRepository.getAppointmentToUpdate(appointmentInput, groomer, customer, services, pets, secondaryAddress ?? undefined);

        if (!toUpdate.start_time || !toUpdate.end_time)
            throw new Error("StartTime and EndTime fields are required at this point");

        toUpdate.start_time = getDateWithMergedTime(originalRecurrence.start_time, toUpdate.start_time, groomer.time_zone);
        toUpdate.end_time = getDateWithMergedTime(originalRecurrence.end_time, toUpdate.end_time, groomer.time_zone);
        await this.update(id, toUpdate);
    }
    async recreateRecurrence(updateInput: RecurrenceUpdateInput) {
        const { id, appointmentInput, groomer, services } = updateInput;

        if (!appointmentInput.customerId)
            throw new Error("Customer selection is required at this point");

        const customer = await CustomerRepository.current.getById(appointmentInput.customerId);
        if (!customer)
            throw new Error(`Customer ${appointmentInput.customerId} not found`);

        const secondaryAddress = appointmentInput.secondaryAddressId
            ? await SecondaryAddressRepository.current.getById(customer.id, appointmentInput.secondaryAddressId)
            : undefined;

        const pets = await PetRepository.current.search(customer.id);

        const newAppointmentData = AppointmentRepository.getAppointmentToCreate(appointmentInput, groomer, customer, services, pets, secondaryAddress ?? undefined, true);
        await this.create(newAppointmentData);

        await this.firestoreService.update(id, { status: AppointmentStatus.cancelled });
    }
    async handleUpdateWholeSeries(recurrenceUpdateInput: RecurrenceUpdateInput) {
        const { occurrenceIndex, originalRecurrence, appointmentInput } = recurrenceUpdateInput;

        const { startTime, frequency } = appointmentInput;
        if (!frequency)
            throw new Error("Frequency is required at this point");

        const sameDay = originalRecurrence.areSameDate(occurrenceIndex, startTime);
        const sameFrecuency = originalRecurrence.getFrequency().interval === frequency.interval;

        if (sameDay && sameFrecuency) {
            await this.updateRecurrence(recurrenceUpdateInput);
        } else {
            await this.recreateRecurrence(recurrenceUpdateInput);
        }
    }
}
