import { Box } from "@mui/material";
import suggestionsLoaderDotsGif from "@marathon/web/images/suggestionsLoaderDots.gif";
import suggestionsLoaderGif from "@marathon/web/images/suggestionsLoader.gif";
import { CSSProperties } from "react";

const AppointmentSuggestionsLoader = ({ style }: { style?: CSSProperties }) => {
    return (
        <Box display="grid" sx={{ placeItems: "center" }} height="100%" style={style}>
            <Box maxWidth={430}>
                <img src={suggestionsLoaderGif} alt="loading-message" />
                <img src={suggestionsLoaderDotsGif} alt="loading-dots" />
            </Box>
        </Box>
    );
};

export default AppointmentSuggestionsLoader;