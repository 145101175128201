import Swal from "sweetalert2";
import * as Sentry from "@sentry/react";

export const logErrorAndShowAlert = (error: unknown, customText?: string) => {
    Sentry.captureException(error);
    const title = error instanceof Error ? error.message : "An error has occurred";
    const text = customText || "Please refresh the page and try again";
    Swal.fire({ title, text });
};

export const logErrorAndShowTitleOnlyAlert = (error: unknown, title: string) => {
    Sentry.captureException(error);
    Swal.fire(title);
};