import { ConfigurationDocument, AiIntegration, AlgoliaConfiguration, MobileServiceFeeConfiguration } from "@marathon/common/entities/Configuration";
import { container, inject, injectable } from "tsyringe";
import { INJECTED_FIRESTORE_SERVICE_TOKEN } from "./IFirestoreService";
import type { IFirestoreService } from "./IFirestoreService";
import { CollectionPaths } from "@marathon/common/entities/base/CollectionPaths";

@injectable()
export class ConfigurationRepository {
    private firestoreService: IFirestoreService<unknown>;
    constructor(@inject(INJECTED_FIRESTORE_SERVICE_TOKEN) injectedService: IFirestoreService<unknown>) {
        injectedService.collectionPath = CollectionPaths.Configuration;
        this.firestoreService = injectedService;
    }
    static get current() {
        return container.resolve(ConfigurationRepository);
    }
    async getAiIntegration() {
        const doc = await this.firestoreService.getById(ConfigurationDocument.AiIntegration);
        if (!doc) {
            throw new Error("AI Integration configuration not found");
        }
        return doc.data as AiIntegration;
    }
    async setAiIntegration(data: AiIntegration) {
        await this.firestoreService.update(ConfigurationDocument.AiIntegration, data);
    }
    async getAlgolia() {
        const doc = await this.firestoreService.getById(ConfigurationDocument.Algolia);
        if (!doc) {
            throw new Error("Algolia configuration not found");
        }
        return doc.data as AlgoliaConfiguration;
    }
    async getMobileServiceFee() {
        const doc = await this.firestoreService.getById(ConfigurationDocument.MobileServiceFee);
        if (!doc) {
            throw new Error("Mobile Service Fee configuration not found");
        }
        return doc.data as MobileServiceFeeConfiguration;
    }
    private listenAlgoliaAttributeChanges(id: string, attributeName: keyof AlgoliaConfiguration, callback: () => void) {
        return this.firestoreService.onDocumentSnapshot(id, doc => {
            const data = doc.data as AlgoliaConfiguration;
            if (data && data[attributeName] !== undefined) {
                callback();
            }
        });
    }
    listenAlgoliaCustomerChanges(callback: () => void) {
        return this.listenAlgoliaAttributeChanges("algolia", "customers_index_refresh_key", callback);
    }
    listenAlgoliaGroomerChanges(callback: () => void) {
        return this.listenAlgoliaAttributeChanges("algolia", "groomers_index_refresh_key", callback);
    }
}